import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { Pricing, PricingItem } from '@pages/Organizations/Pricing/dataTypes';
import {
  LicensePriceListDTORequest,
  PricingPageApi,
  PricingPageDataDTOResponse
} from '@xq/omni-gateway-frontend-client';
import { Currency } from '@xq/ui-kit';

export interface PricingService {
  fetchData(organizationUuid: string, licenseUuid: string): Promise<Pricing>;

  save(
    organizationUuid: string,
    licenseUuid: string,
    pricing: PricingItem[]
  ): Promise<void>;

  deletePriceList(
    organizationUuid: string,
    licenseUuid: string,
    licensePriceListUuid: string
  ): Promise<void>;
}

const omniPricingGateway = new PricingPageApi(omniApiConfiguration);

export class PricingServiceApi implements PricingService {
  async fetchData(
    organizationUuid: string,
    licenseUuid: string
  ): Promise<Pricing> {
    try {
      const response: PricingPageDataDTOResponse =
        await omniPricingGateway.pricingPageControllerGetPageData({
          licenseUuid,
          organizationUuid
        });

      return { ...response, currency: response.currency as Currency };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(
    organizationUuid: string,
    licenseUuid: string,
    pricing: PricingItem[]
  ): Promise<void> {
    try {
      await omniPricingGateway.pricingPageControllerSavePriceLists({
        licenseUuid,
        organizationUuid,
        licensePriceListsDTORequest: {
          priceLists: pricing as LicensePriceListDTORequest[]
        }
      });
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }

  async deletePriceList(
    organizationUuid: string,
    licenseUuid: string,
    licensePriceListUuid: string
  ): Promise<void> {
    try {
      await omniPricingGateway.pricingPageControllerDeletePriceList({
        licensePriceListUuid,
        licenseUuid,
        organizationUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deletePriceList.name
      );
    }
  }
}

const pricingMock: PricingItem[] = [
  {
    uuid: '1',
    updateIsAllowed: false,
    startAt: new Date('2022-12-19T18:32:04.000Z'),
    priceRuleType: 'TOTAL_PRICE',
    billingFrequency: 'MONTHLY',
    paymentMethod: 'BEFORE',
    licensePriceListRules: [
      {
        numberOfUsers: 10,
        price: 5000
      },
      {
        numberOfUsers: 20,
        price: 9000
      },
      {
        numberOfUsers: 30,
        price: 13000
      },
      {
        numberOfUsers: 40,
        price: 17000
      },
      { price: 500 }
    ]
  },
  {
    uuid: '2',
    updateIsAllowed: true,
    startAt: new Date('2023-11-10T18:32:04.000Z'),
    priceRuleType: 'TOTAL_PRICE',
    billingFrequency: 'QUARTERLY',
    paymentMethod: 'BEFORE',
    licensePriceListRules: [
      {
        numberOfUsers: 5,
        price: 15000
      },
      {
        numberOfUsers: 10,
        price: 18000
      },
      {
        numberOfUsers: 15,
        price: 21000
      },
      {
        numberOfUsers: 20,
        price: 24000
      },
      { price: 3500 }
    ]
  }
];

const pricingPageMock: Pricing = {
  priceLists: pricingMock,
  currency: 'EUR'
};

export class PricingServiceMock implements PricingService {
  async fetchData(
    organizationUuid: string,
    licenseUuid: string
  ): Promise<Pricing> {
    try {
      await wait(1000);
      return pricingPageMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(
    organizationUuid: string,
    licenseUuid: string,
    pricing: PricingItem[]
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }

  async deletePriceList(
    organizationUuid: string,
    licenseUuid: string,
    licensePriceListUuid: string
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deletePriceList.name
      );
    }
  }
}
