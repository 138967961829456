import {
  Alert,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Checkbox,
  Col,
  colors,
  common,
  Copy,
  DateIntervalType,
  DatePicker,
  Input,
  offsets,
  openStatusNotification,
  Row,
  Typography
} from '@xq/ui-kit';
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './ApiKeysCreate.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  getBreadcrumbWithDropdown,
  getCurrentLanguage,
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS,
  submitForm
} from '@services';
import { getRouteUrl, ROUTES } from '@router';
import {
  ApiKeysCreateService,
  ApiKeysCreateServiceApi
} from './api-keys-create-service';
import { SidemenuContext, SidemenuContextData } from '@context';
import { ApiKeyCreatePageDataDTOResponse } from '@xq/omni-gateway-frontend-client';
import { ApiKeyPermission } from '@pages/Organizations/ApiKeysCreate/dataTypes';
import cn from 'classnames';

export const ApiKeysCreate: FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const service: ApiKeysCreateService = new ApiKeysCreateServiceApi();
  const navigate = useNavigate();

  const [name, setName] = useState<string>('');
  const [expireAt, setExpireAt] = useState<Date>(null);
  const [permissions, setPermissions] = useState<Array<ApiKeyPermission>>(null);
  const [code, setCode] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

  const isFieldFilled = useMemo(() => {
    return !!name && !!expireAt;
  }, [name, expireAt]);

  function selectPermission(permission: ApiKeyPermission, value: boolean) {
    setPermissions(
      permissions?.map((el) => {
        if (el.name === permission.name) {
          return { ...el, isSelected: value };
        }
        return el;
      })
    );
  }

  async function fetchData() {
    try {
      const response: ApiKeyCreatePageDataDTOResponse = await service.fetchData(
        params?.id
      );

      setPermissions(
        response?.permissions?.map((el) => {
          return { ...el, isSelected: false };
        })
      );
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function create() {
    setIsLoading(true);

    try {
      if (!name) {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: 400,
          message: 'Application name is required'
        });
        return;
      }
      if (!expireAt) {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: 400,
          message: 'Expiry date is required'
        });
        return;
      }

      const response = await service.save(params.id, {
        name: name,
        expireAt: expireAt,
        permissions: permissions
          ?.filter((el) => el.isSelected)
          ?.map((el) => el.name)
      });
      setCode(response);

      setIsAlertOpen(true);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  function cancel() {
    navigate(getRouteUrl(ROUTES.ORGANIZATIONS.API_KEYS, { id: params?.id }));
  }

  function closeAlert() {
    setIsAlertOpen(false);
    cancel();
  }

  useEffect(() => {
    fetchData();
  }, [params.id]);

  /** Sidemenu */
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  useEffect(() => {
    sidemenuContext.setActiveMenu(ORGANIZATION_SIDEMENUS.ORGANIZATION_API_KEYS);
  }, [sidemenuContext]);

  /** Breadcrumbs */
  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN, { id: params?.id })
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.API_KEYS,
        { id: params?.id }
      ),
      {
        label: t(ROUTES.ORGANIZATIONS.API_KEYS),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.API_KEYS, { id: params?.id })
      }
    ],
    [sidemenuContext, params.id]
  );

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={10}>
          <Breadcrumbs
            NavLink={NavLink}
            className={'breadcrumbs'}
            items={breadcrumbs}
          />

          <Col col={9} className={'heading'}>
            <Typography element="div" variant="h2">
              {t('organizations.createApplication')}
            </Typography>
          </Col>
        </Col>
      </Row>

      <Row cols={10}>
        <Col col={6} md={3}>
          <form onSubmit={submitForm}>
            <Input
              disabled={isLoading}
              required={true}
              value={name}
              onChange={(value) => setName(String(value))}
              label={t('organizations.applicationName')}
            />

            <DatePicker
              datePickerPlaceholder={t('uiKit.expiryDate')}
              type="day"
              dateIntervalType={DateIntervalType.Date}
              locale={getCurrentLanguage()}
              datePickerValue={expireAt}
              disabled={isLoading}
              onDatePickerChange={setExpireAt}
            />

            <Typography
              variant={'body-2'}
              element={'div'}
              className={offsets['mt-40']}
            >
              {t('common.scopes')}
            </Typography>

            {permissions?.map((permission) => {
              return (
                <div key={permission.name} className={offsets['my-20']}>
                  <Checkbox
                    label={permission.name}
                    className={offsets['mb-10']}
                    checked={permission.isSelected}
                    disabled={isLoading}
                    onChange={(value) => selectPermission(permission, value)}
                  />
                  <Typography
                    variant={'body-4'}
                    color={colors.gray60Color}
                    element={'div'}
                    className={offsets['ml-24']}
                  >
                    {permission.description}
                  </Typography>
                </div>
              );
            })}

            <div className={styles.buttons}>
              <Button
                type="primary"
                onClick={create}
                disabled={!isFieldFilled}
                isLoading={isLoading}
              >
                {t('common.create')}
              </Button>

              <Button type="secondary" onClick={cancel} disabled={isLoading}>
                {t('common.cancel')}
              </Button>
            </div>
          </form>
        </Col>
      </Row>
      <Alert
        isOpen={isAlertOpen}
        onClose={closeAlert}
        cancelText={t('common.close')}
        submitText={t('common.submit')}
      >
        <Typography
          element={'div'}
          variant={'body-1'}
          className={offsets['mb-20']}
        >
          Please <b>copy and save</b> your Secret Code below. <br />
          The code will be unavailable after closing this window.
        </Typography>
        <div className={styles.code}>
          <Copy
            contentClassName={cn(styles.content, common['no-text-transform'])}
            content={code}
            copyText={t('uiKit.copy')}
            copiedText={t('uiKit.сopied')}
          />
        </div>
      </Alert>
    </Fragment>
  );
};

ApiKeysCreate.displayName = 'ApiKeysCreate';
