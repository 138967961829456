import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Col,
  display,
  flex,
  Input,
  Multiselect,
  offsets,
  openStatusNotification,
  Row,
  SelectItem,
  Textarea,
  Toggle,
  Typography
} from '@xq/ui-kit';
import styles from './CreateRelease.module.scss';
import { useTranslation } from 'react-i18next';
import { CreateReleaseService } from './create-release-service';
import cn from 'classnames';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { SidemenuContext, SidemenuContextData } from '@context';
import { getStatusNotificationTranslations, submitForm } from '@services';
import { getRouteUrl, ROUTES } from '@router';
import { Release } from '@pages/ModelTree/CreateRelease/dataTypes';

export const CreateRelease: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);
  const service: CreateReleaseService = new CreateReleaseService();

  const [organizations, setOrganizations] = useState<SelectItem[]>([]);
  const [releaseVersion, setReleaseVersion] = useState<string | number>('');
  const [description, setDescription] = useState<string>('');
  const [emailReleaseText, setEmailReleaseText] = useState<string>('');
  const [releaseNotes, setReleaseNotes] = useState<string>('');
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    SelectItem[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const buildBranch: string = useMemo(() => {
    // @ts-ignore
    return location?.state?.buildBranch;
  }, [location?.state]);
  const buildId: string = useMemo(() => {
    // @ts-ignore
    return location?.state?.buildId;
  }, [location?.state]);

  useEffect(() => {
    if (!buildBranch || !buildId) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 404,
        message: t('notifications.buildBranchOrBuildIdWasNotProvided')
      });
      navigate(getRouteUrl(ROUTES.MODEL_TREE.BUILDS));
    }
  }, [buildBranch, buildId]);

  useEffect(() => {
    fetchData();
    sidemenuContext.setActiveMenu(ROUTES.MODEL_TREE.BUILDS);
  }, []);

  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await service.fetchData(Number(buildId));
      setOrganizations(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  const isFieldsFilled: boolean = useMemo(() => {
    const isMainFieldsValid =
      releaseVersion && description && emailReleaseText && releaseNotes;

    if (isPrivate) {
      return !(isMainFieldsValid && selectedOrganizations?.length);
    }
    return !isMainFieldsValid;
  }, [
    releaseVersion,
    description,
    emailReleaseText,
    releaseNotes,
    selectedOrganizations?.length,
    isPrivate
  ]);

  async function create() {
    try {
      const release: Release = {
        buildBranch: buildBranch,
        buildId: buildId,
        releaseVersion: String(releaseVersion),
        description: description,
        releaseNotes: releaseNotes,
        emailNotificationDescription: emailReleaseText,
        status: {
          isPrivate: isPrivate,
          organizations: selectedOrganizations
        }
      };

      setIsUpdating(true);

      await service.create(release);

      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: (
          <span>
            {t('modelTree.release')}{' '}
            <strong>
              {t('common.version')} {releaseVersion}
            </strong>{' '}
            for{' '}
            <strong style={{ textTransform: 'lowercase' }}>
              {t('modelTree.buildId')} {buildId}
            </strong>{' '}
            {t('notifications.hasBeenCreated')}
          </span>
        )
      });
      navigate(getRouteUrl(ROUTES.MODEL_TREE.RELEASES));
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsUpdating(false);
    }
  }

  function cancel() {
    navigate(getRouteUrl(ROUTES.MODEL_TREE.BUILDS));
  }

  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.MODEL_TREE.BUILDS),
        url: getRouteUrl(ROUTES.MODEL_TREE.BUILDS)
      }
    ],
    [sidemenuContext]
  );

  useEffect(() => {
    sidemenuContext.setActiveMenu(ROUTES.MODEL_TREE.BUILDS);
  }, [sidemenuContext]);

  return (
    <div>
      <Row cols={10}>
        <Col col={10}>
          <Breadcrumbs
            NavLink={NavLink}
            className={'breadcrumbs'}
            items={breadcrumbs}
          />
          <Typography className={offsets['mb-40']} element="div" variant="h2">
            {t('routes.modelTree.createRelease')}
          </Typography>
        </Col>
      </Row>

      <Row cols={10}>
        <form onSubmit={submitForm}>
          <Col col={9}>
            <div className={styles.block}>
              <Input
                disabled
                value={buildBranch}
                label={t('modelTree.buildBranch')}
              />
              <Input disabled value={buildId} label={t('modelTree.buildId')} />
              <Input
                disabled={isUpdating}
                required={true}
                value={releaseVersion}
                onChange={setReleaseVersion}
                className={styles['release-version']}
                label={t('modelTree.releaseVersion')}
              />
            </div>
          </Col>

          <Col col={9}>
            <Textarea
              disabled={isUpdating}
              required={true}
              value={description}
              onChange={setDescription}
              className={offsets['mb-20']}
              label={t('modelTree.releaseDescription')}
            />

            <Textarea
              isMarkdown={true}
              required={true}
              disabled={isUpdating}
              value={emailReleaseText}
              onChange={setEmailReleaseText}
              className={offsets['mb-20']}
              label={t('modelTree.emailReleaseText')}
            />

            <Textarea
              isMarkdown={true}
              required={true}
              disabled={isUpdating}
              value={releaseNotes}
              onChange={setReleaseNotes}
              className={offsets['mb-20']}
              label={t('modelTree.releaseNotes')}
            />
          </Col>
          <Col col={8} md={4}>
            <div
              className={cn(
                display['d-flex'],
                flex['align-items-center'],
                offsets['mb-20']
              )}
            >
              <Typography className={offsets['mr-12']} variant="body-1">
                {t('modelTree.private')}
              </Typography>

              <Toggle
                label={'private'}
                onChange={setIsPrivate}
                checked={isPrivate}
                disabled={isUpdating}
              />
            </div>

            <Multiselect
              className={offsets['mb-20']}
              items={organizations}
              label={
                isPrivate
                  ? t('modelTree.forOrganizations')
                  : t('modelTree.exceptOrganizations')
              }
              onChange={setSelectedOrganizations}
              disabled={isUpdating}
              isLoading={isLoading}
              required={isPrivate}
              selected={selectedOrganizations}
              selectAllText={t('uiKit.selectAll')}
            />

            <div className={offsets['pb-40']}>
              <Button
                buttonType={'submit'}
                onClick={create}
                isLoading={isUpdating}
                disabled={isFieldsFilled}
                className={offsets['mr-20']}
              >
                {t('common.create')}
              </Button>
              <Button onClick={cancel} disabled={isUpdating} type="secondary">
                {t('common.cancel')}
              </Button>
            </div>
          </Col>
        </form>
      </Row>
    </div>
  );
};

CreateRelease.displayName = 'CreateRelease';
