import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Col,
  Input,
  offsets,
  openStatusNotification,
  Row,
  Textarea,
  Typography,
  validate,
  ValidateTypes
} from '@xq/ui-kit';
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './CreateContactPerson.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  getBreadcrumbWithDropdown,
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS,
  submitForm
} from '@services';
import { getRouteUrl, ROUTES } from '@router';
import { ContactPerson } from './dataTypes';
import {
  CreateContactPersonService,
  CreateContactPersonServiceApi
} from './create-contact-person-service';
import { SidemenuContext, SidemenuContextData } from '@context';

export const CreateContactPerson: FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);
  const service: CreateContactPersonService =
    new CreateContactPersonServiceApi();

  const [name, setName] = useState<string | number>('');
  const [phone, setPhone] = useState<string | number>('');
  const [email, setEmail] = useState<string | number>('');
  const [position, setPosition] = useState<string | number>('');
  const [comments, setComments] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isFieldsFilled = useMemo(() => {
    return !(String(name)?.trim() && String(email)?.trim());
  }, [name, email]);

  const [isEmailValidationError, setIsEmailValidationError] = useState(false);
  const [isPhoneValidationError, setIsPhoneValidationError] = useState(false);

  async function create() {
    const isPhoneValid =
      !phone || (phone && validate(ValidateTypes.tel, phone));
    setIsPhoneValidationError(!isPhoneValid);

    const isEmailValid = email && validate(ValidateTypes.email, email);
    setIsEmailValidationError(!isEmailValid);

    if (!isPhoneValid || !isEmailValid) {
      return;
    }

    const contactPerson: ContactPerson = {
      name: String(name),
      phone: String(phone) || undefined,
      email: String(email),
      position: String(position) || undefined,
      comments: comments || undefined
    };

    setIsLoading(true);

    try {
      await service.create(params.id, contactPerson);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
      setName('');
      setPhone('');
      setEmail('');
      setPosition('');
      setComments('');
      navigate(
        getRouteUrl(ROUTES.ORGANIZATIONS.CONTACT_PERSONS, { id: params.id })
      );
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  function cancel() {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.CONTACT_PERSONS, { id: params.id })
    );
  }

  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.CONTACT_PERSONS,
        { id: params?.id }
      ),
      {
        label: t('organizations.contactPersons'),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.CONTACT_PERSONS, {
          id: params.id
        })
      }
    ],
    [sidemenuContext, params]
  );

  useEffect(() => {
    sidemenuContext.setActiveMenu(
      ORGANIZATION_SIDEMENUS.ORGANIZATION_CONTACT_PERSONS
    );
  }, [sidemenuContext]);

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={10}>
          <Breadcrumbs
            NavLink={NavLink}
            className={'breadcrumbs'}
            items={breadcrumbs}
          />

          <Typography className={offsets['mb-40']} element="div" variant="h2">
            {t('organizations.createContactPerson')}
          </Typography>
        </Col>
      </Row>

      <Row cols={10}>
        <Col col={10}>
          <form className={styles.form} onSubmit={submitForm}>
            <Input
              required
              disabled={isLoading}
              value={name}
              onChange={setName}
              className={offsets['mb-20']}
              label={t('common.name')}
            />

            <Input
              disabled={isLoading}
              value={phone}
              onChange={setPhone}
              className={offsets['mb-20']}
              label={t('common.phoneNumber')}
              errorMessage={
                isPhoneValidationError ? t('notifications.phoneIsNotValid') : ''
              }
            />

            <Input
              required
              disabled={isLoading}
              value={email}
              onChange={setEmail}
              className={offsets['mb-20']}
              label={t('common.email')}
              errorMessage={
                isEmailValidationError ? t('notifications.emailIsNotValid') : ''
              }
            />

            <Input
              disabled={isLoading}
              value={position}
              onChange={setPosition}
              className={offsets['mb-20']}
              label={t('uiKit.role')}
            />

            <Textarea
              disabled={isLoading}
              value={comments}
              onChange={setComments}
              label={t('uiKit.comments')}
            />

            <div className={offsets['mt-40']}>
              <Button
                buttonType={'submit'}
                onClick={create}
                isLoading={isLoading}
                disabled={isFieldsFilled}
                className={offsets['mr-20']}
              >
                {t('common.create')}
              </Button>
              <Button onClick={cancel} type="secondary">
                {t('common.cancel')}
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </Fragment>
  );
};

CreateContactPerson.displayName = 'CreateContactPerson';
