import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './InvoiceDetails.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Col,
  convertBillingFrequencyToText,
  convertPaymentMethodToText,
  Currency,
  EmptyState,
  formatCurrency,
  formatDate,
  offsets,
  openStatusNotification,
  Row,
  SystemInfographics,
  Table,
  TableColumn,
  Typography
} from '@xq/ui-kit';
import {
  InvoiceDetailsService,
  InvoiceDetailsServiceApi
} from './invoice-details-service';
import {
  downloadFile,
  getBreadcrumbWithDropdown,
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS
} from '@services';
import { SidemenuContext, SidemenuContextData } from '@context';
import { getRouteUrl, ROUTES } from '@router';
import { BillingInvoiceDetails, InvoiceDetailsInformation } from './dataTypes';
import { createInvoiceDetailsColumns, createInvoiceDetailsRows } from './utils';
import { DASH } from '@constants';
import { config } from '@config';

export const InvoiceDetails: FC = () => {
  const service: InvoiceDetailsService = new InvoiceDetailsServiceApi();
  const { t } = useTranslation();
  const params = useParams();

  /** Tables and base information  */
  const [information, setInformation] =
    useState<InvoiceDetailsInformation>(null);
  const [licensePayments, setLicensePayments] = useState<
    BillingInvoiceDetails[]
  >([]);
  const [additionalPayments, setAdditionalPayments] = useState<
    BillingInvoiceDetails[]
  >([]);
  const [currencyIso3, setCurrencyIso3] = useState<Currency>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [noResults, setNoResults] = useState<boolean>(false);

  const invoiceDetailsColumns: TableColumn[] = createInvoiceDetailsColumns(t);

  const invoiceDetailsRows = useMemo(() => {
    if (isLoading) {
      return null;
    }
    return createInvoiceDetailsRows(
      t,
      licensePayments,
      additionalPayments,
      currencyIso3
    );
  }, [licensePayments, additionalPayments, currencyIso3, isLoading]);

  useEffect(() => {
    fetchData();
  }, [params.id]);

  async function fetchData() {
    try {
      setIsLoading(true);
      const response = await service.fetchData(params.id, params.invoiceId);

      setInformation(response?.invoice);
      setCurrencyIso3(response?.currencyIso3);

      const noResults =
        !response.invoiceDetails || response?.invoiceDetails?.length === 0;
      setNoResults(noResults);
      setLicensePayments(
        response?.invoiceDetails?.filter((el) => !el.isAdditionalPayment)
      );
      setAdditionalPayments(
        response?.invoiceDetails?.filter((el) => el.isAdditionalPayment)
      );
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function downloadInvoiceDetails() {
    try {
      const url = `${config.apiUrl}/omni/page/billing-invoice/organization/${params.id}/invoice/${params.invoiceId}/pdf-file`;
      await downloadFile(url);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: `${t('organizations.invoiceDetails')} ${params.invoiceId} ${t(
          'notifications.isDownloading'
        )}`
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  /** Sidemenu */
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  useEffect(() => {
    sidemenuContext.setActiveMenu(ORGANIZATION_SIDEMENUS.ORGANIZATION_INVOICES);
  }, [sidemenuContext]);

  /** Breadcrumbs */
  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.INVOICES,
        { id: params?.id }
      ),
      {
        label: t('routes.organizations.invoices'),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.INVOICES, {
          id: params.id,
          invoiceId: params.invoiceId
        })
      }
    ],
    [sidemenuContext, params]
  );

  return (
    <Fragment>
      <Row cols={10}>
        <Breadcrumbs
          NavLink={NavLink}
          className={'breadcrumbs'}
          items={breadcrumbs}
        />
        <Col col={9} className={styles.heading}>
          <Typography element="div" variant="h2">
            {t('organizations.invoiceDetails')}
          </Typography>

          {!noResults && (
            <div className={styles.buttons}>
              <Button
                type="third"
                icon="download"
                onClick={downloadInvoiceDetails}
              >
                {t('common.download')}
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <Row cols={10} style={{ marginRight: 0 }}>
        {!isLoading && noResults && (
          <EmptyState
            heading={t('uiKit.oopsItIsEmpty')}
            description={t('alerts.looksLikeTheOrganizationHasNoInvoices')}
            onClick={downloadInvoiceDetails}
            buttonText={t('common.download')}
            buttonIcon={'download'}
            isCentered={true}
          />
        )}

        {!noResults && (
          <Fragment>
            <div className={styles.statistics}>
              <SystemInfographics
                className={offsets['mr-40']}
                heading={t('organizations.invoiceDate')}
                content={
                  information?.date ? formatDate(information?.date) : DASH
                }
              />

              <SystemInfographics
                className={offsets['mr-40']}
                heading={t('organizations.invoicing')}
                content={
                  information?.billingFrequency
                    ? convertBillingFrequencyToText(
                        information?.billingFrequency
                      )
                    : DASH
                }
              />

              <SystemInfographics
                className={offsets['mr-40']}
                heading={t('organizations.paymentMethod')}
                content={
                  information?.paymentMethod
                    ? convertPaymentMethodToText(information?.paymentMethod)
                    : DASH
                }
              />

              <SystemInfographics
                className={offsets['mr-40']}
                heading={t('organizations.invoiceAmount')}
                content={
                  information?.totalPayments
                    ? formatCurrency(
                        currencyIso3,
                        information?.totalPayments,
                        2
                      )
                    : DASH
                }
              />
            </div>
          </Fragment>
        )}
      </Row>

      {!noResults && (
        <Row cols={10}>
          <Col col={9}>
            <Table
              isLoading={isLoading}
              className={offsets['mb-40']}
              rows={invoiceDetailsRows}
              columns={invoiceDetailsColumns}
              noMoreItemsText={t('common.noMoreItems')}
              maxHeight="auto"
              noResults={noResults}
              noResultsText={t('common.noResults')}
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

InvoiceDetails.displayName = 'InvoiceDetails';
