import { TFunction } from 'i18next';
import { XQInvalidRequestMetaError } from '@xq/omni-gateway-frontend-client';

export const handleTranslationErrorFromStatus = (
  t: TFunction,
  error: XQInvalidRequestMetaError
): string => {
  switch (error?.error) {
    case 'USER_EMAIL_ALREADY_EXISTS': {
      return t('notifications.userIsExist');
    }
    case 'USER_EMAIL_DOMAIN_IS_FORBIDDEN': {
      return t('notifications.domainIsForbidden');
    }
    default: {
      break;
    }
  }
};
