import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './Invoices.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  AdditionalPaymentsItem,
  AdditionalPaymentsList,
  Alert,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  changeSortDirection,
  Col,
  convertPaymentMethodToText,
  DateIntervalType,
  DatePicker,
  EmptyState,
  formatCurrency,
  formatDate,
  Modal,
  offsets,
  openStatusNotification,
  Row,
  SortDirection,
  SystemInfographics,
  Table,
  TableAction,
  TableColumn,
  TableRow,
  Typography
} from '@xq/ui-kit';
import { InvoicesService, InvoicesServiceApi } from './invoices-service';
import {
  backendErrorLog,
  checkEmptyState,
  checkNoMoreData,
  downloadFile,
  getBreadcrumbWithDropdown,
  getCurrentLanguage,
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS
} from '@services';
import { SidemenuContext, SidemenuContextData } from '@context';
import { getRouteUrl, ROUTES } from '@router';
import { ListMetaInfo, ListParams } from 'interfaces';
import { DASH, SORT_DIRECTIONS } from '@constants';
import { InvoiceItem, InvoicesInformation } from './dataTypes';
import { createInvoiceColumns, createInvoiceRows } from './utils';
import { config } from '@config';

export const Invoices: FC = () => {
  const service: InvoicesService = new InvoicesServiceApi();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  /** Tables and base information  */
  const [information, setInformation] = useState<InvoicesInformation>(null);
  const [invoices, setInvoices] = useState<InvoiceItem[]>([]);
  const [invoicesMeta, setInvoicesMeta] = useState<ListMetaInfo>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<SortDirection>(
    SORT_DIRECTIONS.DESC as SortDirection
  );
  const [sortBy, setSortBy] = useState<string>('date');
  const [noMoreData, setNoMoreData] = useState<boolean>(false);
  const [rangePickerValue, setRangePickerValue] = useState<[Date, Date]>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [shownInvoiceUuid, setShownInvoiceUuid] = useState<string>(null);
  const [shownAdditionalPayment, setShownAdditionalPayment] = useState<
    AdditionalPaymentsItem[]
  >([]);

  const [isEmptyState, setIsEmptyState] = useState<boolean>(false);

  /** values to alert */
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [submitText, setSubmitText] = useState<string>('');
  const [alertText, setAlertText] = useState<string>('');
  const [alertSubmitFunction, setAlertSubmitFunction] = useState<() => void>();

  const shownInvoice = useMemo(() => {
    return invoices?.find((invoice) => invoice.uuid === shownInvoiceUuid);
  }, [shownInvoiceUuid, invoices]);

  const invoiceColumns: TableColumn[] = createInvoiceColumns(t);

  const invoicesActions: TableAction[] = [
    {
      key: 'additionalPayments',
      title: t('organizations.additionalPayments'),
      icon: 'creditcard-plus',
      onClick: showAdditionalPayments
    },
    {
      key: 'invoiceDetails',
      title: t('organizations.invoiceDetails'),
      icon: 'invoice-details',
      onClick: showInvoiceDetails
    },
    {
      key: 'userTrackingReport',
      title: t('uiKit.userTrackingReport'),
      icon: 'users',
      onClick: downloadInvoiceUserReportFile
    },
    {
      key: 'delete',
      title: t('common.delete'),
      icon: 'trash',
      onClick: deleteInvoiceSubmit
    }
  ];

  const filterActionsForRow = (actions: TableAction[], row: TableRow) => {
    let newActions = [...actions];
    const invoice = invoices?.find((invoice) => invoice.uuid === row.id);

    if (!invoice) {
      return actions;
    }

    if (!invoice?.canBeDeleted) {
      newActions = newActions?.map((action) =>
        action.key === 'delete' ? { ...action, disabled: true } : action
      );
    }

    return newActions;
  };

  const activeUserRows = useMemo(() => {
    return createInvoiceRows(t, invoices, information?.currencyIso3);
  }, [invoices, information?.currencyIso3, t]);

  useEffect(() => {
    setIsEmptyState(false);
    fetchData();
  }, [params.id]);

  useEffect(() => {
    fetchInvoices({
      sortOrder: sortOrder,
      sortBy: sortBy
    });
  }, [params.id, sortOrder, sortBy, rangePickerValue]);

  async function fetchInvoices(queryParams?: ListParams) {
    try {
      setIsLoading(true);
      const response = await service.fetchInvoices(
        params?.id,
        params?.licenseId,
        rangePickerValue,
        queryParams
      );

      const hasResults = !response.items || response?.items?.length === 0;
      setNoResults(hasResults);
      setInvoices(response?.items);
      checkNoMoreData(
        response?.items?.length,
        response?.meta?.totalItems,
        setNoMoreData
      );
      setInvoicesMeta(response?.meta);
      checkEmptyState({
        setIsEmptyState,
        meta: response?.meta,
        rangePickerValue
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchData() {
    try {
      setIsLoading(true);
      const response = await service.fetchData(params?.id, params.licenseId);
      setInformation(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function runBilling() {
    try {
      await service.runBilling(params.id);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: `${t('notifications.successfullyStarted')}. ${t(
          'notifications.pleaseReloadThePage'
        )}`
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function showAdditionalPayments(row: TableRow) {
    try {
      const response = await service.fetchAdditionalPayments(params.id, row.id);
      setShownAdditionalPayment(response);
      setShownInvoiceUuid(row?.id);
      setIsModalOpen(true);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function saveAdditionalPayment() {
    try {
      const response: InvoiceItem = await service.saveAdditionalPayments(
        params.id,
        shownInvoiceUuid,
        shownAdditionalPayment
      );

      const updatedInvoices = invoices?.map((invoice) => {
        if (invoice.uuid === shownInvoiceUuid) {
          return response;
        } else {
          return invoice;
        }
      });

      setInvoices(updatedInvoices);

      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  function deleteInvoiceSubmit(row: TableRow) {
    const invoice = invoices?.find((invoice) => invoice.uuid === row.id);
    setAlertText(
      `${t('alerts.areYouSureYouWantToDelete')} <br/>${t(
        'organizations.invoice'
      )} ${invoice?.licenseName} ${invoice?.date && formatDate(invoice.date)}?`
    );
    setSubmitText(t('common.delete'));
    setAlertSubmitFunction(() => () => deleteInvoice(invoice));
    setIsAlertOpen(true);
  }

  async function deleteInvoice(invoice: InvoiceItem) {
    try {
      await service.deleteInvoice(params.id, invoice.uuid);
      setInvoices(invoices.filter((el) => el.uuid !== invoice.uuid));
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: t('notifications.successfullyDeleted')
      });
      fetchInvoices({
        sortOrder: sortOrder,
        sortBy: sortBy
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  function showInvoiceDetails(row: TableRow) {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.INVOICE_DETAILS, {
        id: params.id,
        invoiceId: row.id
      })
    );
  }

  async function downloadInvoiceUserReportFile(row: TableRow) {
    try {
      const url = `${config.apiUrl}/omni/page/billing-invoice/organization/${params.id}/invoice/${row.id}/user-report/file`;
      await downloadFile(url);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
      backendErrorLog(error, `downloadInvoiceUserReportFile in invoices`);
    }
  }

  function updateSortParams(key: string) {
    let updatedSortBy = sortBy;
    let updatedSortOrder = sortOrder;

    if (key === sortBy) {
      updatedSortOrder = changeSortDirection(sortOrder);
      setSortOrder(updatedSortOrder);
    } else {
      updatedSortBy = key;
      setSortBy(updatedSortBy);
    }
  }

  async function loadMoreInvoices() {
    if (noMoreData) {
      return;
    }

    try {
      const response = await service.fetchInvoices(
        params.id,
        params.licenseId,
        rangePickerValue,
        {
          page: ++invoicesMeta.currentPage,
          sortOrder: sortOrder,
          sortBy: sortBy
        }
      );
      const { items } = response;
      checkNoMoreData(items?.length, response?.meta?.totalItems, setNoMoreData);
      setInvoices([...invoices, ...items]);
      setInvoicesMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const isFieldsFilled: boolean = useMemo(() => {
    return shownAdditionalPayment?.reduce((result, item) => {
      return (
        result &&
        !!item.service &&
        !!item.from &&
        !!item.to &&
        typeof item.price === 'number' &&
        !isNaN(item.price)
      );
    }, true);
  }, [shownAdditionalPayment]);

  /** Sidemenu */
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  useEffect(() => {
    sidemenuContext.setActiveMenu(ORGANIZATION_SIDEMENUS.ORGANIZATION_INVOICES);
  }, [sidemenuContext]);

  /** Breadcrumbs */
  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.INVOICES,
        { id: params?.id }
      )
    ],
    [sidemenuContext, params.id, t]
  );

  return (
    <Fragment>
      <Row cols={10}>
        <Breadcrumbs
          NavLink={NavLink}
          className={'breadcrumbs'}
          items={breadcrumbs}
        />
        <Col col={9} className={styles.heading}>
          <Typography element="div" variant="h2">
            {t('organizations.invoices')}
          </Typography>

          {!isEmptyState && (
            <div className={styles.buttons}>
              <Button type="third" icon="update" onClick={runBilling}>
                {t('organizations.runBilling')}
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <Row cols={10} style={{ marginRight: 0 }}>
        {!isLoading && isEmptyState && (
          <EmptyState
            heading={t('uiKit.oopsItIsEmpty')}
            description={t('alerts.looksLikeTheOrganizationHasNoInvoices')}
            onClick={runBilling}
            buttonText={t('organizations.runBilling')}
            buttonIcon={'update'}
            isCentered={true}
          />
        )}
        {!isEmptyState && (
          <Fragment>
            <div className={styles.infographics}>
              <SystemInfographics
                className={offsets['mr-40']}
                heading={t('organizations.lastBillingDate')}
                content={
                  information?.lastInvoiceDate
                    ? formatDate(information?.lastInvoiceDate)
                    : DASH
                }
              />

              <SystemInfographics
                className={offsets['mr-40']}
                heading={t('organizations.licensePayments')}
                content={
                  information?.currencyIso3
                    ? formatCurrency(
                        information?.currencyIso3,
                        information?.licensePayments || 0,
                        2
                      )
                    : DASH
                }
              />

              <SystemInfographics
                className={offsets['mr-40']}
                heading={t('organizations.additionalPayments')}
                content={
                  information?.currencyIso3
                    ? formatCurrency(
                        information?.currencyIso3,
                        information?.additionalPayments || 0,
                        2
                      )
                    : DASH
                }
              />

              <SystemInfographics
                className={offsets['mr-40']}
                heading={t('common.total')}
                content={
                  information?.currencyIso3
                    ? formatCurrency(
                        information?.currencyIso3,
                        information?.totalPayments || 0,
                        2
                      )
                    : DASH
                }
              />
            </div>

            <div>
              <DatePicker
                className={offsets['mb-20']}
                locale={getCurrentLanguage()}
                type="range"
                dateIntervalType={DateIntervalType.Month}
                rangePickerValue={rangePickerValue}
                disabled={isLoading}
                onRangePickerChange={setRangePickerValue}
              />
            </div>
          </Fragment>
        )}
      </Row>

      {!isEmptyState && (
        <Row cols={10}>
          <Col col={9}>
            <Table
              isLoading={isLoading}
              className={offsets['mb-40']}
              rows={activeUserRows}
              columns={invoiceColumns}
              actions={invoicesActions}
              filterActionsForRow={filterActionsForRow}
              noMoreData={noMoreData}
              noMoreItemsText={t('common.noMoreItems')}
              onLoading={loadMoreInvoices}
              maxHeight="640px"
              minWidth={900}
              onSort={updateSortParams}
              sortOrder={sortOrder}
              sortBy={sortBy}
              noResults={noResults}
              noResultsText={t('common.noResults')}
            />
          </Col>
        </Row>
      )}

      {isModalOpen && (
        <Modal
          title={'Additional payments'}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          cancelText={t('common.cancel')}
          submitText={t('common.save')}
          isSubmitDisabled={!isFieldsFilled}
          onCancel={() => setIsModalOpen(false)}
          onSubmit={saveAdditionalPayment}
          maxHeight="637px"
          maxWidth="1000px"
        >
          <Typography
            className={offsets['mt-40']}
            variant="system"
            element="div"
          >
            {t('organizations.organization')}:{' '}
            {sidemenuContext?.currentOrganization?.name || DASH}
          </Typography>
          <Typography variant="system" element="div">
            {t('common.license')}: {shownInvoice?.licenseName || DASH}
          </Typography>
          <Typography variant="system" element="div">
            {t('organizations.invoiceDate')}:{' '}
            {shownInvoice?.date ? formatDate(shownInvoice.date) : DASH}
          </Typography>
          <Typography variant="system" element="div">
            {t('organizations.paymentMethod')}:{' '}
            {convertPaymentMethodToText(shownInvoice?.paymentMethod) || DASH}
          </Typography>

          <AdditionalPaymentsList
            className={offsets['mt-40']}
            additionalPayments={shownAdditionalPayment}
            onChange={setShownAdditionalPayment}
            currency={information?.currencyIso3}
            translations={{
              delete: t('common.delete'),
              service: t('common.service'),
              comment: t('common.comment'),
              price: t('common.price'),
              amountOfUsers: t('common.amountOfUsers')
            }}
          />
        </Modal>
      )}

      <Alert
        isOpen={isAlertOpen}
        onSubmit={alertSubmitFunction}
        onClose={() => setIsAlertOpen(false)}
        cancelText={t('common.cancel')}
        submitText={submitText}
      >
        <div dangerouslySetInnerHTML={{ __html: alertText }} />
      </Alert>
    </Fragment>
  );
};

Invoices.displayName = 'Invoices';
