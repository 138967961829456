import React, { FC } from 'react';
import styles from '../../License.module.scss';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  offsets,
  SystemInfographics,
  formatDate,
  getCurrencySign,
  Superscript,
  formatNumber,
  convertPaymentMethodToText,
  convertBillingFrequencyToText
} from '@xq/ui-kit';
import { LicensePageData } from '@pages';
import { DASH } from '@constants';

interface LicenseSystemInfographicsProps {
  information: LicensePageData;
  isLoading: boolean;
  isMD: boolean;
}

export const LicenseSystemInfographics: FC<LicenseSystemInfographicsProps> = (
  props
) => {
  const { t } = useTranslation();
  const { information, isLoading, isMD } = props;

  return (
    <div className={styles.statistics}>
      {!information?.isTrial && (
        <>
          <SystemInfographics
            isLoading={isLoading}
            className={offsets['mr-40']}
            heading={t('organizations.nextInvoiceDate')}
            content={
              information?.nextInvoiceDate
                ? formatDate(information?.nextInvoiceDate)
                : DASH
            }
            isError={!isLoading && !information?.nextInvoiceDate}
          />

          <SystemInfographics
            isLoading={isLoading}
            className={offsets['mr-40']}
            heading={t('organizations.invoicing')}
            content={
              information?.billingFrequency
                ? convertBillingFrequencyToText(information?.billingFrequency)
                : DASH
            }
            isError={!isLoading && !information?.billingFrequency}
          />

          <SystemInfographics
            isLoading={isLoading}
            className={offsets['mr-40']}
            heading={t('organizations.method')}
            content={
              information?.paymentMethod
                ? convertPaymentMethodToText(information?.paymentMethod)
                : DASH
            }
            isError={!isLoading && !information?.paymentMethod}
          />

          <SystemInfographics
            isLoading={isLoading}
            className={offsets['mr-40']}
            heading={`${t('common.price')} / ${t('common.monthly')}` || DASH}
            isError={!isLoading && !information?.pricePerMonth}
          >
            <Superscript
              content={
                information?.pricePerMonth
                  ? formatNumber(information?.pricePerMonth, 2)
                  : DASH
              }
              sign={getCurrencySign(information?.currencyIso3)}
              variant="h3"
              isError={!isLoading && !information?.pricePerMonth}
            />
          </SystemInfographics>
        </>
      )}

      {information?.isTrial && (
        <>
          <SystemInfographics
            isLoading={isLoading}
            className={offsets['mr-40']}
            heading={t('organizations.activationDate')}
            content={
              information?.createdAt ? formatDate(information?.createdAt) : DASH
            }
          />

          <SystemInfographics
            isLoading={isLoading}
            className={offsets['mr-40']}
            heading={t('uiKit.expiryDate')}
            content={
              information?.expireAt ? formatDate(information?.expireAt) : DASH
            }
            isError={!isLoading && information?.isExpired}
          />
        </>
      )}

      <SystemInfographics
        isLoading={isLoading}
        className={offsets['mr-40']}
        heading={t('organizations.activeUsers')}
        content={
          information?.activeUsers ? String(information?.activeUsers) : DASH
        }
      />

      {information?.licenseFeatures &&
        information?.licenseFeatures.length > 0 && (
          <SystemInfographics
            isLoading={isLoading}
            heading={t('common.licenseFeatures')}
          >
            {information?.licenseFeatures?.map((feature, key) => {
              return (
                <Typography
                  key={key}
                  element={isMD ? 'span' : 'div'}
                  variant="body-4"
                  className={offsets['mr-16']}
                >
                  {feature}
                </Typography>
              );
            })}
          </SystemInfographics>
        )}
    </div>
  );
};

LicenseSystemInfographics.displayName = 'LicenseSystemInfographics';
