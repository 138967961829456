import React, { FC, Fragment } from 'react';
import styles from '../../License.module.scss';
import { useTranslation } from 'react-i18next';
import { Col, Typography, offsets, Chevron, colors } from '@xq/ui-kit';
import { LicensePageData } from '@pages';

interface LicenseInformationChevronsProps {
  information: LicensePageData;
}

export const LicenseInformationChevrons: FC<LicenseInformationChevronsProps> = (
  props
) => {
  const { t } = useTranslation();
  const { information } = props;

  return (
    <Col col={9} className={offsets['mb-40']}>
      {information && (
        <Fragment>
          {(information?.isTrial ||
            information?.isSuspended ||
            information?.isExpired ||
            !information?.hasPricing) && (
            <div className={styles.subheading}>
              {information?.isTrial && (
                <Chevron className={offsets['mr-20']}>
                  {t('uiKit.trialVersion')}
                </Chevron>
              )}

              {information?.isSuspended && (
                <Typography
                  className={offsets['mr-20']}
                  variant="system-heading"
                  color={colors.red70Color}
                >
                  {t('common.suspended')}
                </Typography>
              )}

              {information?.isExpired && (
                <Typography
                  variant="system-heading"
                  color={colors.red70Color}
                  className={offsets['mr-20']}
                >
                  {t('common.expired')}
                </Typography>
              )}

              {!information?.hasPricing && !information?.isExpired && (
                <Typography variant="system-heading" color={colors.red70Color}>
                  {t('common.noPricing')}
                </Typography>
              )}
            </div>
          )}
        </Fragment>
      )}
    </Col>
  );
};

LicenseInformationChevrons.displayName = 'LicenseInformationChevrons';
