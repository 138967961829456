import React, { FC } from 'react';
import styles from '../../License.module.scss';
import { Button, offsets, openStatusNotification } from '@xq/ui-kit';
import { LicensePageData } from '@pages';
import { useTranslation } from 'react-i18next';
import { getRouteUrl, ROUTES } from '@router';
import { useNavigate, useParams } from 'react-router-dom';
import { getStatusNotificationTranslations } from '@services';
import { LicenseService, LicenseServiceApi } from '../../license-service';

interface LicenseInformationButtonsProps {
  information: LicensePageData;
  setInformation: (information: LicensePageData) => void;
  isMD: boolean;
}

export const LicenseInformationButtons: FC<LicenseInformationButtonsProps> = (
  props
) => {
  const { information, setInformation, isMD } = props;

  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const service: LicenseService = new LicenseServiceApi();

  const redirectToPricing = () => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.PRICING, {
        id: params.id,
        licenseId: params?.licenseId
      })
    );
  };

  const redirectToEditLicense = () => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.EDIT_LICENSE, {
        id: params?.id,
        licenseId: params?.licenseId
      })
    );
  };

  async function activateLicense() {
    try {
      await service.activateLicense(params.id, params.licenseId);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: t('notifications.licenseIsSuccessfullyActivated')
      });
      setInformation({
        ...information,
        isSuspended: false
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function suspendLicense() {
    try {
      await service.suspendLicense(params.id, params.licenseId);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: t('notifications.licenseIsSuccessfullySuspended')
      });
      setInformation({
        ...information,
        isSuspended: true
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  return (
    <div className={styles.buttons}>
      {!information?.isExpired && (
        <Button
          type="fourth"
          icon="dollar-sign"
          className={isMD ? offsets['mr-40'] : offsets['mr-30']}
          onClick={redirectToPricing}
        >
          {isMD && t('common.pricing')}
        </Button>
      )}

      <Button
        type="fourth"
        icon="edit"
        disabled={information?.isSuspended}
        className={isMD ? offsets['mr-40'] : offsets['mr-30']}
        onClick={redirectToEditLicense}
      >
        {isMD && t('common.edit')}
      </Button>

      {information?.isSuspended ? (
        <Button
          type="fourth"
          icon="play"
          className={isMD ? offsets['mr-40'] : offsets['mr-30']}
          onClick={activateLicense}
        >
          {isMD && t('common.activate')}
        </Button>
      ) : (
        <Button
          type="fourth"
          icon="pause"
          disabled={information?.isExpired}
          className={isMD ? offsets['mr-40'] : offsets['mr-30']}
          onClick={suspendLicense}
        >
          {isMD && t('common.suspend')}
        </Button>
      )}
    </div>
  );
};

LicenseInformationButtons.displayName = 'LicenseInformationButtons';
