import {
  Alert,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Col,
  ContactPerson as ContactPersonCard,
  EmptyState,
  openStatusNotification,
  Row,
  Typography
} from '@xq/ui-kit';
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './ContactPersons.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  getBreadcrumbWithDropdown,
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS
} from '@services';
import { getRouteUrl, ROUTES } from '@router';
import { SidemenuContext, SidemenuContextData } from '@context';
import {
  ContactPersonsService,
  ContactPersonsServiceApi
} from './contact-persons-service';
import { ContactPerson } from '@pages/Organizations/ContactPersons/dataTypes';

export const ContactPersons: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);
  const service: ContactPersonsService = new ContactPersonsServiceApi();

  const [contactPersons, setContactPersons] = useState<ContactPerson[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const [contactPersonToDelete, setContactPersonToDelete] =
    useState<ContactPerson>(null);

  async function fetchData() {
    try {
      setIsLoading(true);
      const response = await service.fetchData(params.id);
      setContactPersons(response?.contactPersons);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [params.id]);

  const redirectToEditContactPerson = (contactPerson: ContactPerson) => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.EDIT_CONTACT_PERSON, {
        id: params.id,
        contactPersonId: contactPerson.uuid
      })
    );
  };

  async function createContactPerson() {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.CREATE_CONTACT_PERSON, {
        id: params.id
      })
    );
  }

  async function deleteContactPerson(contactPerson: ContactPerson) {
    try {
      await service.deleteContactPerson(params.id, contactPerson.uuid);
      const updatedContactPersons = contactPersons?.filter(
        (el: ContactPerson) => el.uuid !== contactPerson.uuid
      );
      setContactPersons(updatedContactPersons);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setAlertText('');
      setContactPersonToDelete(null);
    }
  }

  async function deleteContactPersonSubmit(contactPerson: ContactPerson) {
    setIsAlertOpen(true);
    setAlertText(
      `${t('alerts.areYouSureYouWantToDelete')} <b>${contactPerson.name}</b>?`
    );
    setContactPersonToDelete(contactPerson);
  }

  /** Breadcrumbs context */
  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.CONTACT_PERSONS,
        { id: params?.id }
      )
    ],
    [sidemenuContext, params]
  );

  /** Sidemenu */
  useEffect(() => {
    sidemenuContext.setActiveMenu(
      ORGANIZATION_SIDEMENUS.ORGANIZATION_CONTACT_PERSONS
    );
  }, [sidemenuContext]);

  return (
    <Fragment>
      <Row cols={10}>
        <Breadcrumbs
          className="breadcrumbs"
          items={breadcrumbs}
          NavLink={NavLink}
        />

        <Col col={9} className="heading">
          <Typography element="div" variant="h2">
            {t('organizations.contactPersons')}
          </Typography>
          {contactPersons && contactPersons.length > 0 && (
            <Button type="third" icon="user-plus" onClick={createContactPerson}>
              {t('common.create')}
            </Button>
          )}
        </Col>

        <Col col={9} className={styles.contacts}>
          {contactPersons &&
            contactPersons?.length > 0 &&
            contactPersons?.map((contactPerson: ContactPerson) => {
              return (
                <ContactPersonCard
                  key={contactPerson.uuid}
                  role={contactPerson.position}
                  name={contactPerson.name}
                  phoneNumber={contactPerson.phone}
                  email={contactPerson.email}
                  comments={contactPerson.comment}
                  isEdit={true}
                  onDelete={() => deleteContactPersonSubmit(contactPerson)}
                  onEdit={() => redirectToEditContactPerson(contactPerson)}
                  translations={{
                    seeDetails: t('uiKit.seeDetails'),
                    role: t('uiKit.role'),
                    name: t('common.name'),
                    phoneNumber: t('common.phoneNumber'),
                    email: t('common.email'),
                    comments: t('uiKit.comments'),
                    delete: t('common.delete'),
                    edit: t('common.edit')
                  }}
                />
              );
            })}

          {!isLoading && (!contactPersons || contactPersons.length === 0) && (
            <EmptyState
              heading={t('uiKit.oopsItIsEmpty')}
              description={t(
                'alerts.looksLikeTheOrganizationHasNoContactPersons'
              )}
              onClick={createContactPerson}
              buttonText={t('common.create')}
              buttonIcon={'user-plus'}
              isCentered={true}
            />
          )}
        </Col>
      </Row>
      <Alert
        isOpen={isAlertOpen}
        onSubmit={() => deleteContactPerson(contactPersonToDelete)}
        onClose={() => setIsAlertOpen(false)}
        cancelText={t('common.cancel')}
        submitText={t('common.submit')}
      >
        <div dangerouslySetInnerHTML={{ __html: alertText }} />
      </Alert>
    </Fragment>
  );
};

ContactPersons.displayName = 'Licenses';
