import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { SelectItem } from '@xq/ui-kit';
import { Release } from '@pages/ModelTree/CreateRelease/dataTypes';
import { ModelTreeReleaseCreatePageApi } from '@xq/omni-gateway-frontend-client';

export interface CreateReleaseService {
  fetchData(buildId: number): Promise<SelectItem[]>;
  create(release: Release): Promise<void>;
}

const omniCreateReleaseGateway = new ModelTreeReleaseCreatePageApi(
  omniApiConfiguration
);

export class CreateReleaseService implements CreateReleaseService {
  async fetchData(buildId: number): Promise<SelectItem[]> {
    try {
      const response =
        await omniCreateReleaseGateway.modelTreeReleaseCreatePageControllerGetPageData(
          { modelTreeBuildId: buildId }
        );
      return response.organizations;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async create(release: Release): Promise<void> {
    try {
      await omniCreateReleaseGateway.modelTreeReleaseCreatePageControllerCreateRelease(
        {
          modelTreeBuildId: Number(release?.buildId),
          modelTreeReleaseInfoDTORequestCreate: {
            version: release?.releaseVersion,
            description: release?.description,
            notes: release?.releaseNotes,
            emailNotificationDescription: release?.emailNotificationDescription,
            isPrivate: release?.status?.isPrivate,
            organizationUuids: release?.status?.organizations?.map(
              (el) => el.value
            )
          }
        }
      );
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.create.name);
    }
  }
}

const fetchDataMock: SelectItem[] = [
  {
    value: '1',
    label: 'Exquance'
  },
  {
    value: '2',
    label: '2NS'
  },
  {
    value: '3',
    label: 'Ilmarinen'
  }
];

export class CreateReleaseServiceMock implements CreateReleaseService {
  async fetchData(): Promise<SelectItem[]> {
    try {
      await wait(1000);
      return fetchDataMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async create(release: Release): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.create.name);
    }
  }
}
