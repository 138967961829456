import packageJson from '../../package.json';
import {
  AuthApi,
  Configuration as IamConfiguration,
  ConfigurationParameters
} from '@xq/iam-frontend-client';
import { config } from '@config';
import { Configuration as OmniConfiguration } from '@xq/omni-gateway-frontend-client';

export interface AppVersion {
  version: string;
  name: string;
}

export const appVersion: AppVersion = {
  version: packageJson.version,
  name: packageJson.name
};

const getConfiguration = (basePath: string): ConfigurationParameters => {
  return {
    basePath: basePath,
    credentials: 'include',
    headers: {
      'xq-app-version': appVersion.version,
      'xq-app-name': appVersion.name
    }
  };
};

export const iamGateway = new AuthApi(
  new IamConfiguration(getConfiguration(config.apiUrl))
);

/** Usage example:
 *** const omniUsersGateway = new LayoutApi(omniApiConfiguration)
 **/

export const omniApiConfiguration = new OmniConfiguration(
  getConfiguration(`${config.apiUrl}/omni`)
);
