import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { SelectItem } from '@xq/ui-kit';
import { EditReleaseData, Release } from './dataTypes';
import {
  ModelTreeReleaseUpdatePageApi,
  ModelTreeReleaseUpdatePageInfoDTOResponse
} from '@xq/omni-gateway-frontend-client';

export interface EditReleaseService {
  fetchData(buildId: number): Promise<EditReleaseData>;
  save(release: Release): Promise<void>;
}

const omniEditReleaseGateway = new ModelTreeReleaseUpdatePageApi(
  omniApiConfiguration
);

export class EditReleaseService implements EditReleaseService {
  async fetchData(buildId: number): Promise<EditReleaseData> {
    try {
      const response: ModelTreeReleaseUpdatePageInfoDTOResponse =
        await omniEditReleaseGateway.modelTreeReleaseUpdatePageControllerGetPageData(
          { modelTreeBuildId: buildId }
        );
      const info = response?.releaseInfo;
      return {
        releaseInfo: {
          uuid: String(info?.buildId),
          buildBranch: info?.branchName,
          buildId: info?.buildId,
          releaseVersion: info?.version,
          description: info?.description,
          releaseNotes: info?.notes,
          emailNotificationDescription: info?.emailNotificationDescription,
          publishDate: info?.publishDate,
          status: {
            isPrivate: info?.isPrivate,
            organizations: info?.organizations
          }
        },
        organizations: response?.organizations
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(release: Release): Promise<void> {
    try {
      await omniEditReleaseGateway.modelTreeReleaseUpdatePageControllerUpdateRelease(
        {
          modelTreeBuildId: release?.buildId,
          modelTreeReleaseInfoDTORequestUpdate: {
            version: release?.releaseVersion,
            description: release?.description,
            notes: release?.releaseNotes,
            emailNotificationDescription: release?.emailNotificationDescription,
            isPrivate: release?.status?.isPrivate,
            organizationUuids: release?.status?.organizations?.map(
              (el) => el.value
            )
          }
        }
      );
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}

const organizationsListMock: SelectItem[] = [
  {
    value: '1',
    label: 'Exquance'
  },
  {
    value: '2',
    label: '2NS'
  },
  {
    value: '3',
    label: 'Ilmarinen'
  }
];

const releaseMock: Release = {
  uuid: '1',
  buildBranch: 'implement-2fa-feature',
  buildId: 1,
  releaseVersion: '2.1.4',
  description: 'Now you can make quick notes/comments/plans for assets.',
  releaseNotes:
    '##### Asset Notes\n' +
    'Now you can make quick notes/comments/plans for assets. Asset managers can communicate, store and share useful information on a daily basis of the assets they manage. A note can for example be a compact business plan of the asset or some task like "Lease 500 sqm vacant space at floor 4".\n' +
    "Notes are also useful for valuers. It's possible to record and track internal notes about the valuation work, for example explanation why certain parameters have chosen to financial model, some specific characteristics of the building that affect the value, market observations and so on.\n" +
    'Asset notes allow you to communicate asset plans and valuation tasks across your team.\n' +
    '##### Map improvements\n' +
    "* Now it's possible to show assets on the map filtered by additional attributes: country, region, asset grade, asset manager, valuator, type\n" +
    '* Lot of new KPIs was added to be displayed along with asset map pushpins\n' +
    'Bing Maps is now the default map layer provider\n' +
    '* Automatically fill asset name (when available) from geolocation when creating asset from map\n' +
    'Other fixes and improvements\n' +
    '* Show currency in Market assumptions table headers\n' +
    '* Fix cell formatting when exporting Assets grid to Excel\n' +
    '* Fix updating asset Ownership when changing relations on Diagram\n' +
    '* Improved design of Hold-Sell indicator\n' +
    '* Redesign of "Updates window"\n' +
    '* Fix few KPI calculations in parent asset\n' +
    '* Allow to export reports in DOCX format\n' +
    '* Fix Idle Time calculation (for future premises idle time begins not just from Analysis Start Date, but from Life * * * Start Date of the premise)\n' +
    '* Other small UI fixes',
  emailNotificationDescription:
    '##### Asset Notes\n' +
    'Now you can make quick notes/comments/plans for assets. Asset managers can communicate, store and share useful information on a daily basis of the assets they manage. A note can for example be a compact business plan of the asset or some task like "Lease 500 sqm vacant space at floor 4".\n' +
    "Notes are also useful for valuers. It's possible to record and track internal notes about the valuation work, for example explanation why certain parameters have chosen to financial model, some specific characteristics of the building that affect the value, market observations and so on.\n" +
    'Asset notes allow you to communicate asset plans and valuation tasks across your team.\n',
  status: {
    isPrivate: true,
    organizations: [organizationsListMock[0]]
  }
};

export class EditReleaseServiceMock implements EditReleaseService {
  async fetchData(buildId: number): Promise<EditReleaseData> {
    try {
      await wait(1000);
      return {
        releaseInfo: releaseMock,
        organizations: organizationsListMock
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(release: Release): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}
