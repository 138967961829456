import React, { FC, Fragment, useEffect, useState } from 'react';
import styles from './MainInformation.module.scss';
import {
  Col,
  GeneralInformation,
  offsets,
  openStatusNotification,
  Row,
  Typography,
  Users
} from '@xq/ui-kit';
import { useTranslation } from 'react-i18next';
import { getStatusNotificationTranslations } from '@services';
import { MainInformationService } from './main-information-service';
import { MainInformationData } from './dataTypes';
import { getRouteUrl, ROUTES } from '@router';
import { useNavigate } from 'react-router-dom';

export const MainInformation: FC = () => {
  const { t } = useTranslation();
  const service: MainInformationService = new MainInformationService();
  const navigate = useNavigate();

  const [information, setInformation] = useState<MainInformationData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await service.fetchData();
      setInformation(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const redirectToOrganizations = () => {
    navigate(getRouteUrl(ROUTES.ORGANIZATIONS.MAIN));
  };

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={10}>
          <Typography className={offsets['mb-40']} element="div" variant="h2">
            {t('routes.mainInformation')}
          </Typography>

          <div className={styles.grid}>
            <GeneralInformation
              className={styles.general}
              organizationsNumber={information?.general?.totalOrganizations}
              estimatedMonthlyPayments={information?.general?.monthlyIncome}
              isLoading={isLoading}
              translations={{
                generalInformation: t('uiKit.generalInformation'),
                organizations: t('organizations.organizations'),
                estimatedMonthlyPayment: t('uiKit.estimatedMonthlyPayment'),
                seeDetails: t('uiKit.seeDetails')
              }}
              onSeeDetails={redirectToOrganizations}
            />

            <Users
              className={styles.users}
              totalUsers={information?.userStatistic?.totalUsers}
              activeUsers={information?.userStatistic?.activeUsers}
              externalUsers={information?.userStatistic?.externalUsers}
              administrators={information?.userStatistic?.administrators}
              isLoading={isLoading}
              translations={{
                totalUsers: t('organizations.totalUsers'),
                activeUsers: t('organizations.activeUsers'),
                externalUsers: t('uiKit.externalUsers'),
                administrators: t('organizations.administrators'),
                users: t('common.users')
              }}
              isHorizontalView={true}
            />
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

MainInformation.displayName = 'MainInformation';
