import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { ContactPerson } from './dataTypes';
import { ContactPersonCreatePageApi } from '@xq/omni-gateway-frontend-client';

export interface CreateContactPersonService {
  create(organizationUuid: string, contactPerson: ContactPerson): Promise<void>;
}

const createContactPersonGateway = new ContactPersonCreatePageApi(
  omniApiConfiguration
);

export class CreateContactPersonServiceApi
  implements CreateContactPersonService
{
  async create(
    organizationUuid: string,
    contactPerson: ContactPerson
  ): Promise<void> {
    try {
      await createContactPersonGateway.contactPersonCreatePageControllerCreate({
        organizationUuid,
        contactPersonDTORequestCreate: {
          name: contactPerson.name,
          position: contactPerson.position,
          email: contactPerson.email,
          phone: contactPerson.phone,
          comment: contactPerson.comments
        }
      });
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.create.name);
    }
  }
}

export class CreateContactPersonServiceMock
  implements CreateContactPersonService
{
  async create(
    organizationUuid: string,
    contactPerson: ContactPerson
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.create.name);
    }
  }
}
