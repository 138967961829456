import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { SelectItem } from '@xq/ui-kit';
import { MimicryPage } from '@pages/Mimicry/Mimicry/dataTypes';
import { MimicryPageApi } from '@xq/omni-gateway-frontend-client';

export interface MimicryService {
  fetchData(): Promise<MimicryPage>;
  mimicry(organization: SelectItem): Promise<void>;
  stopMimicry(): Promise<void>;
}

const mimicryGateway = new MimicryPageApi(omniApiConfiguration);

export class MimicryService implements MimicryService {
  async fetchData(): Promise<MimicryPage> {
    try {
      return await mimicryGateway.mimicryPageControllerGetPageData();
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async mimicry(organization: SelectItem): Promise<void> {
    try {
      await mimicryGateway.mimicryPageControllerSetUserMimicryOrganizationInfo({
        organizationUuid: organization?.value
      });
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.mimicry.name);
    }
  }

  async stopMimicry(): Promise<void> {
    try {
      await mimicryGateway.mimicryPageControllerUnsetUserMimicryOrganizationInfo();
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.stopMimicry.name
      );
    }
  }
}

const organizationsMock: SelectItem[] = [
  {
    value: '1',
    label: 'Exquance'
  },
  { value: '2', label: '2NS' },
  { value: '3', label: 'Tapiola' }
];

export class MimicryServiceMock implements MimicryService {
  async fetchData(): Promise<MimicryPage> {
    try {
      await wait(1000);
      return {
        organizations: organizationsMock,
        selectedMimicryOrganization: null
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async mimicry(organization: SelectItem): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.mimicry.name);
    }
  }

  async stopMimicry(): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.stopMimicry.name
      );
    }
  }
}
