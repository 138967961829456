import React, { Fragment } from 'react';
import { TableColumn, formatDate, Icon, Typography, offsets } from '@xq/ui-kit';
import { TFunction } from 'i18next';
import { ReleasesItem } from '@pages';
import styles from './Releases.module.scss';
import { DASH } from '@constants';

/** Methods for the table */
export const createReleasesRows = (t: TFunction, releases: ReleasesItem[]) => {
  return releases?.map((release) => ({
    id: release?.uuid,
    data: {
      buildId: release?.buildId || DASH,
      version: (
        <div className={styles['status-wrap']}>
          <Icon
            className={
              release?.publishDate
                ? styles['status--published']
                : styles['status--created']
            }
            name={'status'}
            size={'s'}
          />
          <Typography variant={'body-4'}>
            {release?.releaseVersion || DASH}
          </Typography>
        </div>
      ),
      description: release?.description || DASH,
      createdAt: release?.createOn ? formatDate(release?.createOn) : DASH,
      publishDate: release?.publishDate ? formatDate(release?.publishDate) : '',
      isPrivate: (
        <Fragment>
          <Icon
            size={'s'}
            name={release?.status?.isPrivate ? 'cloud-private' : 'cloud-public'}
          />
          {release?.status?.organizations && (
            <Typography
              element={'div'}
              variant={'body-4'}
              className={offsets['mt-8']}
            >
              {getReleaseOrganizationsList(
                release?.status?.organizations,
                release?.status?.isPrivate
              )}
            </Typography>
          )}
        </Fragment>
      )
    }
  }));
};

export const createReleasesColumns = (t: TFunction): TableColumn[] => [
  {
    title: t('modelTree.buildId'),
    key: 'buildId',
    width: 100,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('modelTree.releaseVersion'),
    key: 'version',
    width: 160,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('common.description'),
    key: 'description',
    width: 240,
    alignment: 'left',
    paddingLeft: 20
  },
  {
    title: t('common.createOn'),
    key: 'createdAt',
    width: 100,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('modelTree.publishedOn'),
    key: 'publishDate',
    width: 100,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('common.status'),
    key: 'isPrivate',
    width: 150,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  }
];

const getReleaseOrganizationsList = (
  organizations: string[],
  isPrivate: boolean
): string => {
  const length = organizations?.length;
  if (!organizations || length === 0) {
    return null;
  }
  return organizations?.reduce(
    (res, organization, idx) => {
      const separator = idx < length - 1 ? ', ' : '';
      return res + organization + separator;
    },
    isPrivate ? '+ ' : '- '
  );
};
