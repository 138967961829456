import React, { useState, FC, useEffect, useRef } from 'react';
import {
  Sidemenu,
  MenuItem,
  openStatusNotification,
  useCurrentWidth
} from '@xq/ui-kit';
import { NavLink, Outlet, useLocation, useParams } from 'react-router-dom';
import styles from './SidemenuLayout.module.scss';
import { SidemenuContext } from '@context';
import {
  SidemenuLayoutService,
  SidemenuLayoutLicense
} from './sidemenu-layout-service';
import {
  generateModelTreeSidemenu,
  generateOrganizationSidemenu,
  getStatusNotificationTranslations
} from '@services';
import { useTranslation } from 'react-i18next';
import { OrganizationName } from 'interfaces';
import { SECTION_URL } from '@router';

interface SidemenuLayoutProps {}

export const SidemenuLayout: FC<SidemenuLayoutProps> = () => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const service: SidemenuLayoutService = new SidemenuLayoutService();

  const [activeMenu, setActiveMenu] = useState<string>('');
  const [sideMenuWidth, setSideMenuWidth] = useState<number>(null);
  const [licenses, setLicenses] = useState<SidemenuLayoutLicense[]>([]);
  const [organizations, setOrganizations] = useState<OrganizationName[]>([]);
  const [currentOrganization, setCurrentOrganization] =
    useState<OrganizationName>();
  const [activeSection, setActiveSection] = useState<string>('');

  const { width } = useCurrentWidth();

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    setSideMenuWidth(ref.current.clientWidth);
  }, [width]);

  const [sidemenuItems, setSidemenuItems] = useState<MenuItem[]>([]);
  const sidemenuContextData = {
    activeMenu,
    setActiveMenu,
    sidemenuItems,
    setSidemenuItems,
    licenses,
    setLicenses,
    currentOrganization,
    setCurrentOrganization,
    organizations,
    setOrganizations
  };

  async function fetchData() {
    if (!params.id) {
      return;
    }
    try {
      const response = await service.fetchData(params.id);
      setLicenses(response?.licenses);
      setCurrentOrganization(response?.currentOrganization);
      setOrganizations(response?.organizations);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  //todo: watch on changing licenses and current org in other pages
  useEffect(() => {
    let sidemenu;
    switch (activeSection) {
      case SECTION_URL.ORGANIZATIONS: {
        sidemenu = generateOrganizationSidemenu(
          t,
          params?.id,
          currentOrganization,
          licenses
        );
        break;
      }
      case SECTION_URL.MODEL_TREE: {
        sidemenu = generateModelTreeSidemenu(t);
        break;
      }
    }

    setSidemenuItems(sidemenu);
  }, [params?.id, licenses, currentOrganization, activeSection]);

  useEffect(() => {
    if (!location?.pathname) {
      setActiveSection(null);
      return;
    }

    const mainLocation = location.pathname?.split('/')[1];
    setActiveSection(mainLocation);
  }, [location?.pathname]);

  useEffect(() => {
    fetchData();
  }, [params.id]);

  return (
    <div className={styles.layout}>
      <SidemenuContext.Provider value={sidemenuContextData}>
        <div ref={ref}>
          {activeSection && (
            <Sidemenu
              id="layout-sidemenu"
              items={sidemenuItems}
              activeMenuKey={activeMenu}
              setActiveMenuKey={setActiveMenu}
              className={styles.sidemenu}
              NavLink={NavLink}
            />
          )}
        </div>

        <div
          style={{ width: `calc(100% - ${sideMenuWidth}px)` }}
          className={styles.page}
        >
          <Outlet />
        </div>
      </SidemenuContext.Provider>
    </div>
  );
};

SidemenuLayout.displayName = 'SidemenuLayout';
