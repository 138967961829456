import React from 'react';

export interface UserContextData {
  firstName: string;
  lastName: string;
  avatar: string;
  languageIso2: string;
  mimicryOrganizationName?: string;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setAvatar: (value: string) => void;
  setLanguageIso2: (value: string) => void;
  setMimicryOrganizationName: (value: string) => void;
}

const userContextData: UserContextData = {
  firstName: '',
  lastName: '',
  avatar: '',
  languageIso2: '',
  mimicryOrganizationName: '',
  setFirstName: () => null,
  setLastName: () => null,
  setAvatar: () => null,
  setLanguageIso2: () => null,
  setMimicryOrganizationName: () => null
};

export const UserContext =
  React.createContext<UserContextData>(userContextData);
