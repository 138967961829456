import {
  handleBackendError,
  MODEL_TREE_DESKTOP_UUID,
  omniApiConfiguration,
  wait
} from '@services';
import { BillingInvoiceDetails, InvoiceDetailsPageData } from '@pages';
import { BillingInvoiceDetailsPageApi } from '@xq/omni-gateway-frontend-client';
import { Currency } from '@xq/ui-kit';

export interface InvoiceDetailsService {
  fetchData(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<InvoiceDetailsPageData>;
}

const omniInvoiceDetailsGateway = new BillingInvoiceDetailsPageApi(
  omniApiConfiguration
);

export class InvoiceDetailsServiceApi implements InvoiceDetailsService {
  async fetchData(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<InvoiceDetailsPageData> {
    try {
      const response =
        await omniInvoiceDetailsGateway.billingInvoiceDetailsPageControllerGetPageData(
          {
            billingInvoiceUuid: invoiceUuid
          }
        );

      const invoiceDetails: BillingInvoiceDetails[] =
        response.invoiceDetails.map((detail) => ({
          ...detail,
          billingInvoiceUuid: invoiceUuid
        }));

      return {
        ...response,
        invoiceDetails,
        currencyIso3: response?.currencyIso3 as Currency
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }
}

const invoiceDetailsMock: BillingInvoiceDetails[] = [
  {
    uuid: '1',
    licenseUuid: MODEL_TREE_DESKTOP_UUID,
    service: 'ModelTree: investment management',
    comment: 'Recalculation of regular license payment for previous period',
    createdAt: new Date('2022-11-19T18:32:04.000Z'),
    from: new Date('2022-11-19T18:32:04.000Z'),
    to: new Date('2022-12-19T18:32:04.000Z'),
    amountOfUsers: null,
    totalPayment: 2000,
    prepayment: null,
    isEditable: false,
    isAdditionalPayment: true
  },
  {
    uuid: '2',
    licenseUuid: MODEL_TREE_DESKTOP_UUID,
    service: 'ModelTree: investment management',
    comment: 'Regular license payment',
    createdAt: new Date('2022-12-19T18:32:04.000Z'),
    from: new Date('2022-11-19T18:32:04.000Z'),
    to: new Date('2022-12-19T18:32:04.000Z'),
    amountOfUsers: 10,
    totalPayment: 12000,
    prepayment: 6000,
    isEditable: true,
    isAdditionalPayment: false
  }
];

const pageDataMock: InvoiceDetailsPageData = {
  invoice: {
    uuid: '1',
    licenseName: 'ModelTree: investment management',
    date: new Date('2022-12-19T18:32:04.000Z'),
    from: new Date('2021-12-19T18:32:04.000Z'),
    to: new Date('2022-12-19T18:32:04.000Z'),
    licenseUuid: MODEL_TREE_DESKTOP_UUID,
    billingFrequency: 'ANNUALLY',
    paymentMethod: 'BEFORE',
    licensePayments: 20000,
    additionalPayments: 5000,
    totalPayments: 25000,
    canBeDeleted: false
  },
  currencyIso3: 'EUR',
  invoiceDetails: invoiceDetailsMock
};

export class InvoiceDetailsServiceMock implements InvoiceDetailsService {
  async fetchData(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<InvoiceDetailsPageData> {
    try {
      await wait(1000);
      return pageDataMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }
}
