import {
  License,
  LicenseInfo,
  UserLicense
} from '@pages/Organizations/CreateEditUser/dataTypes';
import { appNameMapperByUUID } from '@services';
import { LicenseApp } from '@xq/ui-kit';

/** Generate objects to show in license card */
export const mapLicenses = (
  licensesInfo: LicenseInfo[],
  userLicenses: UserLicense[]
): License[] => {
  return licensesInfo?.map((license) => {
    const userLicense = userLicenses?.find(
      (el) => el?.licenseUuid == license?.uuid
    );

    return {
      uuid: license?.uuid,
      app: appNameMapperByUUID(license?.licenseProduct?.uuid)
        ?.name as LicenseApp,
      description: license?.licenseProduct?.description,
      expiryDate: license?.expireAt,
      licenceType: license?.licenseType?.name,
      isTrial: license?.isTrial,
      selectedRole: license?.roles?.find(
        (role) => role?.value === userLicense?.licenseRoleUuid
      ),
      isActive: Boolean(userLicense?.licenseRoleUuid),
      roles: license?.roles
    };
  });
};

export const getUserLicenseFromFullLicenseData = (
  licenses: License[]
): UserLicense[] => {
  return licenses
    ?.filter((license) => license.isActive)
    ?.map((license) => {
      return {
        licenseUuid: license?.uuid,
        licenseRoleUuid: license?.selectedRole?.value
      };
    });
};
