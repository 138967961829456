import React from 'react';
import {
  Dropdown,
  formatDate,
  SelectItem,
  TableColumn,
  Typography,
  UserTableBlock
} from '@xq/ui-kit';
import { LicenseUser } from '@pages';
import { TFunction } from 'i18next';
import { DASH } from '@constants';
import { userTableBlockTranslations } from '@services';

/** Methods for the table */
export const createActiveUserRows = (
  t: TFunction,
  activeUsers: LicenseUser[],
  roles: SelectItem[],
  changeRole: (rowId: string, newRoleValue: string) => void
) => {
  return activeUsers?.map((user) => ({
    id: user?.uuid,
    data: {
      user: userLicenseBlock(user, t, true),
      role: (
        <>
          {roles?.length > 1 ? (
            <Dropdown
              items={roles}
              onValueSelected={(value) => changeRole(user?.uuid, value)}
              selectedItemValue={user?.role?.value}
              variant="simple"
              position="bottom"
            />
          ) : (
            <Typography element="div" variant="body-4">
              {user?.role?.label || DASH}
            </Typography>
          )}
        </>
      ),
      attachDate: (
        <Typography element="div" variant="body-4">
          {user?.attachDate ? formatDate(user?.attachDate) : DASH}
        </Typography>
      )
    }
  }));
};

export const createInactiveUserRows = (
  t: TFunction,
  inactiveUsers: LicenseUser[]
) => {
  return inactiveUsers?.map((user) => ({
    id: user?.uuid,
    data: {
      user: userLicenseBlock(user, t, false)
    }
  }));
};

export const createActiveUserColumns = (t: TFunction): TableColumn[] => [
  {
    title: t('common.user'),
    key: 'user',
    width: 310,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('organizations.userRole'),
    key: 'role',
    width: 100,
    alignment: 'left',
    paddingLeft: 20,
    sortable: false
  },
  {
    title: t('organizations.attachOn'),
    key: 'attachDate',
    width: 98,
    alignment: 'left',
    paddingLeft: 20,
    sortable: false
  }
];

export const createInactiveUserColumns = (t: TFunction): TableColumn[] => [
  {
    title: t('common.user'),
    key: 'user',
    width: 310,
    alignment: 'left',
    paddingLeft: 20
  }
];

export const mapColumnKey = (key: string): string => {
  switch (key) {
    case 'user': {
      return 'fullname';
    }
    default: {
      return key;
    }
  }
};

function userLicenseBlock(user: LicenseUser, t: TFunction, isActive: boolean) {
  return (
    <UserTableBlock
      user={{
        fullname: user?.fullname,
        email: user?.email,
        avatar: user?.avatar,
        isExternal: user?.isExternal,
        isOrganizationAdmin: user?.isOrganizationAdmin,
        fromAzure: user?.fromAzure
      }}
      showAvatar
      isActive={isActive}
      translations={userTableBlockTranslations(t)}
    />
  );
}
