import { OrganizationName } from '../interfaces';
import { getRouteUrl, ROUTES } from '@router';
import { CountryIso3, MenuItem } from '@xq/ui-kit';
import { TFunction } from 'i18next';
import { SidemenuLayoutLicense } from 'layouts/SidemenuLayout/sidemenu-layout-service';
import { SidemenuContextData } from '@context';
import { generateCompaniesList } from '@pages';

export const ORGANIZATION_SIDEMENUS = {
  ORGANIZATION: 'organization',
  ORGANIZATION_INFO: 'organization-info',
  ORGANIZATION_USERS: 'organization-users',
  ORGANIZATION_LICENSES: 'organization-licenses',
  FUNDY: 'fundy',
  MODEL_TREE_INVESTMENT: 'model-tree-investment',
  ORGANIZATION_INVOICES: 'organization-invoices',
  ORGANIZATION_CONTACT_PERSONS: 'organization-contact-persons',
  ORGANIZATION_DATABASE_CONNECTION: 'organization-database-connection',
  ORGANIZATION_SECURITY_SETTINGS: 'organization-security-settings',
  ORGANIZATION_ACTIVITY_LOG: 'organization-activity-log',
  ORGANIZATION_API_KEYS: 'api-keys'
};

/* todo add other routes */
/* todo replace to {params} */
/* todo replace ORGANIZATION_SIDEMENUS to ROUTES objects */
export const generateOrganizationSidemenu = (
  t: TFunction,
  organizationId: string,
  organization?: OrganizationName,
  licenses?: SidemenuLayoutLicense[]
): MenuItem[] => [
  {
    key: ORGANIZATION_SIDEMENUS.ORGANIZATION,
    route: getRouteUrl(ROUTES.ORGANIZATIONS.ORGANIZATION, {
      id: organizationId
    }),
    title: organization?.name
      ? organization?.name
      : t('organizations.organizationName'),
    icon: 'home'
  },
  {
    key: ORGANIZATION_SIDEMENUS.ORGANIZATION_INFO,
    route: getRouteUrl(ROUTES.ORGANIZATIONS.ORGANIZATION_INFO, {
      id: organizationId
    }),
    title: t(ROUTES.ORGANIZATIONS.ORGANIZATION_INFO),
    icon: 'clipboard'
  },
  {
    key: ORGANIZATION_SIDEMENUS.ORGANIZATION_USERS,
    route: getRouteUrl(ROUTES.ORGANIZATIONS.USERS, { id: organizationId }),
    title: t(ROUTES.ORGANIZATIONS.USERS),
    icon: 'users'
  },
  {
    key: ORGANIZATION_SIDEMENUS.ORGANIZATION_LICENSES,
    route: getRouteUrl(ROUTES.ORGANIZATIONS.LICENSES, {
      id: organizationId
    }),
    title: t(ROUTES.ORGANIZATIONS.LICENSES),
    icon: 'licenses',
    sideMenus: licenses?.map((license) => {
      return {
        key: `licenses-${license?.uuid}`,
        route: getRouteUrl(ROUTES.ORGANIZATIONS.LICENSE, {
          id: organizationId,
          licenseId: license?.uuid
        }),
        title: license?.title,
        warningIcon: license?.hasErrors ? 'info' : null
      };
    })
  },
  {
    key: ORGANIZATION_SIDEMENUS.ORGANIZATION_INVOICES,
    route: getRouteUrl(ROUTES.ORGANIZATIONS.INVOICES, {
      id: organizationId
    }),
    title: t(ROUTES.ORGANIZATIONS.INVOICES),
    icon: 'creditcard'
  },
  {
    key: ORGANIZATION_SIDEMENUS.ORGANIZATION_CONTACT_PERSONS,
    route: getRouteUrl(ROUTES.ORGANIZATIONS.CONTACT_PERSONS, {
      id: organizationId
    }),
    title: t(ROUTES.ORGANIZATIONS.CONTACT_PERSONS),
    icon: 'user-check'
  },
  {
    key: ORGANIZATION_SIDEMENUS.ORGANIZATION_DATABASE_CONNECTION,
    route: getRouteUrl(ROUTES.ORGANIZATIONS.DATABASE_SETTINGS_MT, {
      id: organizationId
    }),
    title: t(ROUTES.ORGANIZATIONS.DATABASE_SETTINGS_MT),
    icon: 'database'
  },
  {
    key: ORGANIZATION_SIDEMENUS.ORGANIZATION_SECURITY_SETTINGS,
    route: getRouteUrl(ROUTES.ORGANIZATIONS.SECURITY_SETTINGS, {
      id: organizationId
    }),
    title: t(ROUTES.ORGANIZATIONS.SECURITY_SETTINGS),
    icon: 'shield'
  },

  {
    key: ORGANIZATION_SIDEMENUS.ORGANIZATION_API_KEYS,
    route: getRouteUrl(ROUTES.ORGANIZATIONS.API_KEYS, {
      id: organizationId
    }),
    title: t(ROUTES.ORGANIZATIONS.API_KEYS),
    icon: 'key'
  },
  {
    key: ORGANIZATION_SIDEMENUS.ORGANIZATION_ACTIVITY_LOG,
    route: getRouteUrl(ROUTES.ORGANIZATIONS.ACTIVITY_LOGS, {
      id: organizationId
    }),
    title: t(ROUTES.ORGANIZATIONS.ACTIVITY_LOGS),
    icon: 'activity'
  }
];

export const generateModelTreeSidemenu = (t: TFunction): MenuItem[] => [
  {
    key: ROUTES.MODEL_TREE.BUILDS,
    route: getRouteUrl(ROUTES.MODEL_TREE.BUILDS),
    title: t(ROUTES.MODEL_TREE.BUILDS),
    icon: 'build'
  },
  {
    key: ROUTES.MODEL_TREE.RELEASES,
    route: getRouteUrl(ROUTES.MODEL_TREE.RELEASES),
    title: t(ROUTES.MODEL_TREE.RELEASES),
    icon: 'release'
  }
];

export const getBreadcrumbWithDropdown = (
  t: TFunction,
  sidemenuContext: SidemenuContextData,
  route: string,
  routeParams?: Record<string, string>
) => {
  return {
    label:
      sidemenuContext?.currentOrganization?.name ||
      t('organizations.organizationName'),
    url: getRouteUrl(route, routeParams),
    countryIso3: sidemenuContext?.currentOrganization
      ?.countryIso3 as CountryIso3,
    dropdown: generateCompaniesList(sidemenuContext?.organizations, route)
  };
};
