import React, { Fragment } from 'react';
import {
  colors,
  common,
  Dropdown,
  formatDate,
  formatNumber,
  offsets,
  TableColumn,
  Tooltip,
  Typography
} from '@xq/ui-kit';
import {
  ManagerList,
  OrganizationDataItem
} from '@pages/Organizations/Organizations/dataTypes';
import { TFunction } from 'i18next';

export function createOrganizationsRows(
  organizations: OrganizationDataItem[],
  managers: ManagerList[],
  changeManager: (rowId: string, newManagerValue: string) => void
) {
  return organizations?.map((row) => ({
    id: row.uuid,
    data: {
      name: (
        <Fragment>
          <Typography
            element="div"
            variant="body-5"
            className={common['no-text-transform']}
          >
            {row.name}
          </Typography>
          {row.description && (
            <Typography
              element="div"
              variant="body-6"
              className={offsets['mt-2']}
            >
              {row.description}
            </Typography>
          )}
        </Fragment>
      ),
      countryIso3: row.countryIso3,
      createdAt: formatDate(row.createdAt),
      licenses: (
        <Fragment>
          {row.licenses?.map((license, index) =>
            license?.warning ? (
              <Tooltip key={index} type="bottom" content={license.warning}>
                <Typography
                  element="div"
                  className={index !== 0 && offsets['mt-8']}
                  variant="body-4"
                  color={colors.palettesRed80}
                >
                  {license.name}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                element="div"
                className={index !== 0 && offsets['mt-8']}
                key={index}
                variant="body-4"
              >
                {license.name}
              </Typography>
            )
          )}
        </Fragment>
      ),
      users: (
        <Typography variant="number-1">
          {formatNumber(row.usersAmount)}
        </Typography>
      ),
      lastBillingDate: (
        <Fragment>
          {row.licenses?.map((license, index) => (
            <Typography
              element="div"
              className={index !== 0 && offsets['mt-8']}
              key={index}
              variant="body-4"
            >
              {license.lastBillingDate
                ? formatDate(license.lastBillingDate)
                : ''}
            </Typography>
          ))}
        </Fragment>
      ),
      // todo show we will have mtDatabaseVersion
      // mtDatabaseVersion: (
      //   <Typography variant="number-1">
      //     {row.mtDatabaseVersion || ''}
      //   </Typography>
      // ),
      managerUuid: (
        <>
          {managers?.length > 0 && (
            <Dropdown
              onValueSelected={(value) => changeManager(row.uuid, value)}
              items={managers}
              selectedItemValue={row.managerUuid}
              variant="simple"
              position="bottom"
            />
          )}
        </>
      )
    }
  }));
}

export const createOrganizationsColumns = (t: TFunction): TableColumn[] => [
  {
    title: t('common.name'),
    key: 'name',
    width: 140,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('common.country'),
    key: 'countryIso3',
    width: 48,
    alignment: 'left',
    paddingLeft: 20,
    paddingRight: 20,
    sortable: true
  },
  {
    title: t('common.createOn'),
    key: 'createdAt',
    width: 95,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('common.licenses'),
    key: 'licenses',
    width: 160,
    alignment: 'left',
    paddingLeft: 20,
    sortable: false
  },
  {
    title: t('common.users'),
    key: 'users',
    width: 40,
    alignment: 'right',
    paddingLeft: 20,
    paddingRight: 20,
    sortable: false
  },
  {
    title: t('organizations.lastBillingDate'),
    key: 'lastBillingDate',
    width: 115,
    alignment: 'left',
    paddingLeft: 20,
    paddingRight: 20,
    sortable: false
  },
  // todo show we will have mtDatabaseVersion
  // {
  //   title: t('organizations.mtDatabaseVersion'),
  //   key: 'mtDatabaseVersion',
  //   width: 95,
  //   alignment: 'right',
  //   paddingLeft: 20,
  //   sortable: false
  // },
  {
    title: t('common.organizationManager'),
    key: 'managerUuid',
    width: 160,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  }
];
