import { TableColumn, formatDate } from '@xq/ui-kit';
import { TFunction } from 'i18next';
import { LicenseFeaturesItem } from './dataTypes';
import React from 'react';
import { DASH } from '@constants';

/** Methods for the table */
export const createLicenseFeaturesRows = (
  t: TFunction,
  licenseFeatures: LicenseFeaturesItem[]
) => {
  return licenseFeatures?.map((licenseFeature) => ({
    id: licenseFeature?.uuid,
    data: {
      name: licenseFeature?.name,
      description: licenseFeature?.description,
      createdAt: licenseFeature?.createdAt
        ? formatDate(licenseFeature?.createdAt)
        : DASH,
      licenseProduct: licenseFeature?.licenseProduct
    }
  }));
};

export const createLicenseFeaturesColumns = (t: TFunction): TableColumn[] => [
  {
    title: t('uiKit.featureName'),
    key: 'name',
    width: 180,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('common.description'),
    key: 'description',
    width: 300,
    alignment: 'left',
    paddingLeft: 20
  },
  {
    title: t('common.createOn'),
    key: 'createdAt',
    width: 100,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('uiKit.licenseProduct'),
    key: 'licenseProduct',
    width: 250,
    alignment: 'left',
    paddingLeft: 20
  }
];
