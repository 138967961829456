import { ListMetaInfo } from '../interfaces';
import { FormEvent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Locales } from '@xq/ui-kit';
import { config } from '@config';
import { logger } from '@services/logger';

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const checkNoMoreData = (
  /** number of items in response from backend */
  itemsLength: number,
  /** number of items in metadata from backend */
  totalItems: number,
  setNoMoreData: (value: boolean) => void
) => {
  const isNoMoreData = itemsLength === totalItems || itemsLength === 0;
  setNoMoreData(isNoMoreData);
};

export interface CheckEmptyStateProps {
  setIsEmptyState: (value: boolean) => void;
  meta: ListMetaInfo;
  /** below optional search params used on the page  */
  searchValue?: string;
  rangePickerValue?: [Date, Date];
  datePickerValue?: Date;
}

export const checkEmptyState = (props: CheckEmptyStateProps) => {
  const {
    setIsEmptyState,
    meta,
    searchValue,
    rangePickerValue,
    datePickerValue
  } = props;
  const isParamsSet = searchValue || datePickerValue || rangePickerValue;
  const totalItems = meta?.totalItems;
  const isEmptyState = !isParamsSet && (!totalItems || totalItems === 0);
  setIsEmptyState(isEmptyState);
};

export const submitForm = (e: FormEvent) => {
  e.preventDefault();
};

export default function ScrollToTop(): any {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  return null;
}

export async function downloadFile(url: string) {
  const link = document.createElement('a');
  link.href = url;
  link.download = 'User tracking report';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export const getCurrentLanguage = (): Locales => {
  //todo get from backend?
  let language = config.defaultLang;
  try {
    const savedLanguage = window.localStorage.getItem(config.localStorageLang);
    if (savedLanguage) {
      language = savedLanguage;
    }
  } catch (error) {
    logger.error('Local storage error:', error);
  }
  return language as Locales;
};

export const redirectToSSOLoginPage = () => {
  const ssoUrl = `${config.ssoFrontendLoginUrl}?redirectUrl=${window.location.href}`;
  window.location.replace(ssoUrl);
};
