import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { SecuritySettingsData } from './dataTypes';
import { SecuritySettingsPageApi } from '@xq/omni-gateway-frontend-client';

export interface SecuritySettingsService {
  fetchData(organizationUuid: string): Promise<SecuritySettingsData>;

  save(organizationUuid: string, settings: SecuritySettingsData): Promise<void>;
}

const omniSecuritySettingsGateway = new SecuritySettingsPageApi(
  omniApiConfiguration
);

export class SecuritySettingsServiceApi implements SecuritySettingsService {
  async fetchData(organizationUuid: string): Promise<SecuritySettingsData> {
    try {
      const response =
        await omniSecuritySettingsGateway.securitySettingsPageControllerGetSecuritySettings(
          {
            organizationUuid
          }
        );

      return {
        emailDomainAllowList: response?.allowedDomainList,
        loginIpWhiteList: response?.allowedIPList,
        forceEmailTwoFactorAuth: response?.forceEmailTwoFactorAuth,
        allowModelExportForModelTree: response?.allowModelExportForModelTree,
        azureIntegration: response?.azureIntegration,
        azureTenantID: response?.azureTenantID
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(
    organizationUuid: string,
    settings: SecuritySettingsData
  ): Promise<void> {
    try {
      await omniSecuritySettingsGateway.securitySettingsPageControllerSaveSecuritySettings(
        {
          organizationUuid: organizationUuid,
          organizationSecuritySettingsDTO: {
            allowedIPList: settings?.loginIpWhiteList,
            allowedDomainList: settings?.emailDomainAllowList,
            forceEmailTwoFactorAuth: settings?.forceEmailTwoFactorAuth,
            allowModelExportForModelTree:
              settings?.allowModelExportForModelTree,
            azureIntegration: settings?.azureIntegration,
            azureTenantID: settings?.azureTenantID
          }
        }
      );
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}

const securitySettingsMock: SecuritySettingsData = {
  emailDomainAllowList: ['2ns.com'],
  loginIpWhiteList: ['251.123.12.3', '129.209.29.180'],
  forceEmailTwoFactorAuth: false,
  allowModelExportForModelTree: true,
  azureTenantID: null,
  azureIntegration: false
};

export class SecuritySettingsServiceMock implements SecuritySettingsService {
  async fetchData(organizationUuid: string): Promise<SecuritySettingsData> {
    try {
      await wait(1000);
      return securitySettingsMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(
    organizationUuid: string,
    settings: SecuritySettingsData
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}
