import React, { Fragment } from 'react';
import {
  display,
  flex,
  formatDate,
  formatTime,
  Icon,
  offsets,
  TableColumn,
  Typography,
  UserTableBlock
} from '@xq/ui-kit';
import { TFunction } from 'i18next';
import { ActivityLogItem } from '@pages/Organizations/ActivityLog/dataTypes';
import cn from 'classnames';
import styles from './ActivityLog.module.scss';
import { v4 as uuidv4 } from 'uuid';
import { DASH } from '@constants';
import { userTableBlockTranslations } from '@services';

/** Methods for the table */
export const createActivityLogsRows = (
  t: TFunction,
  activityLogs: ActivityLogItem[]
) => {
  return activityLogs?.map((activityLog) => ({
    id: uuidv4(),
    data: {
      user: (
        <UserTableBlock
          user={{
            fullname: activityLog?.userShortInfo?.fullname,
            email: activityLog?.userShortInfo?.email,
            isExternal: activityLog?.userShortInfo?.isExternal,
            isOrganizationAdmin:
              activityLog?.userShortInfo?.isOrganizationAdmin,
            fromAzure: activityLog?.userShortInfo?.fromAzure,
            isDeleted: activityLog?.userShortInfo?.isDeleted
          }}
          showAvatar={false}
          isActive
          translations={userTableBlockTranslations(t)}
        />
      ),
      event: (
        <div className={cn(display['d-flex'], flex['align-items-center'])}>
          {activityLog?.action?.hasWarning && (
            <Icon className={styles['event-icon']} name={'alert'} size={'s'} />
          )}
          <Typography element="div" variant="body-4">
            {activityLog?.action?.name}
          </Typography>
        </div>
      ),
      timestamp: (
        <Fragment>
          <Typography element="div" variant="body-4">
            {activityLog?.date ? formatDate(activityLog?.date) : DASH}
          </Typography>
          {activityLog?.date && (
            <Typography element="div" variant="body-4">
              {formatTime(activityLog?.date)}
            </Typography>
          )}
        </Fragment>
      ),
      application: activityLog?.application,
      userInfo: (
        <div>
          <Typography
            variant={'body-4'}
            element={'div'}
            className={offsets['mb-8']}
          >
            {activityLog?.client?.name}
          </Typography>
          <Typography variant={'body-4'} element={'div'}>
            {activityLog?.client?.ip}
          </Typography>
        </div>
      ),
      additionalInfo: (
        <Typography
          variant={'body-4'}
          element={'div'}
          className={offsets['mb-8']}
        >
          {`${activityLog?.additionalInfo}`}
        </Typography>
      )
    }
  }));
};

export const createInvoiceDetailsColumns = (t: TFunction): TableColumn[] => [
  {
    title: t('common.user'),
    key: 'user',
    width: 180,
    alignment: 'left',
    paddingLeft: 20
  },
  {
    title: t('common.event'),
    key: 'event',
    width: 90,
    alignment: 'left',
    paddingLeft: 20
  },
  {
    title: t('common.dateAndTime'),
    key: 'timestamp',
    width: 90,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('uiKit.application'),
    key: 'application',
    width: 90,
    alignment: 'left',
    paddingLeft: 20
  },
  {
    title: t('organizations.userInfo'),
    key: 'userInfo',
    width: 100,
    alignment: 'left',
    paddingLeft: 20
  },
  {
    title: t('common.additionalInfo'),
    key: 'additionalInfo',
    width: 200,
    alignment: 'left',
    paddingLeft: 20
  }
];
