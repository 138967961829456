import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Col,
  Input,
  offsets,
  openStatusNotification,
  Row,
  Textarea,
  Typography,
  validate,
  ValidateTypes
} from '@xq/ui-kit';
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './EditContactPerson.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  getBreadcrumbWithDropdown,
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS,
  submitForm
} from '@services';
import { getRouteUrl, ROUTES } from '@router';
import { ContactPerson } from './dataTypes';
import {
  EditContactPersonService,
  EditContactPersonServiceApi
} from './edit-contact-person-service';
import { SidemenuContext, SidemenuContextData } from '@context';
import hash from 'object-hash';

export const EditContactPerson: FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);
  const service: EditContactPersonService = new EditContactPersonServiceApi();

  const [contactPerson, setContactPerson] = useState<ContactPerson>(null);
  const [initialContactPersonHash, setInitialContactPersonHash] =
    useState<string>(null);
  const [isContactPersonChanged, setIsContactPersonChanged] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isFieldsFilled = useMemo(() => {
    return !(contactPerson?.name?.trim() && contactPerson?.email?.trim());
  }, [contactPerson]);

  const [isEmailValidationError, setIsEmailValidationError] = useState(false);
  const [isPhoneValidationError, setIsPhoneValidationError] = useState(false);

  const onInputChange = (property: string, value: string | number) => {
    setContactPerson({ ...contactPerson, [property]: String(value) });
  };

  async function fetchData() {
    setIsLoading(true);
    try {
      const response: ContactPerson = await service.fetchData(
        params.id,
        params.contactPersonId
      );
      setContactPerson(response);
      setInitialContactPersonHash(hash(response));
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [params]);

  useEffect(() => {
    if (contactPerson) {
      const newHash = hash(contactPerson);
      setIsContactPersonChanged(newHash !== initialContactPersonHash);
    }
  }, [contactPerson, initialContactPersonHash]);

  async function save() {
    const isPhoneValid =
      !contactPerson?.phone ||
      (contactPerson?.phone &&
        validate(ValidateTypes.tel, contactPerson?.phone));
    setIsPhoneValidationError(!isPhoneValid);

    const isEmailValid =
      contactPerson?.email &&
      validate(ValidateTypes.email, contactPerson?.email);

    setIsEmailValidationError(!isEmailValid);

    if (!isPhoneValid || !isEmailValid) {
      return;
    }

    try {
      setIsLoading(true);
      await service.save(params.id, contactPerson);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
      setInitialContactPersonHash(hash(contactPerson));
      navigate(
        getRouteUrl(ROUTES.ORGANIZATIONS.CONTACT_PERSONS, { id: params.id })
      );
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  function cancel() {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.CONTACT_PERSONS, { id: params.id })
    );
  }

  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.CONTACT_PERSONS,
        { id: params?.id }
      ),
      {
        label: t('organizations.contactPersons'),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.CONTACT_PERSONS, {
          id: params.id
        })
      }
    ],
    [sidemenuContext, params]
  );

  useEffect(() => {
    sidemenuContext.setActiveMenu(
      ORGANIZATION_SIDEMENUS.ORGANIZATION_CONTACT_PERSONS
    );
  }, [sidemenuContext]);

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={10}>
          <Breadcrumbs
            NavLink={NavLink}
            className={'breadcrumbs'}
            items={breadcrumbs}
          />

          <Typography className={offsets['mb-40']} element="div" variant="h2">
            {t('organizations.editContactPerson')}
          </Typography>
        </Col>
      </Row>

      <Row cols={10}>
        <Col col={10}>
          <form className={styles.form} onSubmit={submitForm}>
            <Input
              required
              disabled={isLoading}
              value={contactPerson?.name}
              onChange={(value) => onInputChange('name', value)}
              className={offsets['mb-20']}
              label={t('common.name')}
            />

            <Input
              disabled={isLoading}
              value={contactPerson?.phone}
              onChange={(value) => onInputChange('phone', value)}
              className={offsets['mb-20']}
              label={t('common.phoneNumber')}
              errorMessage={
                isPhoneValidationError ? t('notifications.phoneIsNotValid') : ''
              }
            />

            <Input
              required
              disabled={isLoading}
              value={contactPerson?.email}
              onChange={(value) => onInputChange('email', value)}
              className={offsets['mb-20']}
              label={t('common.email')}
              errorMessage={
                isEmailValidationError ? t('notifications.emailIsNotValid') : ''
              }
            />

            <Input
              disabled={isLoading}
              value={contactPerson?.position}
              onChange={(value) => onInputChange('position', value)}
              className={offsets['mb-20']}
              label={t('uiKit.role')}
            />

            <Textarea
              disabled={isLoading}
              value={contactPerson?.comment}
              onChange={(value) => onInputChange('comment', value)}
              label={t('uiKit.comments')}
            />

            <div className={offsets['mt-40']}>
              <Button
                buttonType={'submit'}
                onClick={save}
                isLoading={isLoading}
                disabled={isFieldsFilled || !isContactPersonChanged}
                className={offsets['mr-20']}
              >
                {t('common.save')}
              </Button>
              <Button onClick={cancel} type="secondary">
                {t('common.cancel')}
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </Fragment>
  );
};

EditContactPerson.displayName = 'EditContactPerson';
