import React from 'react';
import {
  ActivityLog,
  ApiKeys,
  ApiKeysCreate,
  Builds,
  ContactPersons,
  CreateContactPerson,
  CreateEditUser,
  CreateLicenses,
  CreateOrganization,
  CreateRelease,
  DatabaseSettingsMT,
  EditContactPerson,
  EditLicense,
  EditOrganization,
  EditRelease,
  Forbidden,
  InternalServerError,
  InvoiceDetails,
  Invoices,
  License,
  LicenseFeatures,
  Licenses,
  MainInformation,
  Mimicry,
  NotFound,
  Organization,
  Organizations,
  Pricing,
  Releases,
  SecuritySettings,
  Users
} from '@pages';
import { Layout, SidemenuLayout } from '@layouts';
import { Outlet, RouteObject } from 'react-router-dom';
import { LayoutContainer, offsets } from '@xq/ui-kit';

export const SECTION_URL = {
  ORGANIZATIONS: 'organizations',
  MODEL_TREE: 'model-tree'
};

export const ROUTES = {
  HOME: 'routes.mainInformation',
  ORGANIZATIONS: {
    MAIN: 'routes.organizations.main',
    ORGANIZATION: 'routes.organizations.organization',
    ORGANIZATION_INFO: 'routes.organizations.organization-info',
    CREATE: 'routes.organizations.create',
    CREATE_USER: 'routes.organizations.create-user',
    EDIT_USER: 'routes.organizations.edit-user',
    USERS: 'routes.organizations.users',
    LICENSES: 'routes.organizations.licenses',
    CREATE_LICENSES: 'routes.organizations.createLicenses',
    EDIT_LICENSE: 'routes.organizations.editLicenses',
    LICENSE: 'routes.organizations.license',
    PRICING: 'routes.organizations.pricing',
    INVOICES: 'routes.organizations.invoices',
    INVOICE_DETAILS: 'routes.organizations.invoice-details',
    CONTACT_PERSONS: 'routes.organizations.contact-persons',
    CREATE_CONTACT_PERSON: 'routes.organizations.create-contact-person',
    EDIT_CONTACT_PERSON: 'routes.organizations.edit-contact-person',
    DATABASE_SETTINGS_MT: 'organizations.mtDatabaseSettings',
    SECURITY_SETTINGS: 'organizations.securitySettings',
    ACTIVITY_LOGS: 'routes.organizations.activityLogs',
    API_KEYS: 'routes.organizations.apiKeys',
    API_KEYS_CREATE: 'routes.organizations.apiKeysCreate'
  },
  LICENSES_FEATURES: {
    MAIN: 'routes.licensesFeatures'
  },
  MODEL_TREE: {
    MAIN: 'routes.modelTree.modelTree',
    BUILDS: 'routes.modelTree.builds',
    RELEASES: 'routes.modelTree.releases',
    CREATE_RELEASE: 'routes.modelTree.createRelease',
    EDIT_RELEASE: 'routes.modelTree.editRelease'
  },
  MIMICRY: {
    MAIN: 'routes.mimicry'
  },
  ERRORS: {
    FORBIDDEN: 'routes.errors.forbidden'
  }
};

export const getRouteUrl = (name: string, params?: Record<string, string>) => {
  switch (name) {
    case ROUTES.HOME: {
      return '/';
    }
    case ROUTES.ORGANIZATIONS.MAIN: {
      return `/${SECTION_URL.ORGANIZATIONS}`;
    }
    case ROUTES.ORGANIZATIONS.ORGANIZATION: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}`;
    }
    case ROUTES.ORGANIZATIONS.ORGANIZATION_INFO: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/info`;
    }
    case ROUTES.ORGANIZATIONS.CREATE: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/create`;
    }
    case ROUTES.ORGANIZATIONS.CREATE_USER: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/users/create`;
    }
    case ROUTES.ORGANIZATIONS.EDIT_USER: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/users/${params.userId}`;
    }
    case ROUTES.ORGANIZATIONS.USERS: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/users`;
    }
    case ROUTES.ORGANIZATIONS.LICENSES: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/licenses`;
    }
    case ROUTES.ORGANIZATIONS.CREATE_LICENSES: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/licenses/create`;
    }
    case ROUTES.ORGANIZATIONS.EDIT_LICENSE: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/licenses/${params.licenseId}/edit`;
    }
    case ROUTES.ORGANIZATIONS.LICENSE: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/licenses/${params.licenseId}`;
    }
    case ROUTES.ORGANIZATIONS.PRICING: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/licenses/${params.licenseId}/pricing`;
    }
    case ROUTES.ORGANIZATIONS.INVOICES: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/invoices`;
    }
    case ROUTES.ORGANIZATIONS.INVOICE_DETAILS: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/invoices/${params.invoiceId}/details`;
    }
    case ROUTES.ORGANIZATIONS.CONTACT_PERSONS: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/contact-persons`;
    }
    case ROUTES.ORGANIZATIONS.CREATE_CONTACT_PERSON: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/contact-persons/create`;
    }
    case ROUTES.ORGANIZATIONS.EDIT_CONTACT_PERSON: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/contact-persons/${params.contactPersonId}`;
    }
    case ROUTES.ORGANIZATIONS.DATABASE_SETTINGS_MT: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/mt-database-connection`;
    }
    case ROUTES.ORGANIZATIONS.SECURITY_SETTINGS: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/security-settings`;
    }
    case ROUTES.ORGANIZATIONS.ACTIVITY_LOGS: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/activity-logs`;
    }
    case ROUTES.ORGANIZATIONS.API_KEYS: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/api-keys`;
    }
    case ROUTES.ORGANIZATIONS.API_KEYS_CREATE: {
      return `/${SECTION_URL.ORGANIZATIONS}/${params.id}/api-keys/create`;
    }
    case ROUTES.LICENSES_FEATURES.MAIN: {
      return `/licenses-features`;
    }
    case ROUTES.MODEL_TREE.MAIN: {
      return `/${SECTION_URL.MODEL_TREE}`;
    }
    case ROUTES.MODEL_TREE.BUILDS: {
      return `/${SECTION_URL.MODEL_TREE}`;
    }
    case ROUTES.MODEL_TREE.RELEASES: {
      return `/${SECTION_URL.MODEL_TREE}/releases`;
    }
    case ROUTES.MODEL_TREE.CREATE_RELEASE: {
      return `/${SECTION_URL.MODEL_TREE}/releases/create`;
    }
    case ROUTES.MODEL_TREE.EDIT_RELEASE: {
      return `/${SECTION_URL.MODEL_TREE}/releases/${params.buildId}`;
    }
    case ROUTES.MIMICRY.MAIN: {
      return `/mimicry`;
    }
    case ROUTES.ERRORS.FORBIDDEN: {
      return '/forbidden';
    }
    default: {
      return '/';
    }
  }
};

export const routes: RouteObject[] = [
  {
    id: ROUTES.HOME,
    path: '/',
    element: <Layout />,
    children: [
      {
        id: ROUTES.HOME,
        path: '/',
        element: (
          <LayoutContainer className={offsets['mt-60']}>
            <Outlet />
          </LayoutContainer>
        ),
        children: [
          {
            id: ROUTES.HOME,
            index: true,
            path: '',
            element: <MainInformation />
          },
          {
            id: ROUTES.ORGANIZATIONS.MAIN,
            path: `/${SECTION_URL.ORGANIZATIONS}`,
            children: [
              {
                id: ROUTES.ORGANIZATIONS.MAIN,
                index: true,
                path: `/${SECTION_URL.ORGANIZATIONS}`,
                element: <Organizations />
              },
              {
                id: ROUTES.ORGANIZATIONS.CREATE,
                path: 'create',
                element: <CreateOrganization />
              }
            ]
          },
          {
            id: ROUTES.LICENSES_FEATURES.MAIN,
            path: '/licenses-features',
            element: <LicenseFeatures />
          },
          {
            id: ROUTES.MIMICRY.MAIN,
            path: '/mimicry',
            element: <Mimicry />
          }
        ]
      },
      {
        id: ROUTES.HOME,
        path: '/',
        element: <SidemenuLayout />,
        children: [
          {
            id: ROUTES.ORGANIZATIONS.MAIN,
            path: `/${SECTION_URL.ORGANIZATIONS}`,
            children: [
              {
                id: ROUTES.ORGANIZATIONS.ORGANIZATION,
                path: ':id',
                children: [
                  {
                    id: ROUTES.ORGANIZATIONS.ORGANIZATION,
                    path: '',
                    index: true,
                    element: <Organization />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.ORGANIZATION_INFO,
                    path: 'info',
                    element: <EditOrganization />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.USERS,
                    path: 'users',
                    element: <Users />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.CREATE_USER,
                    path: 'users/create',
                    element: <CreateEditUser />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.EDIT_USER,
                    path: 'users/:userId',
                    element: <CreateEditUser />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.LICENSES,
                    path: 'licenses',
                    element: <Licenses />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.CREATE_LICENSES,
                    path: 'licenses/create',
                    element: <CreateLicenses />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.EDIT_LICENSE,
                    path: 'licenses/:licenseId/edit',
                    element: <EditLicense />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.LICENSE,
                    path: 'licenses/:licenseId',
                    element: <License />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.PRICING,
                    path: 'licenses/:licenseId/pricing',
                    element: <Pricing />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.INVOICES,
                    path: 'invoices',
                    element: <Invoices />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.INVOICE_DETAILS,
                    path: 'invoices/:invoiceId/details',
                    element: <InvoiceDetails />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.CONTACT_PERSONS,
                    path: 'contact-persons',
                    element: <ContactPersons />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.CREATE_CONTACT_PERSON,
                    path: 'contact-persons/create',
                    element: <CreateContactPerson />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.EDIT_CONTACT_PERSON,
                    path: 'contact-persons/:contactPersonId',
                    element: <EditContactPerson />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.DATABASE_SETTINGS_MT,
                    path: 'mt-database-connection',
                    element: <DatabaseSettingsMT />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.SECURITY_SETTINGS,
                    path: 'security-settings',
                    element: <SecuritySettings />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.ACTIVITY_LOGS,
                    path: 'activity-logs',
                    element: <ActivityLog />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.API_KEYS,
                    path: 'api-keys',
                    element: <ApiKeys />
                  },
                  {
                    id: ROUTES.ORGANIZATIONS.API_KEYS_CREATE,
                    path: 'api-keys/create',
                    element: <ApiKeysCreate />
                  }
                ]
              }
            ]
          },
          {
            id: ROUTES.MODEL_TREE.MAIN,
            path: `/${SECTION_URL.MODEL_TREE}`,
            children: [
              {
                id: ROUTES.MODEL_TREE.BUILDS,
                path: '',
                index: true,
                element: <Builds />
              },
              {
                id: ROUTES.MODEL_TREE.RELEASES,
                path: 'releases',
                element: <Releases />
              },
              {
                id: ROUTES.MODEL_TREE.CREATE_RELEASE,
                path: 'releases/create',
                element: <CreateRelease />
              },
              {
                id: ROUTES.MODEL_TREE.EDIT_RELEASE,
                path: 'releases/:buildId',
                element: <EditRelease />
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: 'notFound',
    path: '*',
    element: <NotFound />
  },
  {
    id: 'internalError',
    path: 'internal-error',
    element: <InternalServerError />
  },
  {
    id: ROUTES.ERRORS.FORBIDDEN,
    path: 'forbidden',
    element: <Forbidden />
  }
];
