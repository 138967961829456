import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { ReleasesItem, ReleasesList } from './dataTypes';
import { ListParams } from 'interfaces';
import {
  ModelTreeReleaseInfoListDTOResponse,
  ModelTreeReleaseInfoListSortOptionsDTOFieldNameEnum,
  ModelTreeReleaseInfoListSortOptionsDTOOrderEnum,
  ModelTreeReleasesListPageApi
} from '@xq/omni-gateway-frontend-client';

export interface ReleasesService {
  fetchReleases(params?: ListParams): Promise<ReleasesList>;

  deleteRelease(buildId: number): Promise<void>;

  publishRelease(buildId: number): Promise<void>;

  downloadRelease(buildId: number): Promise<Blob>;
}

const omniReleasesGateway = new ModelTreeReleasesListPageApi(
  omniApiConfiguration
);

export class ReleasesService implements ReleasesService {
  async fetchReleases(params?: ListParams): Promise<ReleasesList> {
    try {
      const response: ModelTreeReleaseInfoListDTOResponse =
        await omniReleasesGateway.modelTreeReleasesListPageControllerGetList({
          modelTreeReleaseInfoListOptionsDTORequest: {
            pagination: {
              page: params?.page,
              limit: params?.limit
            },
            filters: [],
            sort: [
              {
                order:
                  params?.sortOrder as ModelTreeReleaseInfoListSortOptionsDTOOrderEnum,
                fieldName:
                  params?.sortBy as ModelTreeReleaseInfoListSortOptionsDTOFieldNameEnum
              }
            ]
          },
          searchString: params?.searchValue
        });
      return {
        meta: response?.meta,
        items: response?.items?.map((release) => {
          return {
            uuid: String(release.buildId),
            buildId: release?.buildId,
            releaseVersion: release?.version,
            description: release?.description,
            createOn: release?.createdAt,
            publishDate: release?.publishDate,
            status: {
              isPrivate: release?.isPrivate,
              organizations: release?.organizations
                ? release?.organizations?.map((el) => el?.label)
                : []
            },
            fileVariantTags: release?.fileVariantTags
          };
        })
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchReleases.name
      );
    }
  }

  async deleteRelease(buildId: number): Promise<void> {
    try {
      await omniReleasesGateway.modelTreeReleasesListPageControllerDeleteRelease(
        {
          modelTreeBuildId: buildId
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteRelease.name
      );
    }
  }

  async publishRelease(buildId: number): Promise<void> {
    try {
      await omniReleasesGateway.modelTreeReleasesListPageControllerPublishRelease(
        {
          modelTreeBuildId: buildId
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteRelease.name
      );
    }
  }

  async downloadRelease(buildId: number): Promise<Blob> {
    try {
      return await omniReleasesGateway.modelTreeReleasesListPageControllerGetReleaseFile(
        {
          modelTreeBuildId: buildId
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.downloadRelease.name
      );
    }
  }
}

const releasesMock: ReleasesItem[] = [
  {
    uuid: '1',
    buildId: 28037,
    createOn: new Date(2021, 5, 22),
    releaseVersion: 'Version 8.2021',
    publishDate: new Date(2021, 5, 24),
    description:
      'KPI Dynamics Grid provides details how key asset indicators have been changing',
    status: {
      isPrivate: true,
      organizations: ['Ilmarinen']
    },
    fileVariantTags: []
  },
  {
    uuid: '2',
    buildId: 28036,
    createOn: new Date(2021, 3, 12),
    releaseVersion: 'Version 7.2021',
    description: 'Notes are also useful for valuers',
    status: {
      isPrivate: false,
      organizations: ['Adelaer', 'Tapiola']
    },
    fileVariantTags: []
  },
  {
    uuid: '3',
    buildId: 28035,
    createOn: new Date(2021, 2, 24),
    releaseVersion: 'Version 6.2021',
    publishDate: new Date(2021, 3, 14),
    description: 'Calculate Market Value per sqm for each valuation method',
    status: {
      isPrivate: false
    },
    fileVariantTags: []
  }
];

const releasesListMock: ReleasesList = {
  items: releasesMock,
  meta: {
    totalItems: 50,
    totalPages: 5,
    itemsPerPage: 10,
    currentPage: 1,
    itemCount: 50
  }
};

export class ReleasesServiceMock implements ReleasesService {
  async fetchReleases(params?: ListParams): Promise<ReleasesList> {
    try {
      await wait(1000);
      return releasesListMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchReleases.name
      );
    }
  }

  async deleteRelease(buildId: number): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteRelease.name
      );
    }
  }

  async publishRelease(buildId: number): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteRelease.name
      );
    }
  }

  async downloadRelease(buildId: number): Promise<Blob> {
    try {
      const blobMock = new Blob(['testing'], { type: 'application/pdf' });
      await wait(1000);
      return blobMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.downloadRelease.name
      );
    }
  }
}
