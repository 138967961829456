import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './LicenseFeatures.module.scss';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  Col,
  Row,
  Table,
  Typography,
  changeSortDirection,
  Search,
  offsets,
  SortDirection,
  openStatusNotification,
  EmptyState
} from '@xq/ui-kit';
import { LicenseFeaturesItem } from './dataTypes';
import { LicenseFeaturesService } from './license-features-service';
import { useDebounce } from '@hooks';
import { config } from '@config';
import { SORT_DIRECTIONS } from '@constants';
import { checkEmptyState, getStatusNotificationTranslations } from '@services';
import { ListMetaInfo, ListParams } from 'interfaces';
import {
  createLicenseFeaturesColumns,
  createLicenseFeaturesRows
} from './utils';

export const LicenseFeatures: FC = () => {
  const service: LicenseFeaturesService = new LicenseFeaturesService();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [licenseFeatures, setLicenseFeatures] = useState<LicenseFeaturesItem[]>(
    []
  );
  const [licenseFeaturesMeta, setLicenseFeaturesMeta] =
    useState<ListMetaInfo>(null);

  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('search') || ''
  );
  const debouncedSearchValue = useDebounce(searchValue, config.debounceDelay);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<SortDirection>(
    SORT_DIRECTIONS.ASC as SortDirection
  );
  const [sortBy, setSortBy] = useState<string>('name');
  const [noMoreData, setNoMoreData] = useState<boolean>(false);
  const [isEmptyState, setIsEmptyState] = useState<boolean>(false);

  async function fetchLicenseFeatures(params?: ListParams) {
    setIsLoading(true);
    try {
      const res = await service.fetchLicenseFeatures(params);
      const hasResults = !res.items || res?.items?.length === 0;
      setNoResults(hasResults);
      setLicenseFeatures(res?.items);
      setLicenseFeaturesMeta(res?.meta);
      checkNoMoreData(res?.items?.length, res?.meta?.totalItems);
      checkEmptyState({ setIsEmptyState, meta: res?.meta, searchValue });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  function updateSortParams(key: string) {
    let updatedSortBy = sortBy;
    let updatedSortOrder = sortOrder;

    if (key === sortBy) {
      updatedSortOrder = changeSortDirection(sortOrder);
      setSortOrder(updatedSortOrder);
    } else {
      updatedSortBy = key;
      setSortBy(updatedSortBy);
    }
  }

  const rows = useMemo(() => {
    return createLicenseFeaturesRows(t, licenseFeatures);
  }, [licenseFeatures]);

  const columns = useMemo(() => {
    return createLicenseFeaturesColumns(t);
  }, []);

  const checkNoMoreData = (length: number, totalItems: number) => {
    const isNoMoreData = length === totalItems || length === 0;
    setNoMoreData(isNoMoreData);
  };

  async function loadMoreLicenseFeatures() {
    if (noMoreData) {
      return;
    }

    try {
      const response = await service.fetchLicenseFeatures({
        page: ++licenseFeaturesMeta.currentPage,
        searchValue: debouncedSearchValue,
        sortOrder: sortOrder,
        sortBy: sortBy
      });
      const { items } = response;
      checkNoMoreData(items?.length, response?.meta?.totalItems);
      setLicenseFeatures([...licenseFeatures, ...items]);
      setLicenseFeaturesMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  useEffect(() => {
    if (debouncedSearchValue) {
      setSearchParams({ search: searchValue });
    } else {
      setSearchParams({});
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    fetchLicenseFeatures({
      searchValue: debouncedSearchValue,
      sortOrder: sortOrder,
      sortBy: sortBy
    });
  }, [sortOrder, sortBy, debouncedSearchValue]);

  return (
    <div className={styles.wrap}>
      <Row cols={10}>
        <Col col={10} className={styles.heading}>
          <Typography element="div" variant="h2">
            {t('routes.licensesFeatures')}
          </Typography>
        </Col>
      </Row>

      <Row cols={10} style={{ marginRight: 0 }}>
        {!isLoading && isEmptyState && (
          <EmptyState
            heading={t('uiKit.oopsItIsEmpty')}
            description={t('alerts.looksLikeNoOneCame')}
            isCentered={true}
          />
        )}
      </Row>

      {!isEmptyState && (
        <Row cols={10}>
          <Col col={6} lg={3} className={offsets['mb-20']}>
            <Search
              placeholder={t('common.searchByFeatureName')}
              value={searchValue}
              onChange={setSearchValue}
            />
          </Col>

          <Col col={10}>
            <Table
              rows={rows}
              columns={columns}
              maxHeight="640px"
              minWidth={800}
              noMoreData={noMoreData}
              noMoreItemsText={t('common.noMoreItems')}
              onLoading={loadMoreLicenseFeatures}
              onSort={updateSortParams}
              sortOrder={sortOrder}
              sortBy={sortBy}
              noResults={noResults}
              noResultsText={t('common.noResults')}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

LicenseFeatures.displayName = 'LicenseFeatures';
