export const methodsAndInvoicingOptions = [
  {
    value: 'MONTHLY_BEFORE',
    label: 'Monthly / Prepayment'
  },
  {
    value: 'MONTHLY_AFTER',
    label: 'Monthly / Postpayment'
  },
  {
    value: 'QUARTERLY_BEFORE',
    label: 'Quarterly / Prepayment'
  },
  {
    value: 'QUARTERLY_MIDDLE',
    label: 'Quarterly / Middlepayment'
  },
  {
    value: 'QUARTERLY_AFTER',
    label: 'Quarterly / Postpayment'
  },
  {
    value: 'ANNUALLY_BEFORE',
    label: 'Annually / Prepayment'
  }
];
