import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { SelectItem } from '@xq/ui-kit';
import { Organization } from './dataTypes';
import {
  FileRestrictionsDTOResponse,
  OrganizationCreateDataDTOResponse,
  OrganizationCreateDTOResponseCreate,
  OrganizationCreatePageApi,
  OrganizationCreatePageApiOrganizationCreatePageControllerPostOrganizationCreateRequest
} from '@xq/omni-gateway-frontend-client';

interface CreateOrganizationData {
  countries: SelectItem[];
  managers: SelectItem[];
  currencies: SelectItem[];
  filesRestrictions: FileRestrictionsDTOResponse;
}

export interface CreateOrganizationService {
  fetchData(): Promise<CreateOrganizationData>;

  create(organization: Organization): Promise<string>;
}

const omniOrganizationCreateGateway = new OrganizationCreatePageApi(
  omniApiConfiguration
);

export class CreateOrganizationServiceApi implements CreateOrganizationService {
  async fetchData(): Promise<CreateOrganizationData> {
    try {
      const result: OrganizationCreateDataDTOResponse =
        await omniOrganizationCreateGateway.organizationCreatePageControllerGetOrganizationCreateData();
      return {
        countries: result?.countries,
        managers: result?.managers,
        currencies: result?.currencies,
        filesRestrictions: result?.agreementFilesRestrictions
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async create(organization: Organization): Promise<string> {
    const params: OrganizationCreatePageApiOrganizationCreatePageControllerPostOrganizationCreateRequest =
      {
        name: organization.name,
        countryIso3: organization.countryIso3,
        currencyIso3: organization.currencyIso3,
        description: organization.description,
        managerUuid: organization.managerUuid,
        agreementId: organization.agreementNumber
      };
    if (organization.files) {
      params.agreementFiles = organization.files;
    }

    if (organization.agreementDate) {
      params.agreementDate = new Date(organization.agreementDate);
    }

    try {
      const result: OrganizationCreateDTOResponseCreate =
        await omniOrganizationCreateGateway.organizationCreatePageControllerPostOrganizationCreate(
          params
        );
      return result.uuid;
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.create.name);
    }
  }
}

const countriesMock: SelectItem[] = [
  { value: '1', label: 'US' },
  { value: '2', label: 'Finland' }
];

const currenciesMock: SelectItem[] = [
  {
    value: '1',
    label: 'USD'
  },
  { value: '2', label: 'EUR' }
];

const agreementFilesRestrictionsMock: FileRestrictionsDTOResponse = {
  maxByteSize: 2000,
  allowedMimeTypes: 'application/pdf,application/msword'
};

const managersMock: SelectItem[] = [
  { value: '1', label: 'Nikita Pakhomov' },
  { value: '2', label: 'Markus' }
];

export class CreateOrganizationServiceMock
  implements CreateOrganizationService
{
  async fetchData(): Promise<CreateOrganizationData> {
    try {
      await wait(1000);
      return {
        countries: countriesMock,
        managers: managersMock,
        currencies: currenciesMock,
        filesRestrictions: agreementFilesRestrictionsMock
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async create(organization: Organization): Promise<string> {
    try {
      await wait(1000);

      return '00000000-0000-0000-0000-000000000000';
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.create.name);
    }
  }
}
