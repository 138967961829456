import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import {
  Alert,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  changeSortDirection,
  Col,
  EmptyState,
  offsets,
  openStatusNotification,
  Row,
  Search,
  SortDirection,
  Table,
  TableAction,
  TableRow,
  Typography
} from '@xq/ui-kit';
import { ApiKeysService, ApiKeysServiceApi } from './api-keys-service';
import { useDebounce } from '@hooks';
import { config } from '@config';
import {
  checkEmptyState,
  checkNoMoreData,
  getBreadcrumbWithDropdown,
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS
} from '@services';
import { SidemenuContext, SidemenuContextData } from '@context';
import { getRouteUrl, ROUTES } from '@router';
import { ListMetaInfo, ListParams } from 'interfaces';
import { SORT_DIRECTIONS } from '@constants';
import {
  createApiKeyColumns,
  createApiKeyRows
} from '@pages/Organizations/ApiKeys/utils';
import {
  ApiKeyInfo,
  ApiKeyListDTOResponse
} from '@xq/omni-gateway-frontend-client';

export const ApiKeys: FC = () => {
  const service: ApiKeysService = new ApiKeysServiceApi();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [apiKeysMeta, setApiKeysMeta] = useState<ListMetaInfo>(null);
  const [apiKeys, setApiKeys] = useState<ApiKeyInfo[]>([]);
  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('search') || ''
  );
  const debouncedSearchValue = useDebounce(searchValue, config.debounceDelay);

  const [noResults, setNoResults] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<SortDirection>(
    SORT_DIRECTIONS.ASC as SortDirection
  );
  const [sortBy, setSortBy] = useState<string>('name');
  const [noMoreData, setNoMoreData] = useState<boolean>(false);
  const [isEmptyState, setIsEmptyState] = useState<boolean>(false);

  /** values to alert */
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [submitText, setSubmitText] = useState<string>('');
  const [alertText, setAlertText] = useState<string>('');
  const [alertSubmitFunction, setAlertSubmitFunction] = useState<() => void>();
  const [isApiKeysLoading, setIsApiKeysLoading] = useState<boolean>(false);

  const deleteApiKeySubmit = (user: TableRow) => {
    const apiKeyInfo = apiKeys.find((el) => el.uuid === user.id);
    setAlertText(
      `${t('alerts.areYouSureYouWantToDelete')} ${t(
        'alerts.theAPIKey'
      )} <b>${apiKeyInfo?.name}</b>?<br/><br/>${t(
        'alerts.thisActionCannotBeUndoneAllAppsThatUseThisSecretForApiAccessWillLoseTheirAccess'
      )}`
    );
    setSubmitText(t('common.delete'));
    setAlertSubmitFunction(() => () => deleteApiKey(user));
    setIsAlertOpen(true);
  };

  async function deleteApiKey(apiKey: TableRow) {
    try {
      await service.deleteApiKey(params.id, apiKey.id);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        message: t('notifications.successfullyDeleted'),
        status: 200
      });
      const newApiKeys = apiKeys.filter((el) => apiKey.id !== el.uuid);
      setApiKeys(newApiKeys);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const rows = useMemo(() => {
    return createApiKeyRows(t, apiKeys);
  }, [apiKeys]);

  const columns = createApiKeyColumns(t);

  const actions: TableAction[] = [
    {
      key: 'delete',
      title: t('common.delete'),
      icon: 'trash',
      onClick: deleteApiKeySubmit
    }
  ];

  const getQueryParams = (page?: number): ListParams => {
    return {
      searchValue: String(debouncedSearchValue),
      sortOrder: sortOrder,
      sortBy: sortBy,
      page: page || apiKeysMeta?.currentPage
    };
  };

  async function fetchApiKeys() {
    const queryParams = getQueryParams(1);

    setIsApiKeysLoading(true);
    try {
      const response: ApiKeyListDTOResponse = await service.fetchApiKeys(
        params?.id,
        queryParams
      );
      const hasResults = !response.items || response?.items?.length === 0;
      setNoResults(hasResults);
      setApiKeys(response?.items);
      checkNoMoreData(
        response?.items?.length,
        response?.meta?.totalItems,
        setNoMoreData
      );
      checkEmptyState({ setIsEmptyState, meta: response?.meta, searchValue });
      setApiKeysMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsApiKeysLoading(false);
    }
  }

  async function loadMoreApiKeys() {
    try {
      if (noMoreData || !apiKeysMeta) {
        return;
      }

      const queryParams = getQueryParams(++apiKeysMeta.currentPage);

      const response = await service.fetchApiKeys(params.id, queryParams);

      const { items } = response;
      checkNoMoreData(items?.length, response?.meta?.totalItems, setNoMoreData);
      setApiKeys([...apiKeys, ...items]);
      setApiKeysMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  function updateSortParams(key: string) {
    let updatedSortBy = sortBy;
    let updatedSortOrder = sortOrder;

    if (key === sortBy) {
      updatedSortOrder = changeSortDirection(sortOrder);
      setSortOrder(updatedSortOrder);
    } else {
      updatedSortBy = key;
      setSortBy(updatedSortBy);
    }
  }

  const createApiKey = () => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.API_KEYS_CREATE, {
        id: params.id
      })
    );
  };

  useEffect(() => {
    if (debouncedSearchValue) {
      setSearchParams({ search: searchValue });
    } else {
      setSearchParams({});
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    setIsEmptyState(false);
  }, [params.id]);

  useEffect(() => {
    fetchApiKeys();
  }, [params.id, sortOrder, sortBy, debouncedSearchValue]);

  useEffect(() => {
    sidemenuContext.setActiveMenu(ORGANIZATION_SIDEMENUS.ORGANIZATION_API_KEYS);
  }, []);

  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  /** Breadcrumbs */
  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN, { id: params?.id })
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.API_KEYS,
        { id: params?.id }
      )
    ],
    [sidemenuContext, params.id]
  );

  return (
    <Fragment>
      <Row cols={10}>
        <Breadcrumbs
          NavLink={NavLink}
          className={'breadcrumbs'}
          items={breadcrumbs}
        />
        <Col col={9} className={'heading'}>
          <Typography element="div" variant="h2">
            {t('routes.organizations.apiKeys')}
          </Typography>
          {!isEmptyState && (
            <Button type="third" icon="key" onClick={createApiKey}>
              {t('common.create')}
            </Button>
          )}
        </Col>
      </Row>

      <Row cols={10} style={{ marginRight: 0 }}>
        {!isApiKeysLoading && isEmptyState && (
          <EmptyState
            heading={t('uiKit.oopsItIsEmpty')}
            description={'Looks like you don’t have API keys'}
            onClick={createApiKey}
            buttonText={t('common.create')}
            buttonIcon={'key'}
            isCentered={true}
          />
        )}
      </Row>

      {!isEmptyState && (
        <Row cols={10}>
          <Col col={6} lg={3} className={offsets['mb-20']}>
            <Search
              placeholder={t('common.searchByName')}
              value={searchValue}
              onChange={setSearchValue}
            />
          </Col>

          <Col col={9}>
            <Table
              rows={rows}
              columns={columns}
              actions={actions}
              maxHeight="640px"
              minWidth={900}
              noMoreData={noMoreData}
              noMoreItemsText={t('common.noMoreItems')}
              onLoading={loadMoreApiKeys}
              onSort={updateSortParams}
              sortOrder={sortOrder}
              sortBy={sortBy}
              noResults={noResults}
              noResultsText={t('common.noResults')}
              isLoading={isApiKeysLoading}
            />
          </Col>
        </Row>
      )}

      <Alert
        isOpen={isAlertOpen}
        onSubmit={() => alertSubmitFunction()}
        onClose={() => setIsAlertOpen(false)}
        cancelText={t('common.cancel')}
        submitText={submitText}
      >
        <div dangerouslySetInnerHTML={{ __html: alertText }} />
      </Alert>
    </Fragment>
  );
};

ApiKeys.displayName = 'ApiKeys';
