import React from 'react';
import {
  Currency,
  formatCurrency,
  formatNumber,
  offsets,
  TableColumn,
  TableRow,
  Typography
} from '@xq/ui-kit';
import { TFunction } from 'i18next';
import { BillingInvoiceDetails } from '@pages';
import { DASH } from '@constants';

/** Methods for the table */
export const createInvoiceDetailsRows = (
  t: TFunction,
  licensePayments: BillingInvoiceDetails[],
  additionalPayments: BillingInvoiceDetails[],
  currency: Currency
): TableRow[] => {
  const rows: TableRow[] = [];
  const licensePaymentsTotal: number = licensePayments?.reduce(
    (sum, el) => sum + el.totalPayment,
    0
  );

  const additionalPaymentsTotal: number = additionalPayments?.reduce(
    (sum, el) => sum + el.totalPayment,
    0
  );

  rows.push(
    createSubtitleRow(
      'licensePaymentsRow',
      t('organizations.licensePayments'),
      licensePaymentsTotal,
      currency
    )
  );
  rows.push(...createInvoiceDetailsRow(t, licensePayments, currency));
  rows.push(
    createSubtitleRow(
      'additionalPaymentsRow',
      t('organizations.additionalPayments'),
      additionalPaymentsTotal,
      currency
    )
  );
  rows.push(...createInvoiceDetailsRow(t, additionalPayments, currency));
  rows.push(
    createSubtitleRow(
      'invoiceAmount',
      t('organizations.invoiceAmount'),
      additionalPaymentsTotal + licensePaymentsTotal,
      currency
    )
  );
  return rows;
};

export const createSubtitleRow = (
  id: string,
  title: string,
  total: number,
  currency: Currency
) => {
  return {
    id: id,
    data: {
      service: (
        <Typography element="div" className={offsets['pt-12']} variant="body-5">
          {title}
        </Typography>
      ),
      period: ' ',
      users: ' ',
      total: (
        <Typography
          element="div"
          className={offsets['pt-12']}
          variant="number-2"
        >
          {formatCurrency(currency, total, 2)}
        </Typography>
      ),
      alreadyPaid: ' ',
      comment: ' '
    }
  };
};

export const createInvoiceDetailsRow = (
  t: TFunction,
  invoiceDetails: BillingInvoiceDetails[],
  currency: Currency
): TableRow[] => {
  return invoiceDetails?.map((invoiceDetail) => ({
    id: invoiceDetail?.uuid,
    data: {
      service: (
        <Typography variant="body-4">{invoiceDetail?.service}</Typography>
      ),
      period: getPeriodName(invoiceDetail?.from, invoiceDetail?.to),
      users: (
        <Typography variant="number-1">
          {invoiceDetail?.amountOfUsers
            ? formatNumber(invoiceDetail?.amountOfUsers)
            : DASH}
        </Typography>
      ),
      total: (
        <Typography variant="number-1">
          {formatCurrency(currency, invoiceDetail?.totalPayment, 2)}
        </Typography>
      ),
      alreadyPaid: (
        <Typography variant="number-1">
          {formatCurrency(currency, invoiceDetail?.prepayment, 2)}
        </Typography>
      ),
      comment: (
        <Typography variant="body-4">{invoiceDetail?.comment}</Typography>
      )
    }
  }));
};

export const createInvoiceDetailsColumns = (t: TFunction): TableColumn[] => [
  {
    title: t('common.service'),
    key: 'service',
    width: 190,
    alignment: 'left',
    paddingLeft: 20
  },
  {
    title: t('common.period'),
    key: 'period',
    width: 120,
    alignment: 'left',
    paddingLeft: 20
  },
  {
    title: t('common.users'),
    key: 'users',
    width: 60,
    alignment: 'right',
    paddingRight: 20
  },
  {
    title: t('common.total'),
    key: 'total',
    width: 120,
    alignment: 'right',
    paddingRight: 20
  },
  {
    title: t('organizations.alreadyPaid'),
    key: 'alreadyPaid',
    width: 120,
    alignment: 'right',
    paddingRight: 20
  },
  {
    title: t('organizations.paymentComment'),
    key: 'comment',
    width: 120,
    alignment: 'left',
    paddingLeft: 20
  }
];

function getPeriodName(from: Date, to: Date) {
  if (!from || !to) {
    return DASH;
  }
  const startDate = new Date(from);
  const endDate = new Date(to);

  const formatter = new Intl.DateTimeFormat('ru', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  });
  const formattedStart = formatter.format(startDate);
  const formattedEnd = formatter.format(endDate);

  return `${formattedStart
    .slice(1)
    .split('.')
    .slice(0, 2)
    .join('.')}. ${DASH} ${formattedEnd}`;
}
