import {
  handleBackendError,
  MODEL_TREE_DESKTOP_UUID,
  omniApiConfiguration,
  wait
} from '@services';
import {
  LicenseEditInfoDTORequest,
  LicenseEditPageApi
} from '@xq/omni-gateway-frontend-client';
import { EditLicenseModel } from '@pages/Organizations/EditLicense/dataTypes';

export interface EditLicenseService {
  fetchData(
    organizationUuid: string,
    licenseUuid: string
  ): Promise<EditLicenseModel>;

  save(
    organizationUuid: string,
    licenseUuid: string,
    license: LicenseEditInfoDTORequest
  ): Promise<void>;
}

const omniLicenseEditGateway = new LicenseEditPageApi(omniApiConfiguration);

export class EditLicenseServiceApi implements EditLicenseService {
  async fetchData(
    organizationUuid: string,
    licenseUuid?: string
  ): Promise<EditLicenseModel> {
    try {
      const response =
        await omniLicenseEditGateway.licenseEditPageControllerGetPageData({
          licenseUuid
        });
      return response.licenseProduct;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(
    organizationUuid: string,
    licenseUuid: string,
    license: LicenseEditInfoDTORequest
  ): Promise<void> {
    try {
      await omniLicenseEditGateway.licenseEditPageControllerUpdateOrganizationLicense(
        {
          licenseUuid: licenseUuid,
          licenseEditInfoDTORequest: license
        }
      );
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}

const editLicenseMock: EditLicenseModel = {
  uuid: MODEL_TREE_DESKTOP_UUID,
  name: 'Model tree',
  description:
    'Ultimate all-in-one financial modelling and reporting platform for property investment management and valuation.',
  licenseTypes: [
    { label: 'Valuation', value: '1' },
    { label: 'Investment Management', value: '2' }
  ],
  licenseFeatures: [
    {
      uuid: '1',
      name: 'Power search',
      isActive: false
    },
    {
      uuid: '2',
      name: 'Reports and Dashboards',
      isActive: true
    },
    {
      uuid: '3',
      name: 'Services teams',
      isActive: false
    },
    {
      uuid: '4',
      name: 'Project templates',
      isActive: true
    }
  ],
  license: {
    uuid: '1',
    isTrial: true,
    expireAt: new Date(2023, 3, 2, 11, 41, 23),
    isSuspended: false,
    licenseType: {
      label: 'Valuation',
      value: '1'
    }
  }
};

export class CreateEditLicensesServiceMock implements EditLicenseService {
  async fetchData(
    organizationUuid: string,
    licenseUuid?: string
  ): Promise<EditLicenseModel> {
    try {
      await wait(1000);
      return editLicenseMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(
    organizationUuid: string,
    licenseUuid: string,
    license: LicenseEditInfoDTORequest
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}
