import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import styles from './Organizations.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  changeSortDirection,
  Col,
  Modal,
  offsets,
  openStatusNotification,
  Row,
  Search,
  Select,
  SelectItem,
  SortDirection,
  Table,
  TableAction,
  TableColumn,
  TableRow,
  Typography
} from '@xq/ui-kit';
import {
  ManagerList,
  OldOrganization,
  OrganizationDataItem
} from './dataTypes';
import {
  OrganizationsService,
  OrganizationsServiceApi
} from './organizations-service';
import { useDebounce } from '@hooks';
import { config } from '@config';
import { SORT_DIRECTIONS } from '@constants';
import { getStatusNotificationTranslations, logger } from '@services';
import { ListMetaInfo, ListParams } from 'interfaces';
import {
  createOrganizationsColumns,
  createOrganizationsRows
} from '@pages/Organizations/Organizations/utils';
import cn from 'classnames';

export const Organizations: FC = () => {
  const service: OrganizationsService = new OrganizationsServiceApi();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const columns: TableColumn[] = createOrganizationsColumns(t);

  const [organizations, setOrganizations] = useState<OrganizationDataItem[]>(
    []
  );
  const [organizationsMeta, setOrganizationsMeta] =
    useState<ListMetaInfo>(null);
  const [managers, setManagers] = useState<ManagerList[]>([]);
  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('search') || ''
  );
  const debouncedSearchValue = useDebounce(searchValue, config.debounceDelay);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noResults, setNoResults] = useState<boolean>(false);

  const [sortOrder, setSortOrder] = useState<SortDirection>(
    SORT_DIRECTIONS.ASC as SortDirection
  );
  const [sortBy, setSortBy] = useState<string>('name');
  const [noMoreData, setNoMoreData] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [organizationsFromOldLms, setOrganizationsFromOldLms] = useState<
    SelectItem[]
  >([]);
  const [organizationToMigrate, setOrganizationToMigrate] =
    useState<SelectItem>(null);

  const actions: TableAction[] = [
    {
      key: 'redirect',
      title: 'Redirect',
      icon: 'chevrons-right',
      onClick: redirectToOrganization
    }
  ];

  function redirectToOrganization(row: TableRow) {
    navigate(row.id);
  }

  async function changeManager(rowId: string, newManagerValue: string) {
    const targetIndex = organizations.findIndex((el) => el.uuid === rowId);
    const newData = [...organizations];
    newData[targetIndex].managerUuid = newManagerValue;

    setOrganizations(newData);

    try {
      await service.setOrganizationManager(
        newData[targetIndex].uuid,
        newManagerValue
      );
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const getQueryParams = (optionalParams?: ListParams): ListParams => {
    const params: ListParams = optionalParams || {};

    if (!params.searchValue && debouncedSearchValue) {
      params.searchValue = String(debouncedSearchValue);
    }

    if (!params.sortOrder && sortOrder) {
      params.sortOrder = sortOrder;
    }

    if (!params.sortBy && sortBy) {
      params.sortBy = mapColumnKey(sortBy);
    }

    return params;
  };

  async function fetchOldOrganizations() {
    try {
      const response = await fetch(
        'https://cdn.exquance.com/old-lms/org-list.json',
        {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'GET'
        }
      );

      const oldOrganizations: OldOrganization[] = await response.json();

      if (oldOrganizations) {
        const mappedOrganizations = oldOrganizations
          ?.map((el) => {
            return {
              value: el?.uuid,
              label: el?.organisationName
            };
          })
          ?.sort((a, b) => {
            if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
              return -1;
            }
            if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
              return 1;
            }
            return 0;
          });
        setOrganizationsFromOldLms(mappedOrganizations);
      }
    } catch (error) {
      logger.error('fetchOldOrganizations error:', error);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        message: 'Wrong file with old organizations for migration'
      });
    }
  }

  async function fetchOrganizations(optionalParams?: ListParams) {
    const params = getQueryParams(optionalParams);
    setIsLoading(true);
    try {
      const res = await service.fetchOrganizations(params);
      const hasResults = !res.items || res?.items?.length === 0;
      setNoResults(hasResults);
      setOrganizations(res?.items);
      setOrganizationsMeta(res?.meta);
      checkNoMoreData(res?.items?.length, res?.meta?.totalItems);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  function updateSortParams(key: string) {
    let updatedSortBy = sortBy;
    let updatedSortOrder = sortOrder;

    if (key === sortBy) {
      updatedSortOrder = changeSortDirection(sortOrder);
      setSortOrder(updatedSortOrder);
    } else {
      updatedSortBy = key;
      setSortBy(updatedSortBy);
    }
    return { updatedSortBy: updatedSortBy, updatedSortOrder: updatedSortOrder };
  }

  async function sort(key: string) {
    const { updatedSortBy, updatedSortOrder } = updateSortParams(key);

    const dataKey: string = mapColumnKey(updatedSortBy);
    fetchOrganizations({
      searchValue: String(debouncedSearchValue),
      sortOrder: updatedSortOrder,
      sortBy: dataKey
    });
  }

  async function fetchManagers() {
    try {
      const managers = await service.fetchManagers();
      setManagers(managers);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function migrateOrganization() {
    try {
      await service.migrateOrganization(organizationToMigrate?.value);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message:
          'The migration for the organization has begun! Please check messages in Slack'
      });
      fetchOrganizations({
        searchValue: String(debouncedSearchValue),
        sortOrder: sortOrder,
        sortBy: mapColumnKey(sortBy)
      });
      setOrganizationToMigrate(null);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const rows = useMemo(() => {
    return createOrganizationsRows(organizations, managers, changeManager);
  }, [organizations, managers]);

  const mapColumnKey = (key: string): string => {
    switch (key) {
      case 'users': {
        return 'usersAmount';
      }
      case 'name': {
        return 'name';
      }
      case 'lastBillingDate': {
        return 'licenses';
      }

      default: {
        return key;
      }
    }
  };

  const checkNoMoreData = (organizationLength: number, totalItems: number) => {
    const isNoMoreData =
      organizationLength === totalItems || organizationLength === 0;
    setNoMoreData(isNoMoreData);
  };

  async function loadMoreOrganizations() {
    try {
      if (noMoreData) {
        return;
      }

      const params: ListParams = getQueryParams();

      if (organizationsMeta?.currentPage) {
        params.page = ++organizationsMeta.currentPage;
      }

      const response = await service.fetchOrganizations(params);
      const { items } = response;
      checkNoMoreData(items?.length, response?.meta?.totalItems);
      setOrganizations([...organizations, ...items]);
      setOrganizationsMeta(response?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  useEffect(() => {
    fetchManagers();
    fetchOldOrganizations();
  }, []);

  useEffect(() => {
    if (debouncedSearchValue) {
      setSearchParams({ search: debouncedSearchValue });
    } else {
      setSearchParams({});
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    fetchOrganizations({
      searchValue: String(debouncedSearchValue),
      sortOrder: sortOrder,
      sortBy: mapColumnKey(sortBy)
    });
  }, [sortOrder, sortBy, debouncedSearchValue]);

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={10} className={styles.heading}>
          <Typography element="div" variant="h2">
            {t('organizations.organizations')}
          </Typography>
          <div>
            <Button
              type="fourth"
              icon="update"
              className={offsets['mr-40']}
              onClick={() => setIsModalOpen(true)}
            >
              Migrate
            </Button>
            <Button
              type="third"
              icon="briefcase"
              onClick={() => navigate('create')}
            >
              {t('common.create')}
            </Button>
          </div>
        </Col>

        <Col col={6} lg={3} className={offsets['mb-20']}>
          <Search
            placeholder={t('common.searchByName')}
            value={searchValue}
            onChange={setSearchValue}
          />
        </Col>

        <Col col={10}>
          <Table
            rows={rows}
            columns={columns}
            actions={actions}
            maxHeight="640px"
            minWidth={900}
            noMoreData={noMoreData}
            noMoreItemsText={t('common.noMoreItems')}
            onLoading={loadMoreOrganizations}
            onSort={sort}
            sortOrder={sortOrder}
            sortBy={sortBy}
            noResults={noResults}
            noResultsText={t('common.noResults')}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      {isModalOpen && (
        <Modal
          title={'Migrate organization'}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          cancelText={t('common.cancel')}
          submitText={'migrate'}
          isSubmitDisabled={!organizationToMigrate}
          onCancel={() => setIsModalOpen(false)}
          onSubmit={migrateOrganization}
          maxWidth="454px"
        >
          <Select
            required={true}
            className={cn(offsets['pb-40'], offsets['mt-40'])}
            selected={organizationToMigrate}
            onChange={setOrganizationToMigrate}
            items={organizationsFromOldLms}
            label={'Choose organization name to migrate'}
          />
          <Typography variant="body-2" element="div" className={styles.center}>
            Be cautious!
          </Typography>
          <Typography
            variant="body-1"
            element="div"
            className={cn(offsets['mb-40'], styles.center)}
          >
            This operation can&apos;t be easily undone! If you migrate the
            organization to a new system, users of this organization won&apos;t
            be able to use ModelTree versions lower than 2.8.4.
          </Typography>
        </Modal>
      )}
    </Fragment>
  );
};

Organizations.displayName = 'Organizations';
