import {
  handleBackendError,
  MODEL_TREE_DESKTOP_UUID,
  omniApiConfiguration,
  wait
} from '@services';
import {
  LicenseCreatePageApi,
  LicneseCreateInfoDTORequest
} from '@xq/omni-gateway-frontend-client';
import { CreateLicenseModel } from './dataTypes';

export interface CreateLicensesService {
  fetchData(
    organizationUuid: string,
    licenseUuid?: string
  ): Promise<CreateLicenseModel[]>;

  save(
    organizationUuid: string,
    licenses: LicneseCreateInfoDTORequest[]
  ): Promise<void>;
}

const omniLicenseCreateGateway = new LicenseCreatePageApi(omniApiConfiguration);

export class CreateLicensesServiceApi implements CreateLicensesService {
  async fetchData(organizationUuid: string): Promise<CreateLicenseModel[]> {
    try {
      const response =
        await omniLicenseCreateGateway.licenseCreatePageControllerGetPageData({
          organizationUuid
        });
      return response?.licenseProducts;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(
    organizationUuid: string,
    licenses: LicneseCreateInfoDTORequest[]
  ): Promise<void> {
    try {
      await omniLicenseCreateGateway.licenseCreatePageControllerCreateOrganizationLicenses(
        {
          organizationUuid,
          licenseCreateDTORequest: {
            licenses
          }
        }
      );
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}

const createLicenseMock: CreateLicenseModel[] = [
  {
    uuid: MODEL_TREE_DESKTOP_UUID,
    name: 'Model tree',
    description:
      'Ultimate all-in-one financial modelling and reporting platform for property investment management and valuation.',
    licenseTypes: [
      { label: 'Valuation', value: '1' },
      { label: 'Investment Management', value: '2' }
    ],
    licenseFeatures: [
      {
        uuid: '2',
        name: 'Reports and Dashboards',
        isActive: true
      },
      {
        uuid: '3',
        name: 'Services teams',
        isActive: true
      },
      {
        uuid: '4',
        name: 'Project templates',
        isActive: true
      }
    ]
  }
];

const editLicenseMock: CreateLicenseModel = {
  uuid: MODEL_TREE_DESKTOP_UUID,
  name: 'Model tree',
  description:
    'Ultimate all-in-one financial modelling and reporting platform for property investment management and valuation.',
  licenseTypes: [
    { label: 'Valuation', value: '1' },
    { label: 'Investment Management', value: '2' }
  ],
  licenseFeatures: [
    {
      uuid: '1',
      name: 'Power search',
      isActive: false
    },
    {
      uuid: '2',
      name: 'Reports and Dashboards',
      isActive: true
    },
    {
      uuid: '3',
      name: 'Services teams',
      isActive: false
    },
    {
      uuid: '4',
      name: 'Project templates',
      isActive: true
    }
  ],
  licenseProduct: {
    uuid: '1',
    isTrial: true,
    expiryDate: '2023-09-02T09:04:05.578Z',
    isSuspended: false,
    licenseType: {
      label: 'Valuation',
      value: '1'
    }
  }
};

export class CreateLicensesServiceMock implements CreateLicensesService {
  async fetchData(
    organizationUuid: string,
    licenseUuid?: string
  ): Promise<CreateLicenseModel[]> {
    try {
      await wait(1000);
      if (licenseUuid) {
        return [editLicenseMock];
      }
      return createLicenseMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(
    organizationUuid: string,
    licenses: LicneseCreateInfoDTORequest[]
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}
