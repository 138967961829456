import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { DatabaseSettings, MTDatabaseVersion } from './dataTypes';
import {
  DatabaseSettingsPageApi,
  OrganizationSettingsDTORequestSave
} from '@xq/omni-gateway-frontend-client';

export interface DatabaseSettingsMtService {
  fetchData(organizationUuid: string): Promise<DatabaseSettings>;

  fetchDbConnection(organizationUuid: string): Promise<MTDatabaseVersion>;

  save(
    organizationUuid: string,
    databaseSettings: DatabaseSettings
  ): Promise<void>;

  testConnection(
    organizationUuid: string,
    databaseSettings: DatabaseSettings
  ): Promise<MTDatabaseVersion>;
}

const databaseSettingsMtGateway = new DatabaseSettingsPageApi(
  omniApiConfiguration
);

export class DatabaseSettingsMtServiceApi implements DatabaseSettingsMtService {
  async fetchData(organizationUuid: string): Promise<DatabaseSettings> {
    try {
      const response =
        await databaseSettingsMtGateway.databaseSettingsPageControllerGetPageData(
          {
            organizationUuid
          }
        );

      return {
        serverAddress: response?.databaseSettings?.connection?.server || '',
        port: response?.databaseSettings?.connection?.port || undefined,
        database: response?.databaseSettings?.connection?.database || '',
        user: response?.databaseSettings?.connection?.user || '',
        password: response?.databaseSettings?.connection?.password || '',
        isLocal: false,
        isTrustServerCertificate:
          response?.databaseSettings?.connection?.trustCertificate || false,
        comment: response?.databaseSettings?.connection?.comment || ''
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async fetchDbConnection(
    organizationUuid: string
  ): Promise<MTDatabaseVersion> {
    try {
      const response =
        await databaseSettingsMtGateway.databaseSettingsPageControllerTestCurrentDBConnection(
          {
            organizationUuid: organizationUuid
          }
        );

      return {
        version: response?.currentAppVersion,
        error: response?.errorDetails,
        lastUpdateDate: response?.lastVersionUpdateDate,
        previousVersion: response?.previousAppVersion,
        online: response?.online
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchDbConnection.name
      );
    }
  }

  async save(
    organizationUuid: string,
    databaseSettings: DatabaseSettings
  ): Promise<void> {
    try {
      const settings: OrganizationSettingsDTORequestSave = {
        connection: {
          server: databaseSettings?.serverAddress,
          port: databaseSettings?.port,
          database: databaseSettings?.database,
          user: databaseSettings?.user,
          password: databaseSettings?.password,
          trustCertificate: databaseSettings?.isTrustServerCertificate,
          comment: databaseSettings?.comment
        }
      };

      await databaseSettingsMtGateway.databaseSettingsPageControllerSaveSettings(
        {
          organizationUuid: organizationUuid,
          organizationSettingsDTORequestSave: settings
        }
      );
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }

  async testConnection(
    organizationUuid: string,
    databaseSettings: DatabaseSettings
  ): Promise<MTDatabaseVersion> {
    try {
      const response =
        await databaseSettingsMtGateway.databaseSettingsPageControllerTestRawDBConnection(
          {
            organizationUuid: organizationUuid,
            connectionDTORequestCreate: {
              server: databaseSettings?.serverAddress,
              port: databaseSettings?.port,
              database: databaseSettings?.database,
              user: databaseSettings?.user,
              password: databaseSettings?.password,
              trustCertificate: databaseSettings?.isTrustServerCertificate,
              comment: databaseSettings?.comment
            }
          }
        );

      return {
        version: response?.currentAppVersion,
        error: response?.errorDetails,
        lastUpdateDate: response?.lastVersionUpdateDate,
        previousVersion: response?.previousAppVersion,
        online: response?.online
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.testConnection.name
      );
    }
  }
}

const fetchDataMock: DatabaseSettings = {
  isLocal: false,
  serverAddress: 'xq-dev-mssql.exquance.com',
  port: 144863,
  database: 'xqdb_demo',
  user: 'horatio.morrison@2ns.com',
  password: 'sa298GHv2349',
  isTrustServerCertificate: true
};

const fetchDbConnectionMock: MTDatabaseVersion = {
  version: '3.32.1',
  lastUpdateDate: new Date(2020, 4, 13),
  previousVersion: '2.0.1'
};

const testConnectionMock: MTDatabaseVersion = {
  version: '3.32.1',
  error:
    'The “error establishing a database connection” is probably one of the most common errors WordPress users can encounter. It’s closely tied to the white screen of death (WSOD). '
};

export class DatabaseSettingsMtMock implements DatabaseSettingsMtService {
  async fetchData(organizationUuid: string): Promise<DatabaseSettings> {
    try {
      await wait(1000);
      return fetchDataMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async fetchDbConnection(
    organizationUuid: string
  ): Promise<MTDatabaseVersion> {
    try {
      await wait(1000);
      return testConnectionMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchDbConnection.name
      );
    }
  }

  async save(
    organizationUuid: string,
    databaseSettings: DatabaseSettings
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }

  async testConnection(
    organizationUuid: string,
    databaseSettings: DatabaseSettings
  ): Promise<MTDatabaseVersion> {
    try {
      await wait(1000);
      return fetchDbConnectionMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.testConnection.name
      );
    }
  }
}
