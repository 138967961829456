import {
  handleBackendError,
  MODEL_TREE_DESKTOP_UUID,
  omniApiConfiguration,
  wait
} from '@services';
import {
  BillingInvoiceAdditionalDetails,
  InvoiceItem,
  InvoiceItems,
  InvoicesInformation
} from './dataTypes';
import { ListParams } from 'interfaces';
import { AdditionalPaymentsItem, Currency } from '@xq/ui-kit';
import {
  BillingInvoiceListSortOptionsDTOFieldNameEnum,
  BillingInvoiceListSortOptionsDTOOrderEnum,
  BillingInvoicePageApi,
  BillingInvoicePageApiBillingInvoicePageControllerGetInvoiceListRequest
} from '@xq/omni-gateway-frontend-client';

export interface InvoicesService {
  fetchData(
    organizationUuid: string,
    licenseUuid: string
  ): Promise<InvoicesInformation>;

  fetchInvoices(
    organizationUuid: string,
    invoicesUuid: string,
    datesInterval?: [Date, Date],
    params?: ListParams
  ): Promise<InvoiceItems>;

  runBilling(organizationUuid: string): Promise<void>;

  fetchAdditionalPayments(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<AdditionalPaymentsItem[]>;

  saveAdditionalPayments(
    organizationUuid: string,
    invoiceUuid: string,
    additionalPayments: AdditionalPaymentsItem[]
  ): Promise<InvoiceItem>;

  deleteInvoice(organizationUuid: string, invoiceUuid: string): Promise<void>;
}

const omniInvoicesGateway = new BillingInvoicePageApi(omniApiConfiguration);

export class InvoicesServiceApi implements InvoicesService {
  async fetchInvoices(
    organizationUuid: string,
    invoicesUuid: string,
    datesInterval?: [Date, Date],
    params?: ListParams
  ): Promise<InvoiceItems> {
    try {
      const requestParams: BillingInvoicePageApiBillingInvoicePageControllerGetInvoiceListRequest =
        {
          organizationUuid: organizationUuid,
          billingInvoiceListOptionsDTORequest: {
            pagination: {
              page: params?.page,
              limit: params?.limit
            },
            sort: [
              {
                order:
                  params?.sortOrder as BillingInvoiceListSortOptionsDTOOrderEnum,
                fieldName:
                  params?.sortBy as BillingInvoiceListSortOptionsDTOFieldNameEnum
              }
            ]
          }
        };

      if (
        datesInterval &&
        Array.isArray(datesInterval) &&
        datesInterval.length === 2
      ) {
        requestParams.billingInvoiceListOptionsDTORequest.period = {
          from: datesInterval[0],
          to: datesInterval[1]
        };
      }

      return await omniInvoicesGateway.billingInvoicePageControllerGetInvoiceList(
        requestParams
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchInvoices.name
      );
    }
  }

  async fetchData(
    organizationUuid: string,
    invoicesUuid: string
  ): Promise<InvoicesInformation> {
    try {
      const response =
        await omniInvoicesGateway.billingInvoicePageControllerGetPageData({
          organizationUuid: organizationUuid
        });
      return { ...response, currencyIso3: response.currencyIso3 as Currency };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async runBilling(organizationUuid: string): Promise<void> {
    try {
      await omniInvoicesGateway.billingInvoicePageControllerRunBilling({
        organizationUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.runBilling.name
      );
    }
  }

  async fetchAdditionalPayments(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<BillingInvoiceAdditionalDetails[]> {
    try {
      const response =
        await omniInvoicesGateway.billingInvoicePageControllerGetInvoiceAdditionalPayments(
          {
            organizationUuid: organizationUuid,
            billingInvoiceUuid: invoiceUuid
          }
        );
      return response?.map((el) => {
        return { ...el, price: el.totalPayment };
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchAdditionalPayments.name
      );
    }
  }

  async saveAdditionalPayments(
    organizationUuid: string,
    invoiceUuid: string,
    additionalPayments: AdditionalPaymentsItem[]
  ): Promise<InvoiceItem> {
    try {
      const payments = additionalPayments?.map((el) => {
        return {
          uuid: el?.uuid,
          service: el?.service,
          comment: el?.comment,
          total: el?.price,
          from: el?.from,
          to: el?.to,
          amountOfUsers: el?.amountOfUsers
        };
      });

      return await omniInvoicesGateway.billingInvoicePageControllerSaveInvoiceAdditionalPayments(
        {
          billingInvoiceUuid: invoiceUuid,
          organizationUuid: organizationUuid,
          billingInvoiceDetailsListDTORequestSave: {
            additionalPayments: payments
          }
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.saveAdditionalPayments.name
      );
    }
  }

  async deleteInvoice(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<void> {
    try {
      await omniInvoicesGateway.billingInvoicePageControllerDeleteInvoice({
        billingInvoiceUuid: invoiceUuid,
        organizationUuid: organizationUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteInvoice.name
      );
    }
  }
}

const invoicesMock: InvoiceItem[] = [
  {
    uuid: '1',
    date: new Date('2019-12-10T18:32:04.000Z'),
    licenseName: 'ModelTree: Investment management',
    billingFrequency: 'QUARTERLY',
    paymentMethod: 'BEFORE',
    licensePayments: 5000,
    additionalPayments: 1000,
    totalPayments: 6000,
    canBeDeleted: false,
    from: new Date('2019-12-10T18:32:04.000Z'),
    to: new Date('2021-12-10T18:32:04.000Z'),
    licenseUuid: MODEL_TREE_DESKTOP_UUID
  },
  {
    uuid: '2',
    date: new Date('2022-12-10T18:32:04.000Z'),
    licenseName: 'ModelTree: Investment management',
    billingFrequency: 'MONTHLY',
    paymentMethod: 'AFTER',
    licensePayments: 3000,
    additionalPayments: 2000,
    totalPayments: 5000,
    canBeDeleted: true,
    from: new Date('2021-12-10T18:32:04.000Z'),
    to: new Date('2023-12-10T18:32:04.000Z'),
    licenseUuid: MODEL_TREE_DESKTOP_UUID
  }
];

const invoiceItemsMock: InvoiceItems = {
  items: invoicesMock,
  meta: {
    totalItems: 50,
    totalPages: 5,
    itemsPerPage: 10,
    currentPage: 1,
    itemCount: 50
  }
};

const pageDataMock: InvoicesInformation = {
  lastInvoiceDate: new Date('2022-12-10T18:32:04.000Z'),
  licensePayments: 50000,
  additionalPayments: 10000,
  totalPayments: 60000,
  currencyIso3: 'EUR'
};

const additionalPayments: BillingInvoiceAdditionalDetails[] = [
  {
    uuid: '1',
    service: 'Data out service',
    comment: 'For data integration',
    totalPayment: 1200,
    isEditable: false
  },
  {
    uuid: '2',
    service: 'Reporting plugin (ppt/word/excel)',
    comment: 'For data integration',
    totalPayment: 1200,
    isEditable: true
  }
];

export class InvoicesServiceMock implements InvoicesService {
  async fetchInvoices(
    organizationUuid: string,
    invoicesUuid: string,
    datesInterval?: [Date, Date],
    params?: ListParams
  ): Promise<InvoiceItems> {
    try {
      await wait(1000);
      return invoiceItemsMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchInvoices.name
      );
    }
  }

  async fetchData(
    organizationUuid: string,
    invoicesUuid: string
  ): Promise<InvoicesInformation> {
    try {
      await wait(1000);
      return pageDataMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async runBilling(organizationUuid: string): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.runBilling.name
      );
    }
  }

  async fetchAdditionalPayments(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<BillingInvoiceAdditionalDetails[]> {
    try {
      await wait(1000);
      return additionalPayments;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchAdditionalPayments.name
      );
    }
  }

  async saveAdditionalPayments(
    organizationUuid: string,
    invoiceUuid: string,
    additionalPayments: BillingInvoiceAdditionalDetails[]
  ): Promise<InvoiceItem> {
    try {
      await wait(1000);
      return invoicesMock[0];
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.saveAdditionalPayments.name
      );
    }
  }

  async deleteInvoice(
    organizationUuid: string,
    invoiceUuid: string
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteInvoice.name
      );
    }
  }
}
