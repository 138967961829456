import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Col,
  display,
  flex,
  LicenseApp,
  offsets,
  OmniLicenseCard,
  openStatusNotification,
  Row,
  SelectItem,
  Typography
} from '@xq/ui-kit';
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './CreateLicenses.module.scss';
import { useTranslation } from 'react-i18next';
import {
  CreateLicensesService,
  CreateLicensesServiceApi
} from './create-licenses-service';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  appNameMapperByUUID,
  getBreadcrumbWithDropdown,
  getCurrentLanguage,
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS,
  submitForm
} from '@services';
import { getRouteUrl, ROUTES } from '@router';
import { SidemenuContext, SidemenuContextData } from '@context';
import cn from 'classnames';
import {
  CreateLicenseModel,
  LicenseFeature
} from '@pages/Organizations/CreateLicenses/dataTypes';

export const CreateLicenses: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const service: CreateLicensesService = new CreateLicensesServiceApi();
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [licenses, setCreateLicenses] = useState<CreateLicenseModel[]>([]);
  const [licensesFullInfo, setLicensesFullInfo] = useState<LicensesFullInfo[]>(
    []
  );

  interface LicensesFullInfo {
    uuid: string;
    name: string;
    app: LicenseApp;
    description: string;
    features: Feature[];
    isTrial: boolean;
    expiryDate: Date;
    isActive: boolean;
    licenseType?: SelectItem;
    licenseTypes?: SelectItem[];
  }

  const generateLicensesFullInfo = (
    licenses: CreateLicenseModel[]
  ): LicensesFullInfo[] => {
    if (!licenses) {
      return [];
    }

    return licenses?.map((licenseProduct: CreateLicenseModel) => {
      return {
        uuid: licenseProduct.uuid,
        name: licenseProduct?.name,
        app: appNameMapperByUUID(licenseProduct.uuid)?.name as LicenseApp,
        /** License description */
        description: licenseProduct.description,
        /** License features list */
        features: mapFeatures(licenseProduct?.licenseFeatures),
        isTrial: Boolean(licenseProduct?.licenseProduct?.isTrial),
        expiryDate: licenseProduct?.licenseProduct?.expiryDate
          ? new Date(licenseProduct?.licenseProduct?.expiryDate)
          : null,
        isActive: Boolean(licenseProduct?.licenseProduct),
        licenseTypes: licenseProduct?.licenseTypes,
        licenseType: licenseProduct?.licenseProduct?.licenseType
      };
    });
  };

  useEffect(() => {
    setLicensesFullInfo(generateLicensesFullInfo(licenses));
  }, [licenses]);

  const mapFeatures = (features: LicenseFeature[]): Feature[] => {
    if (!features) {
      return [];
    }
    return features?.map((feature) => {
      return {
        id: feature.uuid,
        label: feature.name,
        value: Boolean(feature?.isActive),
        defaultValue: feature?.isActive
      };
    });
  };

  const changeFeature = (
    licenseId: string,
    featureId: string,
    value: boolean
  ) => {
    const updatedLicenseInfo = licensesFullInfo?.map((license) => {
      if (license.uuid === licenseId) {
        license?.features?.map((feature) => {
          if (feature.id === featureId) {
            feature.value = value;
          }
          return feature;
        });
      }
      return license;
    });

    setLicensesFullInfo(updatedLicenseInfo);
  };

  const setTrial = (licenseId: string, isTrial: boolean) => {
    const updatedLicenseInfo = licensesFullInfo?.map((license) => {
      if (license.uuid === licenseId) {
        license.isTrial = isTrial;
      }
      return license;
    });

    setLicensesFullInfo(updatedLicenseInfo);
  };

  const setLicenseType = (licenseId: string, value: SelectItem) => {
    const updatedLicenseInfo = licensesFullInfo?.map((license) => {
      if (license?.uuid === licenseId) {
        license.licenseType = value;
      }
      return license;
    });

    setLicensesFullInfo(updatedLicenseInfo);
  };

  const resetFeatures = (licenseId: string) => {
    const updatedLicenseInfo = licensesFullInfo?.map((license) => {
      if (license.uuid === licenseId) {
        license?.features?.map((feature) => {
          feature.value = Boolean(feature?.defaultValue);
          return feature;
        });
      }

      return license;
    });

    setLicensesFullInfo(updatedLicenseInfo);
  };

  const setExpiryDate = (licenseId: string, date: Date) => {
    const updatedLicenseInfo = licensesFullInfo?.map((license) => {
      if (license.uuid === licenseId) {
        license.expiryDate = date;
      }
      return license;
    });

    setLicensesFullInfo(updatedLicenseInfo);
  };

  const setIsActive = (licenseId: string, isActive: boolean) => {
    const updatedLicenseInfo = licensesFullInfo?.map((license) => {
      if (license.uuid === licenseId) {
        license.isActive = isActive;
      }
      return license;
    });

    setLicensesFullInfo(updatedLicenseInfo);
  };

  interface Feature {
    id: string;
    label: string;
    value: boolean;
    defaultValue: boolean;
  }

  async function fetchData() {
    try {
      setIsLoading(true);
      const result = await service.fetchData(params?.id);
      if (result?.length === 0) {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: 400,
          message: t('notifications.youHaveAlreadyCreatedAllLicenses')
        });
        navigate(getRouteUrl(ROUTES.ORGANIZATIONS.LICENSES, { id: params.id }));
        return;
      }
      setCreateLicenses(result);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [params]);

  const isLicensesValid = useMemo(() => {
    return licensesFullInfo?.reduce(
      (acc: boolean, license: LicensesFullInfo) => {
        return (
          acc ||
          (license?.isActive &&
            (!license?.isTrial || (license.isTrial && license.expiryDate)))
        );
      },
      false
    );
  }, [licensesFullInfo]);

  const cancel = () => {
    navigate(getRouteUrl(ROUTES.ORGANIZATIONS.LICENSES, { id: params.id }));
  };

  async function save() {
    try {
      const newLicenses = licensesFullInfo
        ?.filter((license) => license.isActive)
        ?.map((license) => {
          return {
            licenseProductUuid: license?.uuid,
            isTrial: license?.isTrial,
            expireAt: license?.expiryDate
              ? new Date(license?.expiryDate)
              : undefined,
            licenseTypeUuid: license?.licenseType?.value,
            licenseProductFeatureUUIDs:
              license?.features?.length > 0
                ? license?.features?.filter((el) => el.value).map((el) => el.id)
                : null
          };
        });
      setIsLoading(true);

      await service.save(params.id, newLicenses);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
      navigate(getRouteUrl(ROUTES.ORGANIZATIONS.LICENSES, { id: params.id }));
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  const breadcrumbs: BreadcrumbsItem[] = useMemo(() => {
    return [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.LICENSES,
        { id: params?.id }
      ),
      {
        label: t(ROUTES.ORGANIZATIONS.LICENSES),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.LICENSES, {
          id: sidemenuContext?.currentOrganization?.uuid
        })
      }
    ];
  }, [licenses, sidemenuContext, params]);

  /** Sidemenu context */
  useEffect(() => {
    sidemenuContext.setActiveMenu(ORGANIZATION_SIDEMENUS.ORGANIZATION_LICENSES);
  }, [sidemenuContext, params.licenseId]);

  return (
    <Fragment>
      <Row cols={10}>
        <Breadcrumbs
          NavLink={NavLink}
          className="breadcrumbs"
          items={breadcrumbs}
        />

        <Col col={9} className="heading">
          <Typography element="div" variant="h2">
            {t('organizations.createLicenses')}
          </Typography>
        </Col>

        {licensesFullInfo?.length > 0 && (
          <form onSubmit={submitForm}>
            <Col col={9} className={styles.licenses}>
              {licensesFullInfo?.map((license, key) => {
                return (
                  <div key={key}>
                    <OmniLicenseCard
                      className={styles.license}
                      translations={{
                        expiryDate: t('uiKit.expiryDate'),
                        isTrial: t('uiKit.isTrial'),
                        licenseFeatures: t('common.licenseFeatures')
                      }}
                      app={license?.app}
                      description={license?.description}
                      features={license?.features}
                      onChangeFeature={(featureId: string, value: boolean) =>
                        changeFeature(license.uuid, featureId, value)
                      }
                      resetFeatures={() => resetFeatures(license.uuid)}
                      isTrial={license?.isTrial}
                      setTrial={(value) => setTrial(license.uuid, value)}
                      /** Language for the date-picker */
                      locale={getCurrentLanguage()}
                      expiryDate={license?.expiryDate}
                      setExpiryDate={(value) =>
                        setExpiryDate(license.uuid, value)
                      }
                      isActive={license?.isActive}
                      setIsActive={(value) => setIsActive(license.uuid, value)}
                      licenseTypeLabel={t('uiKit.licenseType')}
                      licenseType={license.licenseType}
                      licenseTypes={license.licenseTypes}
                      setLicenseType={(value) =>
                        setLicenseType(license.uuid, value)
                      }
                    />
                  </div>
                );
              })}
            </Col>

            <div className={offsets['mt-40']}>
              <div
                className={cn(display['d-flex'], flex['align-items-center'])}
              >
                <Button
                  buttonType={'submit'}
                  disabled={!isLicensesValid}
                  onClick={save}
                  className={offsets['mr-20']}
                  isLoading={isLoading}
                >
                  {t('common.create')}
                </Button>
                <Button onClick={cancel} type="secondary" disabled={isLoading}>
                  {t('common.cancel')}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Row>
    </Fragment>
  );
};

CreateLicenses.displayName = 'CreateLicenses';
