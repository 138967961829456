import React from 'react';
import { TableColumn, formatDate, Typography, common } from '@xq/ui-kit';
import { TFunction } from 'i18next';
import { BuildsItem } from '@pages';
import { DASH } from '@constants';

/** Methods for the table */
export const createBuildsRows = (t: TFunction, builds: BuildsItem[]) => {
  return builds?.map((build) => ({
    id: build?.uuid,
    data: {
      buildBranch: (
        <Typography
          element={'div'}
          className={common['no-text-transform']}
          variant={'body-4'}
        >
          {build?.branchName || DASH}
        </Typography>
      ),
      buildId: build?.buildId || DASH,
      createdAt: build?.createdAt ? formatDate(build?.createdAt) : DASH,
      releaseVersion: build?.releaseVersion || DASH
    }
  }));
};

export const createBuildsColumns = (t: TFunction): TableColumn[] => [
  {
    title: t('modelTree.buildBranch'),
    key: 'buildBranch',
    width: 200,
    alignment: 'left',
    paddingLeft: 20
  },
  {
    title: t('modelTree.buildId'),
    key: 'buildId',
    width: 100,
    alignment: 'left',
    paddingLeft: 40
  },
  {
    title: t('common.createOn'),
    key: 'createdAt',
    width: 100,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('modelTree.releaseVersion'),
    key: 'releaseVersion',
    width: 200,
    alignment: 'left',
    paddingLeft: 20
  }
];
