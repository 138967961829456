import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { openStatusNotification, UserTrackingReport } from '@xq/ui-kit';
import {
  backendErrorLog,
  downloadFile,
  getCurrentLanguage,
  getStatusNotificationTranslations
} from '@services';
import { config } from '@config';

export const LicenseUserTrackingReport: FC = () => {
  const { t } = useTranslation();
  const params = useParams();

  const [rangePickerValue, setRangePickerValue] = useState<[Date, Date]>(null);

  async function onUserTrackingReportDownload() {
    try {
      // todo find the way to generate this url from gateway open api client
      const url = `${config.apiUrl}/omni/page/license-info/organization/${
        params.id
      }/license/${
        params.licenseId
      }/user-report/file?from=${rangePickerValue[0].toISOString()}&to=${rangePickerValue[1].toISOString()}`;

      await downloadFile(url);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
      backendErrorLog(error, `downloadUserTrackingReport error`);
    }
  }

  const locale = getCurrentLanguage();

  return (
    <UserTrackingReport
      translations={{
        userTrackingReport: t('uiKit.userTrackingReport')
      }}
      locale={locale}
      rangePickerValue={rangePickerValue}
      onRangePickerChange={setRangePickerValue}
      onDownload={onUserTrackingReportDownload}
    />
  );
};

LicenseUserTrackingReport.displayName = 'LicenseUserTrackingReport';
