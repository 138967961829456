import React from 'react';
import {
  convertBillingFrequencyToText,
  convertPaymentMethodToText,
  Currency,
  formatCurrency,
  formatDate,
  TableColumn,
  Typography
} from '@xq/ui-kit';
import { InvoiceItem } from './dataTypes';
import { TFunction } from 'i18next';
import { DASH } from '@constants';

/** Methods for the table */
export const createInvoiceRows = (
  t: TFunction,
  invoices: InvoiceItem[],
  currency: Currency
) => {
  return invoices?.map((invoice) => ({
    id: invoice?.uuid,
    data: {
      date: (
        <Typography variant="body-4">
          {invoice?.date ? formatDate(invoice?.date) : DASH}
        </Typography>
      ),
      licenseName: (
        <Typography variant="body-4">{invoice?.licenseName}</Typography>
      ),
      invoicing: (
        <Typography variant="body-4">
          {convertBillingFrequencyToText(invoice?.billingFrequency)}
        </Typography>
      ),
      method: (
        <Typography variant="body-4">
          {convertPaymentMethodToText(invoice?.paymentMethod)}
        </Typography>
      ),
      licensePayments: (
        <Typography variant="number-1">
          {formatCurrency(currency, invoice?.licensePayments, 2)}
        </Typography>
      ),
      additionalPayments: (
        <Typography variant="number-1">
          {formatCurrency(currency, invoice?.additionalPayments, 2)}
        </Typography>
      ),
      totalPayments: (
        <Typography variant="number-1">
          {formatCurrency(currency, invoice?.totalPayments, 2)}
        </Typography>
      )
    }
  }));
};

export const createInvoiceColumns = (t: TFunction): TableColumn[] => [
  {
    title: t('common.date'),
    key: 'date',
    width: 90,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('common.license'),
    key: 'licenseName',
    width: 160,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('organizations.invoicing'),
    key: 'invoicing',
    width: 90,
    alignment: 'left',
    paddingLeft: 20
  },
  {
    title: t('organizations.method'),
    key: 'method',
    width: 110,
    alignment: 'left',
    paddingLeft: 20
  },
  {
    title: t('organizations.licensePayments'),
    key: 'licensePayments',
    width: 90,
    alignment: 'right',
    paddingLeft: 20,
    paddingRight: 20,
    sortable: true
  },
  {
    title: t('organizations.additionalPayments'),
    key: 'additionalPayments',
    width: 110,
    alignment: 'right',
    paddingRight: 20,
    sortable: true
  },
  {
    title: t('common.total'),
    key: 'totalPayments',
    width: 110,
    alignment: 'right',
    sortable: true
  }
];
