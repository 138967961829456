import { PricingItem } from '@pages/Organizations/Pricing/dataTypes';
import { PriceRuleType, PRICING_OPTIONS, PricingData } from '@xq/ui-kit';
import { methodsAndInvoicingOptions } from '@pages/Organizations/Pricing/constants';

export const getInitialPriceItem = (uuid: string): PricingItem => ({
  updateIsAllowed: true,
  startAt: null,
  priceRuleType: PRICING_OPTIONS.TOTAL_PRICE as PriceRuleType,
  licensePriceListRules: [],
  uuid
});

const sortPricingByDate = (pricing: PricingItem[]) => {
  if (!pricing || pricing.length === 0) {
    return null;
  }
  return [...pricing].sort(
    (a, b) => new Date(a?.startAt)?.getTime() - new Date(b?.startAt)?.getTime()
  );
};

export const getPricingForList = (pricing: PricingData[]) => {
  if (pricing) {
    return pricing?.filter((el) => el.numberOfUsers);
  }
  return [];
};

export const getPricePerUserMonthly = (pricing: PricingData[]) => {
  if (pricing) {
    return pricing?.find((el) => !el.numberOfUsers)?.price || null;
  }
  return null;
};

export const getPricingInformationForBackend = (
  pricingItems: PricingItem[]
) => {
  return pricingItems?.map((pricing) => {
    const pricingCopy = { ...pricing };
    delete pricingCopy['invoicingAndMethod'];
    return pricingCopy;
  });
};

export const initPricingInformationObject = (pricingData: PricingItem[]) => {
  if (!pricingData || pricingData?.length === 0) {
    return [];
  }

  function removeUndefinedNumberOfUsers(
    pricingData: PricingItem[]
  ): PricingItem[] {
    return pricingData?.map((pricing) => {
      pricing.licensePriceListRules = pricing?.licensePriceListRules?.map(
        (rule) => {
          if (!rule.numberOfUsers) {
            delete rule['numberOfUsers'];
            return { ...rule };
          }
          return rule;
        }
      );
      return pricing;
    });
  }

  return sortPricingByDate(removeUndefinedNumberOfUsers(pricingData))?.map(
    (pricing) => {
      pricing.startAt = new Date(pricing.startAt);

      if (pricing?.billingFrequency && pricing?.paymentMethod) {
        const invoicingAndMethod = methodsAndInvoicingOptions?.find(
          (option) =>
            option.value ===
            `${pricing.billingFrequency}_${pricing.paymentMethod}`
        );
        return { ...pricing, invoicingAndMethod };
      }
      return pricing;
    }
  );
};

export const generateInitialPricingData = (
  initialPricingInformation: PricingItem[]
) => {
  return (
    initialPricingInformation?.map((pricing) => {
      if (
        typeof pricing === 'object' &&
        !Array.isArray(pricing) &&
        pricing !== null
      ) {
        pricing.startAt = pricing.startAt ? new Date(pricing.startAt) : null;
      }

      pricing.licensePriceListRules = pricing?.licensePriceListRules?.map(
        (rule) => {
          if (!rule.numberOfUsers) {
            return { ...rule };
          }
          return rule;
        }
      );

      return pricing;
    }) || []
  );
};

export const isPricingValid = (pricingInformation: PricingItem[]): boolean => {
  // check that selects are filled
  const isMainInfoValid: boolean = pricingInformation?.reduce(
    (result: boolean, priceList: PricingItem) => {
      return Boolean(
        result &&
          priceList?.startAt &&
          priceList?.paymentMethod &&
          priceList?.invoicingAndMethod
      );
    },
    true
  );

  if (!isMainInfoValid) {
    return isMainInfoValid;
  }

  // check that all price lists have a price
  return pricingInformation?.reduce(
    (result: boolean, priceList: PricingItem) => {
      const isNotValid: boolean = priceList?.licensePriceListRules?.some(
        (el) => !el.price
      );
      return Boolean(result && !isNotValid);
    },
    true
  );
};

export const filterChangedPriceLists = (
  pricing: PricingItem[],
  initialPricing: PricingItem[]
) => {
  if (!pricing || pricing.length === 0) {
    return [];
  }

  if (!initialPricing || initialPricing.length === 0) {
    return pricing;
  }

  const filteredPricing: PricingItem[] = [];

  /** Add new price lists */
  if (pricing.length !== initialPricing.length) {
    const newPricing: PricingItem[] = pricing.filter(
      (item) => !initialPricing.some((el) => el.uuid === item.uuid)
    );
    filteredPricing.push(...newPricing);
  }

  let oldEditedPricing = pricing.filter((item) =>
    initialPricing.some((el) => el.uuid === item.uuid)
  );

  /** Add previous price lists that were edited */
  oldEditedPricing = oldEditedPricing.filter((oldEditedPriceList) => {
    const initialObject = initialPricing.find(
      (el) => el.uuid === oldEditedPriceList.uuid
    );
    if (!initialObject) {
      return true;
    }
    return JSON.stringify(initialObject) != JSON.stringify(oldEditedPriceList);
  });

  if (oldEditedPricing) {
    filteredPricing.push(...oldEditedPricing);
  }

  return filteredPricing;
};

export const scrollPricingToRight = () => {
  const pricingBlockEl = document.getElementById('pricingBlock');
  if (pricingBlockEl) {
    pricingBlockEl.scrollLeft = pricingBlockEl.scrollWidth;
  }
};
