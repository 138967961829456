import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { MainInformationData } from './dataTypes';
import { MainInfoPageApi } from '@xq/omni-gateway-frontend-client';

export interface MainInformationService {
  fetchData(): Promise<MainInformationData>;
}

const mainInfoGateway = new MainInfoPageApi(omniApiConfiguration);

const mainInformationMock: MainInformationData = {
  userStatistic: {
    totalUsers: 192,
    activeUsers: 29,
    administrators: 153,
    externalUsers: 2
  },
  general: {
    totalOrganizations: 32,
    monthlyIncome: [
      {
        currencyIso3: 'EUR',
        amount: 80000
      },
      {
        currencyIso3: 'USD',
        amount: 41200
      },
      {
        currencyIso3: 'RUB',
        amount: 152930
      }
    ]
  }
};

export class MainInformationService implements MainInformationService {
  async fetchData(): Promise<MainInformationData> {
    try {
      return (await mainInfoGateway.mainInfoPageControllerGetPageData()) as MainInformationData;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }
}

export class MainInformationServiceMock implements MainInformationService {
  async fetchData(): Promise<MainInformationData> {
    try {
      await wait(1000);
      return mainInformationMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }
}
