import { handleBackendError, omniApiConfiguration } from '@services';
import {
  ApiKeyCreatePageApi,
  ApiKeyCreatePageDataDTOResponse,
  ApiKeyDTORequest
} from '@xq/omni-gateway-frontend-client';

export interface ApiKeysCreateService {
  fetchData(organizationUuid: string): Promise<ApiKeyCreatePageDataDTOResponse>;

  save(
    organizationUuid: string,
    apiKeyDTORequest: ApiKeyDTORequest
  ): Promise<string>;
}

const ApiKeysCreateServiceGateway = new ApiKeyCreatePageApi(
  omniApiConfiguration
);

export class ApiKeysCreateServiceApi implements ApiKeysCreateService {
  async fetchData(
    organizationUuid: string
  ): Promise<ApiKeyCreatePageDataDTOResponse> {
    try {
      return await ApiKeysCreateServiceGateway.apiKeysPageControllerGetApiKeyPermissionsList(
        {
          organizationUuid
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(
    organizationUuid: string,
    apiKeyDTORequest: ApiKeyDTORequest
  ): Promise<string> {
    try {
      return await ApiKeysCreateServiceGateway.apiKeysPageControllerCreateApiKeyForOrganization(
        {
          organizationUuid,
          apiKeyDTORequest
        }
      );
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}
