import {
  handleBackendError,
  MODEL_TREE_DESKTOP_UUID,
  omniApiConfiguration,
  wait
} from '@services';
import { LicenseListPageApi } from '@xq/omni-gateway-frontend-client';

export interface LicenseProduct {
  uuid: string;
  name: string;
  description: string;
  license: {
    uuid: string;
    isTrial: boolean;
    expireAt?: Date;
    isSuspended: boolean;
    licenseTypeName?: string;
    licenseFeatureNames: Array<string>;
  };
}

interface LicensesData {
  licenseProducts: LicenseProduct[];
  canCreateNewLicenses: boolean;
}

export interface LicensesService {
  fetchData(organizationUuid: string): Promise<LicensesData>;
}

const omniLicenseListGateway = new LicenseListPageApi(omniApiConfiguration);

export class LicensesServiceApi implements LicensesService {
  async fetchData(organizationUuid: string): Promise<LicensesData> {
    try {
      return await omniLicenseListGateway.licenseListPageControllerGetPageData({
        organizationUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }
}

const licenseProductsMock: LicenseProduct[] = [
  {
    uuid: MODEL_TREE_DESKTOP_UUID,
    name: 'model-tree',
    description:
      'Ultimate all-in-one financial modelling and reporting platform for property investment management and valuation.',
    license: {
      uuid: '2',
      isTrial: false,
      isSuspended: false,
      licenseTypeName: 'Valuation',
      licenseFeatureNames: ['Reports and Dashboards', 'Project templates']
    }
  }
];

const licenseDataMock: LicensesData = {
  licenseProducts: licenseProductsMock,
  canCreateNewLicenses: true
};

export class LicensesServiceMock implements LicensesService {
  async fetchData(organizationUuid: string): Promise<LicensesData> {
    try {
      await wait(1000);
      return licenseDataMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }
}
