import { wait, omniApiConfiguration, handleBackendError } from '@services';
import { OrganizationName } from 'interfaces';
import { GlobalMenuRoutesApi } from '@xq/omni-gateway-frontend-client';

export interface SidemenuLayoutLicense {
  uuid: string;
  title: string;
  hasErrors?: boolean;
}

export interface SidemenuLayoutInformation {
  organizations?: OrganizationName[];
  currentOrganization?: OrganizationName;
  licenses?: SidemenuLayoutLicense[];
}

export interface SidemenuLayoutService {
  fetchData(organizationUuid: string): Promise<SidemenuLayoutInformation>;
}

const omniSidemenuGateway = new GlobalMenuRoutesApi(omniApiConfiguration);

export class SidemenuLayoutService implements SidemenuLayoutService {
  async fetchData(
    organizationUuid: string
  ): Promise<SidemenuLayoutInformation> {
    try {
      const { licenses } =
        await omniSidemenuGateway.menuControllerGetSideMenuData({
          organizationUuid
        });

      const { organizations, currentOrganization } =
        await omniSidemenuGateway.menuControllerGetBreadcrumpData({
          organizationUuid
        });
      return {
        organizations,
        currentOrganization,
        licenses
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }
}

const sidemenuLayoutInformationMock: SidemenuLayoutInformation = {
  currentOrganization: {
    uuid: '00000000-0000-0000-0000-000000000000',
    name: 'Exquance',
    countryIso3: 'FIN'
  },
  organizations: [
    {
      uuid: '00000000-0000-0000-0000-000000000000',
      name: 'Exquance',
      countryIso3: 'FIN'
    },
    {
      uuid: '00000000-0000-0000-0000-000000000001',
      name: '2NS',
      countryIso3: 'SWE'
    }
  ],
  licenses: [
    { uuid: '1', title: 'Fundy' },
    {
      uuid: '2',
      title: 'ModelTree: Investment management',
      hasErrors: true
    }
  ]
};

export class SidemenuLayoutServiceMock implements SidemenuLayoutService {
  async fetchData(
    organizationUuid: string
  ): Promise<SidemenuLayoutInformation> {
    try {
      await wait(100);
      return sidemenuLayoutInformationMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }
}
