import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { BuildsItem, BuildsList } from './dataTypes';
import { ListParams } from 'interfaces';
import {
  ModelTreeBuildsListPageApi,
  ModelTreeBuildInfoListSortOptionsDTOFieldNameEnum,
  ModelTreeBuildInfoListSortOptionsDTOOrderEnum
} from '@xq/omni-gateway-frontend-client';

export interface BuildsService {
  fetchBuilds(params?: ListParams): Promise<BuildsList>;
  deleteBuild(buildUuid: number): Promise<void>;
}

const omniBuildsGateway = new ModelTreeBuildsListPageApi(omniApiConfiguration);

export class BuildsService implements BuildsService {
  async fetchBuilds(params?: ListParams): Promise<BuildsList> {
    try {
      const response =
        await omniBuildsGateway.modelTreeBuildsListPageControllerGetList({
          searchString: params?.searchValue,
          modelTreeBuildInfoListOptionsDTORequest: {
            pagination: {
              page: params?.page,
              limit: params?.limit
            },
            filters: [],
            sort: [
              {
                order:
                  params?.sortOrder as ModelTreeBuildInfoListSortOptionsDTOOrderEnum,
                fieldName:
                  params?.sortBy as ModelTreeBuildInfoListSortOptionsDTOFieldNameEnum
              }
            ]
          }
        });
      return {
        ...response,
        items: response?.items?.map((item) => {
          return { ...item, uuid: String(item?.buildId) };
        })
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchBuilds.name
      );
    }
  }

  async deleteBuild(buildUuid: number): Promise<void> {
    try {
      await omniBuildsGateway.modelTreeBuildsListPageControllerDeleteBuild({
        modelTreeBuildId: buildUuid
      });
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteBuild.name
      );
    }
  }
}

const buildsMock: BuildsItem[] = [
  {
    uuid: '28037',
    branchName: 'Branch name 14',
    buildId: 28037,
    createdAt: new Date(2022, 3, 22),
    releaseVersion: 'Version 7.2021'
  },
  {
    uuid: '28036',
    branchName: 'Branch name 13',
    buildId: 28036,
    createdAt: new Date(2022, 3, 12),
    releaseVersion: 'Version 7.2021'
  },
  {
    uuid: '28035',
    branchName: 'Branch name 12',
    buildId: 28035,
    createdAt: new Date(2022, 2, 24)
  }
];

const buildsListMock: BuildsList = {
  items: buildsMock,
  meta: {
    totalItems: 50,
    totalPages: 5,
    itemsPerPage: 10,
    currentPage: 1,
    itemCount: 50
  }
};

export class BuildsServiceMock implements BuildsService {
  async fetchBuilds(params?: ListParams): Promise<BuildsList> {
    try {
      await wait(1000);
      return buildsListMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchBuilds.name
      );
    }
  }

  async deleteBuild(buildUuid: number): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteBuild.name
      );
    }
  }
}
