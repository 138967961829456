import { handleBackendError, omniApiConfiguration, wait } from '@services';
import { ContactPerson } from './dataTypes';
import { ContactPersonEditPageApi } from '@xq/omni-gateway-frontend-client';

export interface EditContactPersonService {
  fetchData(
    organizationUuid: string,
    contactPersonUuid: string
  ): Promise<ContactPerson>;

  save(organizationUuid: string, contactPerson: ContactPerson): Promise<void>;
}

const editContactPersonGateway = new ContactPersonEditPageApi(
  omniApiConfiguration
);

export class EditContactPersonServiceApi implements EditContactPersonService {
  async fetchData(
    organizationUuid: string,
    contactPersonUuid: string
  ): Promise<ContactPerson> {
    try {
      const response =
        await editContactPersonGateway.contactPersonEditPageControllerGetPageData(
          {
            organizationContactPersonUuid: contactPersonUuid,
            organizationUuid
          }
        );
      return response.contactPerson as ContactPerson;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(
    organizationUuid: string,
    contactPerson: ContactPerson
  ): Promise<void> {
    try {
      await editContactPersonGateway.contactPersonEditPageControllerUpdateContactPerson(
        {
          organizationContactPersonUuid: contactPerson.uuid,
          organizationUuid: organizationUuid,
          contactPersonDTORequestUpdate: {
            name: contactPerson.name,
            position: contactPerson.position || null,
            email: contactPerson.email,
            phone: contactPerson.phone || null,
            comment: contactPerson.comment || null
          }
        }
      );
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}

const contactPersonMock: ContactPerson = {
  uuid: '1',
  name: 'Alexey Vlasov',
  phone: '+79216486433',
  email: 'alexey.vlasov@exquance.com',
  position: 'IT Admin',
  comment:
    'Mattis massa sed ac, pellentesque massa congue luctus etiam faucibus'
};

export class EditContactPersonServiceMock implements EditContactPersonService {
  async fetchData(
    organizationUuid: string,
    contactPersonUuid: string
  ): Promise<ContactPerson> {
    try {
      await wait(1000);
      return contactPersonMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(
    organizationUuid: string,
    contactPerson: ContactPerson
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}
