import {
  handleBackendError,
  MODEL_TREE_DESKTOP_UUID,
  omniApiConfiguration,
  wait
} from '@services';
import { LicenseInfo, User } from './dataTypes';
import { UserCreateEditPageApi } from '@xq/omni-gateway-frontend-client';
import { config } from '@config';

interface CreateEditUserData {
  licenses: LicenseInfo[];
}

export interface CreateEditUserService {
  fetchData(organizationUuid: string): Promise<CreateEditUserData>;

  fetchUser(organizationUuid: string, userUuid: string): Promise<User>;

  save(organizationUuid: string, user: User): Promise<void>;
}

const omniUserCreateEditGateway = new UserCreateEditPageApi(
  omniApiConfiguration
);

export class CreateEditUserServiceApi implements CreateEditUserService {
  async fetchData(organizationUuid: string): Promise<CreateEditUserData> {
    try {
      return await omniUserCreateEditGateway.userCreateEditPageControllerGetPageData(
        {
          organizationUuid: organizationUuid
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async fetchUser(organizationUuid: string, userUuid: string): Promise<User> {
    try {
      return await omniUserCreateEditGateway.userCreateEditPageControllerGetUserData(
        {
          organizationUuid: organizationUuid,
          userUuid: userUuid
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchUser.name
      );
    }
  }

  async save(organizationUuid: string, user: User): Promise<void> {
    try {
      const isEdit = Boolean(user?.uuid);

      const data = {
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        isOrganizationAdmin: user?.isOrganizationAdmin,
        isExternal: user?.isExternal,
        attachToLicenses: user?.attachToLicenses,
        setPasswordUrl: config?.ssoFrontendSetPasswordUrl
      };

      if (isEdit) {
        await omniUserCreateEditGateway.userCreateEditPageControllerUpdateUserData(
          {
            userUuid: user.uuid,
            organizationUuid: organizationUuid,
            userUpdateDTORequest: data
          }
        );
      } else {
        await omniUserCreateEditGateway.userCreateEditPageControllerPostUserCreate(
          {
            organizationUuid: organizationUuid,
            userCreateDTORequest: data
          }
        );
      }
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}

const userMock: User = {
  email: 'andrey.smirnov@2ns.com',
  firstName: 'Andrey',
  lastName: 'Smirnov',
  isOrganizationAdmin: true,
  attachToLicenses: []
};

/** information about organization licenses */
const licensesInfoMock: LicenseInfo[] = [
  {
    uuid: MODEL_TREE_DESKTOP_UUID,
    licenseProduct: {
      uuid: MODEL_TREE_DESKTOP_UUID,
      name: 'ModelTree',
      description: 'This is the must have product!'
    },
    licenseType: {
      uuid: '3',
      name: 'Valuation'
    },
    roles: [
      {
        label: 'Admin',
        value: '1'
      },
      {
        label: 'Valuator',
        value: '2'
      }
    ],
    isTrial: false,
    expireAt: new Date('2022-06-30T09:04:05.578Z')
  }
];

const dataMock: CreateEditUserData = {
  licenses: licensesInfoMock
};

export class CreateEditUserServiceMock implements CreateEditUserService {
  async fetchData(organizationUuid: string): Promise<CreateEditUserData> {
    try {
      await wait(1000);
      return { ...dataMock };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(organizationUuid: string, user: User): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }

  async fetchUser(organizationUuid: string, userUuid: string): Promise<User> {
    try {
      await wait(1000);
      return {
        ...userMock
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchUser.name
      );
    }
  }
}
