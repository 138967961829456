import {
  colors,
  formatDate,
  TableColumn,
  TableRow,
  Typography
} from '@xq/ui-kit';
import React from 'react';
import { TFunction } from 'i18next';
import { DASH } from '@constants';
import { ApiKeyInfo } from '@xq/omni-gateway-frontend-client';
import styles from './ApiKeys.module.scss';

export function createApiKeyRows(
  t: TFunction,
  apiKeys: ApiKeyInfo[]
): TableRow[] {
  return apiKeys?.map((apiKey) => ({
    id: apiKey?.uuid,
    data: {
      name: (
        <Typography element="div" variant="body-4">
          {apiKey?.name || DASH}
        </Typography>
      ),
      permissions: (
        <div className={styles.permissions}>
          {apiKey?.permissions?.length > 0
            ? apiKey?.permissions?.map((permission) => {
                return (
                  <Typography element="div" variant="body-4" key={permission}>
                    {permission}
                  </Typography>
                );
              })
            : DASH}
        </div>
      ),
      createdAt: (
        <Typography element="div" variant="body-4">
          {apiKey?.createdAt ? formatDate(apiKey?.createdAt) : DASH}
        </Typography>
      ),
      expireAt: (
        <Typography
          color={apiKey?.isValid ? colors.gray100Color : colors.red70Color}
          element="div"
          variant="body-4"
        >
          {apiKey?.expireAt ? formatDate(apiKey?.expireAt) : DASH}
        </Typography>
      )
    }
  }));
}

export function createApiKeyColumns(t: TFunction): TableColumn[] {
  return [
    {
      title: t('organizations.applicationName'),
      key: 'name',
      width: 180,
      alignment: 'left',
      paddingLeft: 20,
      sortable: true
    },
    {
      title: t('common.scopes'),
      key: 'permissions',
      width: 180,
      alignment: 'left',
      paddingLeft: 20,
      paddingRight: 20,
      sortable: false
    },
    {
      title: t('common.createOn'),
      key: 'createdAt',
      width: 98,
      alignment: 'left',
      paddingLeft: 20,
      sortable: false
    },
    {
      title: t('uiKit.expiryDate'),
      key: 'expireAt',
      width: 98,
      alignment: 'left',
      paddingLeft: 20,
      sortable: false
    }
  ];
}
