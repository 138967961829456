import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Col,
  DatabaseVersion,
  Input,
  offsets,
  openStatusNotification,
  Row,
  Textarea,
  Toggle,
  Typography
} from '@xq/ui-kit';
import styles from './DatabaseSettingsMT.module.scss';
import { useTranslation } from 'react-i18next';
import {
  DatabaseSettingsMtService,
  DatabaseSettingsMtServiceApi
} from './database-settings-mt-service';
import cn from 'classnames';
import { NavLink, useParams } from 'react-router-dom';
import { SidemenuContext, SidemenuContextData } from '@context';
import {
  getBreadcrumbWithDropdown,
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS,
  submitForm
} from '@services';
import { getRouteUrl, ROUTES } from '@router';
import { DatabaseSettings, MTDatabaseVersion } from './dataTypes';
import hash from 'object-hash';

const defaultDatabaseSettings: DatabaseSettings = {
  serverAddress: '',
  port: null,
  database: '',
  user: '',
  password: '',
  isLocal: false,
  isTrustServerCertificate: false
};

export const DatabaseSettingsMT: FC = () => {
  const { t } = useTranslation();
  const params = useParams();

  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);
  const service: DatabaseSettingsMtService = new DatabaseSettingsMtServiceApi();

  const [databaseSettings, setDatabaseSettings] = useState<DatabaseSettings>(
    defaultDatabaseSettings
  );
  const [databaseConnection, setDatabaseConnection] =
    useState<MTDatabaseVersion>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDbConnectionLoading, setIsDbConnectionLoading] =
    useState<boolean>(false);
  const [isConnectionTested, setIsConnectionTested] = useState<boolean>(false);

  const [initialDatabaseSettings, setInitialDatabaseSettings] =
    useState<DatabaseSettings>(null);

  const [isUserValidationError, setIsUserValidationError] =
    useState<boolean>(false);

  const isChanged: boolean = useMemo(() => {
    if (databaseSettings) {
      return hash(databaseSettings) !== hash(initialDatabaseSettings);
    }
    return true;
  }, [databaseSettings, initialDatabaseSettings]);

  const isFieldsFilled = useMemo(() => {
    return !!(
      databaseSettings?.serverAddress &&
      databaseSettings?.port &&
      databaseSettings?.database &&
      databaseSettings?.user &&
      databaseSettings?.password
    );
  }, [databaseSettings]);

  const isSubmitDisabled = useMemo(() => {
    if (!isFieldsFilled) {
      return true;
    }

    if (isConnectionTested) {
      return false;
    }

    return !isChanged;
  }, [isFieldsFilled, isChanged, isConnectionTested]);

  const submitButtonText = useMemo(() => {
    const save = t('common.save');
    const saveAnyway = t('common.saveAnyway');
    const testConnection = t('organizations.testConnection');

    if (isConnectionTested && !isChanged) {
      if (databaseConnection?.error) {
        return saveAnyway;
      }

      if (databaseConnection) {
        return save;
      }
    }

    return testConnection;
  }, [databaseConnection, isChanged, isConnectionTested]);

  const submitButtonFunction = () => {
    if (isChanged || !isConnectionTested) {
      return testConnection;
    }

    return save;
  };

  useEffect(() => {
    fetchData();
  }, [params.id]);

  async function fetchData() {
    setDatabaseSettings(initialDatabaseSettings);
    setIsDbConnectionLoading(false);
    setDatabaseConnection(null);
    try {
      const response: DatabaseSettings = await service.fetchData(params.id);
      setDatabaseSettings(response);
      setInitialDatabaseSettings({ ...response });

      if (response?.serverAddress) {
        fetchDbConnection();
      }
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function fetchDbConnection() {
    setIsDbConnectionLoading(true);

    try {
      const response: MTDatabaseVersion = await service.fetchDbConnection(
        params.id
      );
      setDatabaseConnection(response);
    } catch (error) {
      if (error.status !== 404) {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: error?.status,
          error: {
            details: error?.details,
            code: error?.error,
            message: error?.message
          }
        });
      }
    } finally {
      setIsDbConnectionLoading(false);
    }
  }

  const validateUser = (): boolean => {
    const userFormat = /^[A-Za-z0-9_-]+$/;
    const isValid = Boolean(String(databaseSettings?.user).match(userFormat));
    setIsUserValidationError(!isValid);
    return isValid;
  };

  async function save() {
    if (!validateUser()) {
      return;
    }

    try {
      setIsLoading(true);

      await service.save(params.id, databaseSettings);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
      setInitialDatabaseSettings({
        ...databaseSettings
      });
      setIsConnectionTested(false);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        message: t('notifications.connectionError'),
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function testConnection() {
    if (!validateUser()) {
      return;
    }

    try {
      setIsLoading(true);

      const response = await service.testConnection(
        params.id,
        databaseSettings
      );

      setDatabaseConnection(response);
      setIsConnectionTested(true);
      setInitialDatabaseSettings({
        ...databaseSettings
      });

      if (response.error) {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: 500,
          message: t('notifications.connectionError')
        });
      } else {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: 200,
          message: t('notifications.successfulConnection')
        });
      }
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  const onInputChange = (
    property: string,
    value: string | number | boolean,
    type?: 'number' | 'string' | 'boolean'
  ) => {
    if (type === 'number') {
      setDatabaseSettings({
        ...databaseSettings,
        [property]: Number(value)
      });
    }

    if (type === 'boolean') {
      setDatabaseSettings({
        ...databaseSettings,
        [property]: Boolean(value)
      });
    }

    if (!type || type === 'string') {
      setDatabaseSettings({
        ...databaseSettings,
        [property]: String(value)
      });
    }
  };

  function cancel() {
    setDatabaseSettings(initialDatabaseSettings);
  }

  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.DATABASE_SETTINGS_MT,
        { id: params?.id }
      )
    ],
    [params?.id, sidemenuContext]
  );

  useEffect(() => {
    sidemenuContext.setActiveMenu(
      ORGANIZATION_SIDEMENUS.ORGANIZATION_DATABASE_CONNECTION
    );
  }, []);

  return (
    <div>
      <Row cols={10}>
        <Col col={10}>
          <Breadcrumbs
            NavLink={NavLink}
            className={'breadcrumbs'}
            items={breadcrumbs}
          />
          <Typography className={offsets['mb-40']} element="div" variant="h2">
            {t('organizations.mtDatabaseSettings')}
          </Typography>
        </Col>
      </Row>

      <Row cols={10}>
        <Col col={8} md={4}>
          <form onSubmit={submitForm}>
            <div>
              <div className={cn(offsets['mb-20'], styles.block)}>
                <Input
                  value={databaseSettings?.serverAddress}
                  onChange={(value) => onInputChange('serverAddress', value)}
                  disabled={isLoading}
                  label={t('organizations.serverAddress')}
                  required
                />
                <Input
                  value={databaseSettings?.port || ''}
                  onChange={(value) => onInputChange('port', value, 'number')}
                  disabled={isLoading}
                  label={t('organizations.port')}
                  required
                  type={'number'}
                />
              </div>

              <Input
                value={databaseSettings?.database}
                onChange={(value) => onInputChange('database', value)}
                disabled={isLoading}
                className={offsets['mb-20']}
                label={t('organizations.database')}
                required
              />

              <Input
                value={databaseSettings?.user}
                onChange={(value) => onInputChange('user', value)}
                disabled={isLoading}
                className={offsets['mb-20']}
                label={t('common.user')}
                required
                errorMessage={
                  isUserValidationError ? t('notifications.userIsNotValid') : ''
                }
              />

              <Input
                value={databaseSettings?.password}
                onChange={(value) => onInputChange('password', value)}
                disabled={isLoading}
                className={offsets['mb-20']}
                type={'password'}
                label={t('common.password')}
                required
              />

              <Textarea
                className={offsets['mb-20']}
                label={t('uiKit.comments')}
                onChange={(value) => onInputChange('comment', value)}
                disabled={isLoading}
                required={false}
                value={databaseSettings?.comment}
              />

              <div className={styles.certificate}>
                <Typography variant={'body-1'} element={'div'}>
                  {t('organizations.trustServerCertificate')}
                </Typography>

                <Toggle
                  label={t('organizations.trustServerCertificate')}
                  onChange={(value) =>
                    onInputChange('isTrustServerCertificate', value, 'boolean')
                  }
                  disabled={isLoading}
                  checked={databaseSettings?.isTrustServerCertificate}
                />
              </div>
            </div>

            <div className={offsets['mb-40']}>
              <Button
                buttonType={'submit'}
                onClick={submitButtonFunction()}
                isLoading={isLoading}
                disabled={isSubmitDisabled}
                className={offsets['mr-20']}
              >
                {submitButtonText}
              </Button>
              <Button onClick={cancel} disabled={isLoading} type="secondary">
                {t('common.cancel')}
              </Button>
            </div>
          </form>
        </Col>
        <Col col={8} md={1} />

        {(isDbConnectionLoading || databaseConnection) && (
          <Col col={8} md={4}>
            <DatabaseVersion
              isLocal={false}
              version={databaseConnection?.version}
              translations={{
                seeDetails: t('uiKit.seeDetails'),
                mtDatabaseVersion: t('organizations.mtDatabaseVersion'),
                type: t('common.type'),
                local: t('common.local'),
                shared: t('common.shared'),
                version: t('common.version'),
                errorDetails: t('uiKit.errorDetails'),
                testConnectionFailed: t('organizations.testConnectionFailed'),
                online: t('organizations.online'),
                lastUpdate: t('uiKit.lastUpdate'),
                from: t('uiKit.from')
              }}
              error={databaseConnection?.error}
              isLoading={isDbConnectionLoading}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

DatabaseSettingsMT.displayName = 'DatabaseSettingsMT';
